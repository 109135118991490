import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Room } from 'src/app/models/room.model';
import { Thread } from 'src/app/models/thread.model';
import { ClientcommunicationService } from 'src/app/services/clientcommunication/clientcommunication.service';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.css']
})
export class ChatRoomComponent implements OnInit {
  @ViewChild('sc', { static: false }) scroller?: ScrollPanel;
  scHeight: number = 0;
  thread: Thread = new Thread();
  room: Room = new Room();
  messageList: any[] = [];
  threads: Thread[] = [];
  subs: Subscription[] = [];
  roomSubs?: Subscription;
  businessId: string = '';
  colorCode: any = CommonFunctions.getUser().chatWindowColorCode;

  @Output() RoomData: EventEmitter<any> = new EventEmitter<any>();
  constructor(private clientcommunicationService: ClientcommunicationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.businessId = CommonFunctions.getBusinessInfo().businessId;
    this.retrieveThreads();
    this.scroll = this.scroll.bind(this);
    // this.scroller?.onDocumentMouseUp(this.scroll)
    this.scroller?.contentViewChild.nativeElement.addEventListener('scroll', this.scroll);
  }

  getPreferenceType(doc_id: string) {
    this.roomSubs = this.clientcommunicationService.getRoomData(doc_id).valueChanges().subscribe((doc: any) => {
      console.log("preference type data:" + JSON.stringify(doc))
      if (doc !== null) {
        this.RoomData.emit(doc);
        this.room = doc;
        this.setUserRead(doc_id)
        // this.preference_type = doc?.preference_type || '';
      } else {

      }

    });
  }

  retrieveThreads(): void {
    this.subs.push(this.route.paramMap
      .pipe(
        map((paramMap: any) => {
          // console.log("Id:" + paramMap.get('id'))
          return paramMap.get('id');
        })
      ).subscribe(routePathParam => this.clientcommunicationService.updatePathParamState(routePathParam)));

    this.subs.push(
      this.route.params.subscribe(par => {
        //get preference type
        this.getPreferenceType(par.id);
        // This is to get the messages in reverse order, that is latest on top
        this.subs.push(this.clientcommunicationService.getAllMessages(par.id)
          .valueChanges()
          .subscribe(messages => {
            // console.log("message:" + JSON.stringify(messages))
            if (messages !== null) {
              let groupById = []; let groupByDateTime = [];
              let prevId: any = '';
              let prevTimeTag: string = '';
              this.messageList = [];
              let duplicate: boolean = false;
              for (let index = 0; index < messages.length; index++) {
                let element: Thread = messages[index];
                if (index == 0) {
                  groupById.push(element)
                  prevId = element.sender_id;
                  prevTimeTag = CommonFunctions.getTimeAndDateTag(element.timestamp, 'chatroom')
                } else {
                  let timeTag = CommonFunctions.getTimeAndDateTag(element.timestamp, 'chatroom')
                  if (prevId == element.sender_id && prevTimeTag == timeTag) {
                    groupById.push(element)
                    duplicate = true;
                  } else {
                    duplicate = false;
                    groupById = [];
                    groupById.push(element)
                  }

                  prevId = element.sender_id;
                  prevTimeTag = CommonFunctions.getTimeAndDateTag(element.timestamp, 'chatroom')
                }
                if (!duplicate) {
                  let timeTag = CommonFunctions.getTimeAndDateTag(element.timestamp, 'chatroom')
                  this.messageList.push({ id: element.sender_id, timeTag: timeTag, subMsg: groupById })
                }

              }
              console.log(this.messageList);
              this.scroller?.scrollTop(this.scHeight);
              this.showMessageSuccess()
            } else {
              this.threads = [];
            }

          }));
      })
    );
  }

  setUserRead(path: any) {
    var typeObject: any = {
      read_status: 'noOne'
    }
    if (this.room != null) {
      if (this.room.read_status == CommonFunctions.getBusinessInfoId().toString()) {
        this.clientcommunicationService.updateRoomForTypingOrRead(path, typeObject).then(() => {
        }).catch((error) => {
          console.log('Error: Getting document:', error.message)
        });
      }
    }

  }

  showMessageSuccess() {
    var that = this;
    // this.messageSuccess = true;
    setTimeout(function () {
      that.scHeight = that.scroller?.contentViewChild.nativeElement.scrollHeight || 0;
      that.scroller?.scrollTop(that.scHeight);
      console.log("height:" + that.scroller?.contentViewChild.nativeElement.scrollHeight)
    }, 1000);
  }

  scroll(e: Event) {
    // let scrollTop: number = this.scroller?.contentViewChild.nativeElement.scrollTop;
    // let scrollMax: number = this.scroller?.contentViewChild.nativeElement.scrollHeight - this.scroller?.contentViewChild.nativeElement.clientHeight;
    // if (!this.noMoreBlueprints && !this.working && scrollTop == scrollMax) {
    //   this.loadMoreBlueprints();
    // }
    console.log('scoll...' + e)
  }

  ngOnDestroy() {
    console.log('destroy............................')
    this.subs.forEach(element => {
      element.unsubscribe()
    });
    this.roomSubs?.unsubscribe()
  }
}
