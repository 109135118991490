import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";
import { MessageService } from "primeng/api";
import { AppSettings } from "../Constant/AppSettings";
import { SubscriptionService } from "../services/subscription/subscription.service";
import { UserService } from "../services/user/user.service";
import { CommonFunctions } from "../Utilities/CommonFunctions";

@Component({
  selector: "app-paypal",
  templateUrl: "./paypal.component.html",
  styleUrls: ["./paypal.component.css"],
  providers: [MessageService],
})
export class PaypalComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  showSuccess: boolean = false;
  userId: string = "";
  amount: string = this.subscriptionService.amount;
  subscriptionType: string = this.subscriptionService.subscriptionType;
  subscriptionId: string = this.subscriptionService.subscriptionId;
  constructor(
    public subscriptionService: SubscriptionService,
    public router: Router,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initConfig();
    this.userId = CommonFunctions.getUserId();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: "USD",
      clientId:
        "Acn1XRqCXTvV7RpH8TZqvgK1yLgXevuTBZPcu6HBW6qEEDL6buNQOSnJJ9mfxLLJIQgD5aaFafT77h6d",
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: "USD",
                    value: this.amount,
                  },
                },
              },
              items: [
                {
                  name: this.subscriptionType,
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "USD",
                    value: this.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        layout: "vertical",
        color: "blue",
        shape: "pill",
        label: "pay",
      },
      onApprove: (data, actions) => {
        console.log(
          "onApprove - transaction was approved, but not authorized",
          data,
          actions
        );
        actions.order.get().then((details: any) => {
          console.log(
            "onApprove - you can get full order details inside onApprove: ",
            details
          );
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          "onClientAuthorization - you should probably inform your server about completed transaction at this point",
          data
        );
        this.showSuccess = true;
        if (data.status == "COMPLETED") {
          console.log("payment Reff no --->> " + data.id);
          this.activateUser(data.id);
        } else {
          this.showErrorMessage("Oops! Payment failed, please try again.");
        }
      },
      onCancel: (data, actions) => {
        console.log("OnCancel", data, actions);
        this.showErrorMessage("Oops! Payment failed, please try again.");
      },
      onError: (err) => {
        console.log("OnError", err);
      },
      onClick: (data, actions) => {
        console.log("onClick", data, actions);
      },
    };
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: "errMsg",
      severity: "error",
      detail: msg,
    });
  }

  activateUser(paymentRefNo: string) {
    console.log("auid -> " + this.userId);
    console.log("ast -> " + this.subscriptionType);
    this.userService
      .activateUser(
        this.userId,
        this.subscriptionType,
        this.subscriptionId,
        paymentRefNo,
        this.amount,
        ''
      )
      .subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("Activate User Response --->> " + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.subscriptionService.isPaymentSuccessful = true;
            this.router.navigate(["/home"]);
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          // this.hideProgressBar();
        }
      );
  }
}
