import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirbaseMessagingService } from 'src/app/services/firbase-messaging/firbase-messaging.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Component({
  selector: 'app-twillio-message',
  templateUrl: './twillio-message.component.html',
  styleUrls: ['./twillio-message.component.css']
})
export class TwillioMessageComponent implements OnInit {
  showHideMsg: boolean = false;
  showHideCloseBtn: boolean = false;
  severity: string = 'warn';
  msgText: string = ''
  twillioData: any;

  //Deactivation of business acoount
  deactivationMsgText: string = '';
  deactivationShowHideMsg: boolean = false;
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private messagingService: FirbaseMessagingService
  ) { }

  ngOnInit(): void {

    this.utilsService.twillioBannerData.subscribe(
      (showBannerData: any) => {
        this.twillioData = showBannerData;
        if (CommonFunctions.getBusinessInfo().twilioNumber == null || CommonFunctions.getBusinessInfo().twilioNumber == '' || CommonFunctions.getBusinessInfo().twilioNumber == 'null') {
          this.severity = 'warn'
          this.msgText = 'Your SMS number allocation is in progress'
          this.showHideCloseBtn = false;
          this.showHideMsg = true;
        } else {
          if (showBannerData?.comments == 'unread') {
            this.severity = 'success'
            this.msgText = 'SMS number is allocated'
            this.showHideCloseBtn = true;
            this.showHideMsg = true;
          } else {
            this.showHideMsg = false;
          }
        }
      }
    );
    if (CommonFunctions.getBusinessInfo().state == 'offline') {
      if (this.router.url == '/client-communication') {
        this.deactivationShowHideMsg = true;
        this.deactivationMsgText = 'You are currently offline. Please go online to Send messages'
      } else if (this.router.url == '/business-calendar') {
        this.deactivationShowHideMsg = true;
        this.deactivationMsgText = 'You are currently offline. Please go online to access Business Calendar'
      } else {
        this.deactivationShowHideMsg = false;
      }
    }
  }

  setNotificationRead(path: any) {
    var typeObject: any = {
      comments: 'read'
    }
    this.messagingService.updateNotificationRead(path, typeObject).then(() => {
    });
  }

  hideTwillioMsg() {
    if (this.twillioData != undefined) {
      this.setNotificationRead(this.twillioData.id)
    }
  }

}
