<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Business Keywords
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="toolbar">
                    <div class="p-grid p-ai-center">
                        <!-- <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex" *ngIf="bussinesInfo?.subscriptionType == 'STANDARD'">
                            <button pButton pRipple type="button" label="Add Keywords" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" (click)="showHideModalKeywordDialog('add_keyword')"></button>
                        </div>
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex" *ngIf="bussinesInfo?.subscriptionType != 'STANDARD'">
                            <button pButton pRipple type="button" label="Add Keywords" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" (click)="showHideModalKeywordDialog('add_keyword')" [disabled]="true"></button>
                        </div> -->
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex">
                            <button pButton pRipple type="button" label="Add Keywords" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" (click)="showHideModalKeywordDialog('add_keyword')"></button>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="cols" [value]="keywordsList"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                    <th>
                                        Active/Inactive
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-i="rowIndex"; let-keyword let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        {{keyword[col.field]}}
                                    </td>
                                    <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        &nbsp;
                                        <a href="javascript:void(0);" (click)="getKeyword(keyword, 'edit_msg')">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                        </a>
                                        &nbsp;
                                        <a href="javascript:void(0);" (click)="confirmDeleteDialog(keyword)" [ngClass]="{'disabledClass': keyword.checkExistingKeyword, '': !keyword.checkExistingKeyword}"> <i class="pi pi-trash" [ngStyle]="{'color': keyword.checkExistingKeyword ? '#dddddd' : '#007AFF'}" style="font-size: 0.8rem;color:#007AFF"></i></a>
                                    </td>
                                    <td class="last_border">
                                        <span class="p-column-title">Active/Inactive</span>
                                        <p-inputSwitch *ngIf="!keyword.canChangeRole; else canEdit"
                                            [(ngModel)]="keyword['isEnabled']" name="enableDisable"
                                            (onChange)="onToggle($event,keyword)">
                                        </p-inputSwitch>
                                        <!-- <a  href="javascript:void(0);"
                                                    data-toggle="modal" (click)="getServiceType(service, 'edit_service')">
                                                    <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                                </a> -->
                                        <ng-template #canEdit style="display:inline-block;">
                                            <div pTooltip="You cannot edit this keyword" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <p-inputSwitch [(ngModel)]="keyword['isEnabled']" name="enableDisable"
                                                    [disabled]="true">
                                                </p-inputSwitch>
                                            </div>
                                        </ng-template>
                                        &nbsp;

                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+2">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg" *ngIf="keywordsList?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- ADD/EDIT CLIENT MODAL -->
<p-dialog #keywordDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,keywordDialog)" [contentStyle]="{'overflow':'visible'}">
    <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
    <div class="row" style="min-width: 60vw;">
        <div class="form-group col-md-12">
            <label class="control-label">Automatic message<span class="symbol required"></span></label>
            <mwl-text-input-autocomplete-container>
                <textarea placeholder="Default Text..." class="form-control" [ngClass]="error_AUTOMATIC_Msg?'error':''"
                    [rows]="5" [(ngModel)]="AUTOMATIC_Msg" name="Msg" mwlTextInputAutocomplete
                    [findChoices]="findChoicesIn(arrayVariables)" [getChoiceLabel]="getChoiceLabel"
                    [disabled]="disabled_AUTOMATIC_Msg" triggerCharacter="$" spellcheck="true" required>
                    <!-- [style]="{'overflow':'hidden'}"  -->
                    </textarea>
            </mwl-text-input-autocomplete-container>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" label="Save" (click)="editAutoMsg()" [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<p-dialog #keywordCreateAndUpdateDialog [header]="headerName" [(visible)]="keywordCreateAndUpdate" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,keywordDialog)" [contentStyle]="{'overflow':'visible'}">
    <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
    <div class="row" style="min-width: 60vw;">
        <div class="form-group col-md-6">
            <label>Keyword Name<span class="symbol required"></span></label>
            <input type="text" class="form-control" name="Name" placeholder="Enter Keyword" [(ngModel)]="Name" value="{{Name | uppercase}}" required />
        </div>
        <div class="form-group col-md-6">
            <label>Description<span class="symbol required"></span></label>
            <input type="text" class="form-control" name="Description" placeholder="Enter Description" [(ngModel)]="Description" required />
        </div>
        <div class="form-group col-md-12">
            <label class="control-label">Automatic message<span class="symbol required"></span></label>
            <mwl-text-input-autocomplete-container>
                <textarea placeholder="Default Text..." class="form-control" [ngClass]="error_AUTOMATIC_Msg?'error':''"
                    [rows]="5" [(ngModel)]="AUTOMATIC_Msg" name="Msg" mwlTextInputAutocomplete
                    [findChoices]="findChoicesIn(arrayVariables)" [getChoiceLabel]="getChoiceLabel"
                    [disabled]="disabled_AUTOMATIC_Msg" triggerCharacter="$" spellcheck="true" required>
                    <!-- [style]="{'overflow':'hidden'}"  -->
                    </textarea>
            </mwl-text-input-autocomplete-container>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="keywordCreateAndUpdate = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" label="Add" (click)="AddKeyword()" [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>