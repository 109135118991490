<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Chat Button Configuration
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Change Chat Button</legend>
                                <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
                                <form role="form" class="form-horizontal" (ngSubmit)="editChatButton(chatButtonForm)"
                                    #chatButtonForm="ngForm">
                                    <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;"
                                        data-provides="fileinput">
                                        <div class="user-image mt-2">
                                            <div class="fileinput-new img-thumbnail">
                                                <img style="width: 100px;height: 100px;object-fit: cover;"
                                                    [src]="profileUrl" alt="" (error)="fileFailedToLoad($event)" />
                                                <!-- <p-avatar *ngIf="imageInputError" [label]="avatarName"
                                                            [style]="{width: '100px',height: '100px'}" size="xlarge">
                                                        </p-avatar> -->
                                            </div>
                                            <div class=" user-image-buttons">
                                                <span class="btn btn-azure btn-file btn-sm"
                                                    (click)="displayCropModal = true">
                                                    <span class="fileinput-new">
                                                        <i class="fa fa-pencil"></i>
                                                    </span>
                                                </span>
                                                <a *ngIf="!imageInputError" href="javascript:void(0)"
                                                    (click)="confirmDeleteDialog()"
                                                    class="btn fileinput-exists btn-red btn-sm">
                                                    <i class="fa fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Name <span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <input type="text" name="businessName" class="form-control"
                                                placeholder="Enter Business Name" [(ngModel)]="businessName"
                                                [disabled]="disabled" maxlength="20" required />
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <label class="col-sm-4 control-label">Business Phone<span
                                                class="symbol required"></span></label>
                                        <div class="col-sm-8">
                                            <span class="input-icon"><input type="text" class="form-control"
                                                    placeholder="Enter Phone" name="phone" [(ngModel)]="phone"
                                                    [disabled]="disabled" maxlength="10"
                                                    (keypress)="validateDigitOnly($event)">
                                                <span class="number">+1</span></span>
                                        </div>
                                    </div>
                                    <!-- <div class="row form-group">
                                                <label class="col-sm-4 control-label">Chat Button Category</label>
                                                <div class="col-sm-8">
                                                    <select [(ngModel)]="chatButtonCategory" name="chatButtonCategory"
                                                        class="form-control" style="height: 34px;" required>
                                                        <option value="null" disabled>Select business type</option>
                                                        <option *ngFor="let category of chatButtonCategoryList"
                                                            [value]="category.id">
                                                            {{ category.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div> -->
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Reset"
                                            class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                            (click)="resetChatButton()"></button>

                                        <button pButton pRipple type="button" class="p-button-raised "
                                            (click)="editChatButton(chatButtonForm)" label="Save"
                                            [disabled]="disabled"></button>
                                    </div>

                                </form>
                            </fieldset>
                        </div>
                        <!-- <div class="col-md-6">
                            <fieldset>
                                <legend>Preview</legend>
                                <p-messages key="tr" [style]="{ 'font-size': '12px' }"></p-messages>
                                <div class="form-horizontal">
                                    <div>
                                        <div class="p-d-flex p-jc-between p-ai-center p-p-2"
                                            style="background: #2196F3;border-radius: 4px 4px 0px 0px;">
                                            <i class="pi pi-arrow-circle-left"
                                                style="font-size: 2rem;color: white;"></i>
                                            <div style="font-size: 1.3rem;color: white;font-weight: bold;">
                                                Chatstasy
                                            </div>
                                            <i class="pi pi-home" style="font-size: 2rem;color: white;"></i>
                                        </div>
                                        <div class="p-d-flex p-flex-column p-jc-center p-ai-center p-p-2">
                                            <div class="img-thumbnail">
                                                <img [src]="profileUrl" style="width: 100px;height: 100px;object-fit: cover;border: 5px solid #2196F3;
                                                        border-radius: 4px;" alt="" />
                                            </div>
                                            <div class="p-d-flex p-flex-column"
                                                style="font-weight: bolder;text-align: center;box-sizing: border-box;">
                                                <div style="font-size: 18px;width: 100px;word-wrap: break-word">
                                                    {{businessName}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Publish"
                                            class="p-button-raised p-mb-2" (click)="confirmPublishDialog()"
                                            [disabled]="disabledPublish"></button>

                                        <small *ngIf="!disabledPublish" class="col-sm-12" style="color: #858585;">
                                            Publish your changes to customers in Chatstasy app.
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                        </div> -->

                        <div class="col-md-6 nopadding">
                            <fieldset style="padding: 10px !important;margin: 0;">
                                <legend>Preview</legend>
                                <div class="col-md-8 offset-md-2">
                                    <app-phone-preview [getBusinessName]= businessName [headerShow]= headerShow [cropImage] = profileUrl [getPhone] = phone [businessNameBold]= true [phoneNumberBold] = true ></app-phone-preview>

                                    <div class="form-group center mt-2">
                                        <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                            strokeWidth="3">
                                        </p-progressSpinner>
                                        <button pButton pRipple type="button" label="Publish"
                                            class="p-button-raised p-mb-2" (click)="confirmPublishDialog()"
                                            [disabled]="disabledPublish"></button>

                                        <small *ngIf="!disabledPublish" class="col-sm-12" style="color: #858585;">
                                            Publish your changes to customers in Chatstasy app.
                                        </small>
                                    </div>
                                    
                                </div>
                            </fieldset>
                        </div>

                    </div>

                </div>
            </p-card>

        </div>
    </p-scrollPanel>
</div>
<!-- REMOVE LOGO MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- CROP MODAL -->
<app-image-cropper-dialog #renew [displayCropModal]="displayCropModal" (hideCropModal)="hideCropModal($event)"
    (croppedImage)="croppedImage($event)">
</app-image-cropper-dialog>