<p-messages *ngIf="showHideMsgCredit" key="countMsg" [severity]="severity">
    <ng-template pTemplate>
        <div class="align">
            <div class="p-ml-2">{{msgText}}</div>
            <button *ngIf="getPrivilege('businessclient_billing_management')" pButton label="Buy Now"
                class="p-button-link p-button-sm" (click)="openMsgModalCredit()"></button>
        </div>

        <div>
            <button *ngIf="!showHideCloseBtn" pButton icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-warning p-message-close"
                (click)="hideMsgCredit()"></button>
        </div>

    </ng-template>
</p-messages>