import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Room } from 'src/app/models/room.model';
import { ClientcommunicationService } from 'src/app/services/clientcommunication/clientcommunication.service';
import { Router } from '@angular/router';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.css']
})
export class ChatSidebarComponent implements OnInit, OnDestroy {
  businessId = CommonFunctions.getBusinessInfoId()
  room: Room = new Room();
  rooms: Room[] = [];
  subs: Subscription[] = [];
  lastMessage: string = '';
  searchText = '';
  @Input() userId: string = '';
  subsForGet?: Subscription;
  paramValue: string = '';
  i = 0;
  firebaseId:  any[] = [];
  subsFireGet?: Subscription;
  // @Output() seedValue: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private clientcommunicationService: ClientcommunicationService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {

  }

  ngAfterContentInit(): void {
    this.retrieveThreads()
    // this.createNewChat()routerLink="client-communication/room/{{ room.id }}"
    this.subsForGet = this.clientcommunicationService.pathParam.subscribe(value => {
      this.paramValue = value;
      console.log(this.paramValue);
    });

    this.subsFireGet = this.utilsService.loadFirebaseMessage.subscribe(
      (id) => {
        if(id != ""){
          if(JSON.stringify(CommonFunctions.getClientChatList()) != '{}') {
            if(this.firebaseId.indexOf(id) == -1){
              this.firebaseId.push(id);
              CommonFunctions.setClientChatList(this.firebaseId);
            }
            this.retrieveThreads();
          }else if(this.firebaseId.length == 0) {
            this.firebaseId.push(id);
            CommonFunctions.removeClientChatList();
            CommonFunctions.setClientChatList(this.firebaseId);
            this.retrieveThreads();
          }
        }
      }
    )
  }

  jsonExtract(){
    var obj = CommonFunctions.getClientChatList();

    for(var i in obj)
    this.firebaseId.push(obj[i]);
  }

  retrieveThreads(): void {
    this.subs.push(this.clientcommunicationService.getAllRooms().snapshotChanges().pipe(
      map((changes: any) =>
        changes.map((c: any) =>
        ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
        )
        ).subscribe((data: any) => {
          this.rooms = [];
          this.jsonExtract();
          data.forEach((element: any) => {
            
            element.timeTag = CommonFunctions.getTimeAndDateTag(element.timestamp, 'sidebar')
            
            if(JSON.stringify(CommonFunctions.getClientChatList()) != '{}'){
              if(JSON.stringify(CommonFunctions.getClientChatList()).includes(element.id)) {
                this.rooms.push(element);

                if(this.firebaseId.length == 0){
                  this.firebaseId = CommonFunctions.getClientChatList();
                }
              }
            }

            if(element.read_status == CommonFunctions.getBusinessInfoId().toString()) {
              if(this.firebaseId.indexOf(element.id) == -1){
                this.rooms.push(element);
                this.firebaseId.push(element.id);
                CommonFunctions.setClientChatList(this.firebaseId);
              }
            }

            if(element.read_status == "unread") {
              if(this.firebaseId.indexOf(element.id) == -1){
                this.rooms.push(element);
                this.firebaseId.push(element.id);
                CommonFunctions.setClientChatList(this.firebaseId);
              }
            }

          });
          
      if (this.i == 0) {
        if (this.rooms != null) {
          this.router.navigate(['client-communication/room/' + this.rooms[0]?.id]);
        }
        this.i = this.i + 1;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
    this.utilsService.setloadFirebaseMessage("");
  }

  removeHTMLTag(html:any){
    return html.replace(/(<([^>]+)>)/ig,"").replace(/&nbsp;/g, '');
  }
}
