<div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="p-d-flex p-jc-center p-ai-center p-mb-4">
                    <img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="width: 30%;margin-top: 15px;" alt="Chatstasy" />
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="overflow:auto; height:400px;">
        <div style="width: 100%;">
            <div style="text-align:center">
                <p-card header="{{action.toUpperCase()}}" styleClass="p-card-shadow" [style]="{width: '50%', margin: 'auto'}" *ngIf="appointmentDetails?.title != null">
                    <p-messages key="errMsg" [style]="{
                        'font-size': '12px'
                      }"></p-messages>
                    <h3>{{appointmentDetails?.title}}</h3>
                    <p><strong> Date: </strong> {{appointmentDetails?.scheduledOnFrom  | date: 'MM-dd-yyyy h:mm a'}}</p>
                    <p><strong> Time: </strong> {{appointmentDetails?.scheduledOnTo  | date: 'MM-dd-yyyy h:mm a'}}</p>
                    <p><strong> Type of service: </strong> {{appointmentDetails?.serviceType.name}}</p>
                    <ng-template pTemplate="footer">
                        <button pButton type="button" icon="pi pi-check" label="{{action}}" class="p-button-success" (click)="confirm(action)"></button>
                        <button pButton type="button" icon="pi pi-times" label="Cancel" class="p-button-danger" style=" margin-left: 10px; " (click)="RedirectToBaseURL()"></button>
                    </ng-template>
                </p-card>

                <p-card styleClass="p-card-shadow" [style]="{width: '50%', margin: 'auto'}" *ngIf="appointmentDetails?.title == null">
                    <ng-template pTemplate="header">
                        <img alt="Card" src="assets/images/page_not_found.png" style="width: 240px;margin-top: 20px;">
                    </ng-template>
                    <h3 style="text-transform:uppercase">Invalid Appointment URL</h3>
                </p-card>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{'min-width':'45vw'}"></p-confirmDialog>
<p-toast position="bottom-right" key="br"></p-toast>