export class Profile {
  private firstName: string;
  private lastName: string;
  private email: string;
  private mobile: string;
  private country: string;

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    mobile: string,
    country: string
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.mobile = mobile;
    this.country = country;
  }

  getFirstName(): string {
    return this.firstName;
  }

  getLastName(): string {
    return this.lastName;
  }

  getEmail(): string {
    return this.email;
  }

  getMobile(): string {
    return this.mobile;
  }

  getCountry(): string {
    return this.country;
  }
}
