<div class="error-full-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 page-error">
                <div class="error-number">404</div>
                <div class="error-details col-sm-6 offset-sm-3">
                    <h2 class="error-message">page not found</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus, lorem nec fermentum
                        tempus, ipsum ex porta justo, dapibus placerat velit massa ut dolor.</p><a href=""
                        class="btn btn-wide btn-outline-primary"><i class="fa fa-home"></i> Go to the login</a>
                </div>
            </div>
        </div>
    </div>
</div>