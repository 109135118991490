import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Md5 } from 'ts-md5';
import * as bcrypt from 'bcryptjs';
import { AppSettings } from '../Constant/AppSettings';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { RegistrationService } from '../services/registration/registration.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  isLoading: boolean = false;
  disabled: boolean = true;
  businessUrl = '';
  email = '';
  password = '';
  showBusinessName: boolean = false;
  error = '';
  baseUrl = '';
  //otp
  receivedOtp: any;
  countNumber = 5;
  resendCountNumber = 60;
  showResendLayout: boolean = true;
  showRegisterLayout: boolean = false;
  redirectingToLogin: boolean = false;
  disabledOtp: boolean = true;
  //
  currentPosition: string = 'email';
  constructor(
    private locationStrategy: LocationStrategy,
    private authenticationService: AuthenticationService,
    private registrationService: RegistrationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.baseUrl = CommonFunctions.replaceAll(this.locationStrategy.getBaseHref(), '/', '')
    // console.log('base url:', this.businessUrl);
    CommonFunctions.setBaseUrl('');
    if (this.baseUrl == '' || this.baseUrl == 'login') {
      this.showBusinessName = true;
    } else {
      this.showBusinessName = false;
      this.getBusinessInfoByShortname()
    }
  }

  getBusinessInfoByShortname() {
    this.showProgressBar()
    let shortName = '';
    if (this.showBusinessName) {
      shortName = this.businessUrl
    } else {
      shortName = this.baseUrl
    }

    this.authenticationService.getBusinessInfoByShortname(shortName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Business Info Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  checkDuplicateUser(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authenticationService.checkDuplicateUser(this.email).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Check Duplicate User Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            resolve('success')
          } else {
            this.showErrorMessage(json.data.message)
            resolve('null')
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
          resolve('null')
        }
      );
    })
  }

  resendOtp() {
    this.sendOtp()
    this.showResendButtonAfterSixtySec()
  }

  sendOtp() {
    this.clearOtp()
    this.registrationService.sendOTP(this.email).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send Otp Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.receivedOtp = json.data;
        } else {
          this.showErrorMessage(json.data.message)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  reset(resetPasswordForm: NgForm) {
    this.showProgressBar();
    if (resetPasswordForm.value.profileNewPassword.length === 0) {
      this.showErrorMessage('Please enter a new password.')
      this.hideProgressBar();
      return;
    } else if (resetPasswordForm.value.profileNewPassword !== resetPasswordForm.value.profileConfirmPassword) {
      this.showErrorMessage('Passwords do not match.')
      this.hideProgressBar();
      return;
    }
    const salt = bcrypt.genSaltSync(10);
    let pass = bcrypt.hashSync(resetPasswordForm.value.profileConfirmPassword, 10);
    console.log('encrypt:', pass);
    this.authenticationService.forgotPassword(this.email, pass).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.message)
          this.hideProgressBar();
          this.redirectingToLoginInFiveSec()
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
      }
    );
  }

  validateEmail() {
    this.showProgressBar()
    if (this.email == '') {
      this.showErrorMessage('Email Field is empty.')
      this.hideProgressBar();
      return 'null';
    }

    this.hideProgressBar();
    return 'success';
  }


  async goToNextAndPrevious(value: string) {
    switch (value) {
      case 'email':
        this.clearOtp()
        this.currentPosition = value;
        break;
      case 'otp':
        if (this.validateEmail() == 'null') {
          return;
        } else {
          await this.checkDuplicateUser().then((resolve: string) => {
            if (resolve == 'null') {
              return;
            } else {
              this.sendOtp()
              this.currentPosition = value;
            }
          })
        }
        break;
      case 'password':
        this.clearOtp()
        this.currentPosition = value;
        break;
    }
  }

  onOtpChange(event: any) {
    if (event.length == 6) {
      this.showProgressBar()
      this.ngOtpInput.otpForm.disable();
      const md5 = new Md5();
      let enterOtp = md5.appendStr(event).end();
      if (this.receivedOtp == enterOtp) {
        this.showRegisterLayout = true;
        this.showSuccessMessage('Otp verified successfully')
      } else {
        this.ngOtpInput.otpForm.enable();
        this.showErrorMessage('Invalid OTP. Please check your code and try again.')
      }
      this.hideProgressBar()
    }
  }

  redirectingToLoginInFiveSec() {
    this.disabled = true;
    this.redirectingToLogin = true
    let interval = setInterval(() => {
      this.countNumber = this.countNumber - 1
    }, 1000);
    setTimeout(() => {
      clearInterval(interval)
      let shortName = '';
      if (this.showBusinessName) {
        shortName = this.businessUrl
      } else {
        shortName = this.baseUrl
      }
      window.location.href = shortName + '/login';
      // this.router.navigate(['/login'], { replaceUrl: true });
    }, 5000);
  }


  showResendButtonAfterSixtySec() {
    this.showResendLayout = false
    let interval = setInterval(() => {
      this.resendCountNumber = this.resendCountNumber - 1
    }, 1000);
    setTimeout(() => {
      clearInterval(interval)
      this.resendCountNumber = 60;
      this.showResendLayout = true
    }, 60000);
  }

  clearOtp() {
    this.messageService.clear('errMsg');
    this.ngOtpInput.otpForm.enable();
    this.ngOtpInput.setValue('');
    this.showRegisterLayout = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

}
