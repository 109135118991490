import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ClientSegmentationService {

  constructor(
    private http: HttpClient
  ) { }


  addClient(clientData: any) {
    const url = environment.API_URL + '/api/client-segmentation/add';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, clientData, httpOptions).pipe(timeout(60000));
  }

  getClientSegmentationList(){
    const url = environment.API_URL + '/api/client-segmentation';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      })
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getClientSegmentationListByActive(){
    const url = environment.API_URL + '/api/client-segmentation/enabled';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      })
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  removeClientSegmentation(id:any){
    const url = environment.API_URL + '/api/client-segmentation/' + id + '/remove';;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  UpdateClientSegmentation(clientData: any, id: any) {
    const url = environment.API_URL + '/api/client-segmentation/' + id + '/update';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, clientData, httpOptions).pipe(timeout(60000));
  }

  enableDisable(keyword_id: any, isEnabled: any) {
    const url = environment.API_URL + '/api/client-segmentation/' + keyword_id + '/updateStatus?isEnabled=' + isEnabled;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }
}
