<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Action Buttons
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>

        <div class="p-mb-5">
            <p-card>
                <div class="toolbar">
                    <div class="p-grid p-ai-center">
                        <!-- <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex" *ngIf="bussinesInfo?.subscriptionType == 'STANDARD'">
                            <button pButton pRipple type="button" label="Add Action Button" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" (click)="showHideModalActionButtonDialog('add_action_button')"></button>
                        </div>
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex" *ngIf="bussinesInfo?.subscriptionType != 'STANDARD'">
                            <button pButton pRipple type="button" label="Add Action Button" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" [disabled]="true"></button>
                        </div> -->
                        <div class="p-col p-md-12 p-lg-12 p-jc-end d-flex d-lg-flex d-xl-flex">
                            <button pButton pRipple type="button" label="Add Action Button" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;" (click)="showHideModalActionButtonDialog('add_action_button')"></button>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="cols" [value]="arrayActionButton"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                    <th>
                                        Active/Inactive
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-act_button let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        {{act_button[col.field]}}
                                    </td>
                                    <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        <span style="text-align: center;">
                                            <a href="javascript:void(0);" (click)="getActionButton(act_button, 'update_action_button')">
                                                <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF; padding-left: 5px;padding-right: 5px;"></i>
                                            </a>
                                        </span>
                                        <span style="text-align: center;">
                                            <a href="javascript:void(0);" (click)="removeActionButtonConfirmDialog(act_button['id'])">
                                                <i class="pi pi-trash" style="font-size: 0.8rem;color:#007AFF; padding-left: 5px;padding-right: 5px;"></i>
                                            </a>
                                        </span>
                                    </td>
                                    <td class="last_border">
                                        <span class="p-column-title">Active/Inactive</span>
                                        <p-inputSwitch *ngIf="!act_button.canChangeRole; else canEdit"
                                            [(ngModel)]="act_button['isEnabled']" name="enableDisable"
                                            (onChange)="onToggle($event,act_button)">
                                        </p-inputSwitch>
                                        <ng-template #canEdit style="display:inline-block;">
                                            <div pTooltip="You cannot edit this keyword" tooltipPosition="left"
                                                style="display:inline-block;">
                                                <p-inputSwitch [(ngModel)]="act_button['isEnabled']" name="enableDisable"
                                                    [disabled]="true">
                                                </p-inputSwitch>
                                            </div>
                                        </ng-template>
                                        &nbsp;

                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+2">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg" *ngIf="arrayActionButton?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>
<p-dialog #ActionButtonCreateAndUpdateDialog [header]="headerName" [(visible)]="ActionButtonCreateAndUpdate" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,ActionButtonCreateAndUpdateDialog)" [contentStyle]="{'overflow':'visible'}">
    <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
    <div class="row" style="min-width: 60vw;">
        <div style="width: 70%;">
            <div class="form-group col-md-6">
                <label>Action Button Name<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="Name" placeholder="Enter Action Button" [(ngModel)]="Name" required autocomplete="off"/>
            </div>
    
            <div class="form-group col-md-6">
                <label>Action Button Link<span class="symbol required"></span></label>
                <input type="text" class="form-control" name="Link" placeholder="Enter Action Link" [(ngModel)]="Link"  required autocomplete="off"/>
            </div>
    
            <div class="form-group col-md-12">
                <label>Description<span class="symbol required"></span></label>
                <textarea pInputTextarea type="text" class="form-control" placeholder="Enter Description" [rows]="5" [cols]="30" [(ngModel)]="Description" name="Description" required></textarea>
            </div>
    
            <div class="form-group col-md-6">
                <label>Button Shape<span class="symbol required"></span></label>
                <br>
                <p-dropdown [options]="button_opitions" [(ngModel)]="ButtonShape" optionLabel="name" [showClear]="true" [style]="{'width':'100%'}"></p-dropdown>
            </div>
    
            <div class="form-group col-md-6">
                <label>Button Color<span class="symbol required"></span></label>
                <br>
                <div class="col-sm-8">
                    <p-colorPicker name="btntColor" [(ngModel)]="btntColor" [disabled]="disabled">
                    </p-colorPicker>
                </div>
            </div>
        </div>
        <div style="width: 30%">
            <div style="border-left: 2px solid #258fe5;height: 200px;position: absolute;margin-left: 10px;top: 130px;"></div>
            <div class="text-center">
                <h1>Preview</h1>
            </div>

            <div style=" margin: auto;  width: 50%; padding: 10px;">
                <div class="text-center" style=" padding-top: 70px; ">
                    <a href="{{ Link }}" rel="noopener noreferrer" target="_blank"><img src="https://dabuttonfactory.com/button.png?t={{ Name }}&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c={{ ButtonShape?.value }}&bgt=unicolored&bgc={{ btntColor.substring(1) }}"></a>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="ActionButtonCreateAndUpdate = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>

        <p-button icon="pi pi-save" *ngIf="headerName=='Add Action Button'" label="Add" (click)="AddAndUpdateActionButtonKeyword('add_action_button')" [disabled]="disabled"></p-button>
        <p-button icon="pi pi-save" *ngIf="headerName=='Edit Action Button'" label="Update" (click)="AddAndUpdateActionButtonKeyword('update_action_button')" [disabled]="disabled"></p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>