import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import Quill from 'quill';
import { AppSettings } from '../Constant/AppSettings';
import { ActionButtonService } from '../services/action-button/action-button.service';
import { MessagesTemplateService } from '../services/messages-template/messages-template.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
declare var CKEDITOR:any;
import { Dialog } from 'primeng/dialog';
import { ChatRoomService } from '../services/chat-room/chat-room.service';
import { ResizeEvent } from 'angular-resizable-element';
import { environment } from 'src/environments/environment.prod';
import { PhonePreviewComponent } from '../phone-preview/phone-preview.component';

interface VariablesObject {
  id: string,
  variable: string,
  status: string
}
@Component({
  selector: 'app-messages-template',
  templateUrl: './messages-template.component.html',
  styleUrls: ['./messages-template.component.css']
})
export class MessagesTemplateComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Messages Template' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };

  is_Loading_NEW_APPOINTMENT: boolean = false;
  disabled_NEW_APPOINTMENT: boolean = false;
  NEW_APPOINTMENT_Value: string = '';
  NEW_APPOINTMENT_Msg: string = '';
  error_NEW_APPOINTMENT: boolean = false;

  is_Loading_CHANGE_APPOINTMENT: boolean = false;
  disabled_CHANGE_APPOINTMENT: boolean = false;
  CHANGE_APPOINTMENT_Value: string = '';
  CHANGE_APPOINTMENT_Msg: string = '';
  error_CHANGE_APPOINTMENT: boolean = false;

  is_Loading_CANCELLED_APPOINTMENT: boolean = false;
  disabled_CANCELLED_APPOINTMENT: boolean = false;
  CANCELLED_APPOINTMENT_Value: string = '';
  CANCELLED_APPOINTMENT_Msg: string = '';
  error_CANCELLED_APPOINTMENT: boolean = false;

  is_Loading_APPOINTMENT_REMINDER: boolean = false;
  disabled_APPOINTMENT_REMINDER: boolean = false;
  APPOINTMENT_REMINDER_Value: string = '';
  APPOINTMENT_REMINDER_Msg: string = '';
  error_APPOINTMENT_REMINDER: boolean = false;

  is_Loading_CONFIRM_APPOINTMENT: boolean = false;
  disabled_CONFIRM_APPOINTMENT: boolean = false;
  CONFIRM_APPOINTMENT_Value: string = '';
  CONFIRM_APPOINTMENT_Msg: string = '';
  error_CONFIRM_APPOINTMENT: boolean = false;

  stateOptions: any[] = [];
  arrayVariables: any[] = [''];
  btnArray: any[] = [];
  variablesList:VariablesObject[] = [];
  newAppoinmentQuill: any;
  changeAppoinmentQuill: any;
  cancelAppoinmentQuill: any;
  reminderAppoinmentQuill: any;
  confirmAppoinmentQuill: any;

  arrayActionButton: any[] = [''];

  displayPreview: boolean = false;
  showPreviewText: string = '';
  innerWidth: any;
  bussinesInfo = CommonFunctions.getBusinessInfo();
  profileUrl = '';
  backgroundColor: string = '';
  textColor: string = 'whitesmoke';
  style: any = {};
  messageType:string = "";
  @ViewChild(PhonePreviewComponent) phonePreviewComponent: PhonePreviewComponent | undefined;
  previewText: string = '';

  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private messagesTemplateService: MessagesTemplateService,
    private actionButtonService: ActionButtonService,
    private chatRoomService: ChatRoomService,
  ) { }

  ngOnInit(): void {
    this.stateOptions = [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }];
    this.listActionButton();
    this.listMessagesVariables()
    this.listMessagesTemplate();
    this.getChatRoom();
  }

  getMessages(messageType: string) {
    this.showProgressByType(messageType)
    this.messagesTemplateService.getMessagesTemplate(messageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + messageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.setMessageValues(messageType, json.data, false)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  saveMessage(messageType: string, msgForm: NgForm) {
    this.showProgressByType(messageType)
    if (messageType == 'NEW_APPOINTMENT') {
      if (this.NEW_APPOINTMENT_Msg === '') {
        this.showErrorMessage('New Appointment Field is empty.')
        this.hideProgressBarNEW_APPOINTMENT();
        return;
      }
    } else if (messageType == 'CHANGE_APPOINTMENT') {
      if (this.CHANGE_APPOINTMENT_Msg === '') {
        this.showErrorMessage('Change Appointment Field is empty.')
        this.hideProgressBarCHANGE_APPOINTMENT();
        return;
      }
    } else if (messageType == 'CANCELLED_APPOINTMENT') {
      if (this.CANCELLED_APPOINTMENT_Msg === '') {
        this.showErrorMessage('Cancelled Appointment Field is empty.')
        this.hideProgressBarCANCELLED_APPOINTMENT();
        return;
      }
    } else if (messageType == 'APPOINTMENT_REMINDER') {
      if (this.APPOINTMENT_REMINDER_Msg === '') {
        this.showErrorMessage('Appointment Reminder Field is empty.')
        this.hideProgressBarAPPOINTMENT_REMINDER();
        return;
      }
    } else if (messageType == 'CONFIRM_APPOINTMENT') {
      if (this.CONFIRM_APPOINTMENT_Msg === '') {
        this.showErrorMessage('Confirm Appointment Field is empty.')
        this.hideProgressBarCONFIRM_APPOINTMENT();
        return;
      }
    }
    var setMessage = "";

    if (messageType == 'NEW_APPOINTMENT') {
      setMessage = CKEDITOR.instances['newAppointment'].getData();
    } else if (messageType == 'CHANGE_APPOINTMENT') {
      setMessage = CKEDITOR.instances['changeAppointment'].getData();
    } else if (messageType == 'CANCELLED_APPOINTMENT') {
      setMessage = CKEDITOR.instances['cancelAppointment'].getData();
    } else if (messageType == 'APPOINTMENT_REMINDER') {
      setMessage = CKEDITOR.instances['reminderAppointment'].getData();
    } else if (messageType == 'CONFIRM_APPOINTMENT') {
      setMessage = CKEDITOR.instances['confirmAppointment'].getData();
    }

    const Data = {
      data: {
        active: this.getStatusValueInBoolean(msgForm.value.Value),
        // messageText: msgForm.value.Msg
        messageText: setMessage.replace( /&amp;/g, '&' )
      }
    };

    console.log('Data ' + messageType + ' --->> ' + JSON.stringify(Data));
    this.messagesTemplateService.editMessageTemplate(Data, messageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Message Post Response ' + messageType + ' --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.setMessageValues(messageType, json.data, true)
          this.showSuccessMessage(json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.showInlineErrorMessage(messageType, json.response.displayMessage)
          this.showErrorByType(messageType);
          this.hideAllProgressBar()
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  activateDeactivateMessage(value: string, messageType: string) {
    this.showProgressByType(messageType)
    this.messagesTemplateService.activateDeactivateMessageTemplate(this.getStatusValueInBoolean(value), messageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Message Post Response ' + messageType + ' --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (messageType == 'NEW_APPOINTMENT') {
            this.hideProgressBarNEW_APPOINTMENT();
            this.disabled_NEW_APPOINTMENT = !json.data.active
          } else if (messageType == 'CHANGE_APPOINTMENT') {
            this.hideProgressBarCHANGE_APPOINTMENT();
            this.disabled_CHANGE_APPOINTMENT = !json.data.active
          } else if (messageType == 'CANCELLED_APPOINTMENT') {
            this.hideProgressBarCANCELLED_APPOINTMENT();
            this.disabled_CANCELLED_APPOINTMENT = !json.data.active
          } else if (messageType == 'APPOINTMENT_REMINDER') {
            this.hideProgressBarAPPOINTMENT_REMINDER();
            this.disabled_APPOINTMENT_REMINDER = !json.data.active
          } else if (messageType == 'CONFIRM_APPOINTMENT') {
            this.hideProgressBarCONFIRM_APPOINTMENT();
            this.disabled_CONFIRM_APPOINTMENT = !json.data.active
          }
          this.showSuccessMessage(json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.hideAllProgressBar()
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  resetMessage(messageType: string) {
    this.showProgressByType(messageType)
    this.messagesTemplateService.resetMessagesTemplate(messageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + messageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.setMessageValues(messageType, json.data, true)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.showSuccessMessage(json.response.displayMessage)
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  listMessagesTemplate() {
    this.showAllProgressBar()
    this.messagesTemplateService.listMessagesTemplate().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('list Message Template  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            this.setMessageValues(element.messageType, element, false)
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  async listMessagesVariables() {
    this.messagesTemplateService.listVariables().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('list Message Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayVariables = [];
          json.data = json.data.sort();
          json.data.forEach((element: string) => {
            if('$BOOKING_ID' != element && '$BUSINESS_URL' != element){
              this.arrayVariables.push(element.replace('$', '${').concat('}'))
            }
          });
          console.log()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  setMessageValues(messageType: string, data: any, resetVal: boolean) {
    if (messageType == 'NEW_APPOINTMENT') {
      this.hideProgressBarNEW_APPOINTMENT();
      this.NEW_APPOINTMENT_Msg = data.messageText;
      this.NEW_APPOINTMENT_Value = this.getStatusValue(data.active);
      this.disabled_NEW_APPOINTMENT = !data.active
    } else if (messageType == 'CHANGE_APPOINTMENT') {
      this.hideProgressBarCHANGE_APPOINTMENT();
      this.CHANGE_APPOINTMENT_Msg = data.messageText;
      this.CHANGE_APPOINTMENT_Value = this.getStatusValue(data.active);
      this.disabled_CHANGE_APPOINTMENT = !data.active
    } else if (messageType == 'CANCELLED_APPOINTMENT') {
      this.hideProgressBarCANCELLED_APPOINTMENT();
      this.CANCELLED_APPOINTMENT_Msg = data.messageText;
      this.CANCELLED_APPOINTMENT_Value = this.getStatusValue(data.active);
      this.disabled_CANCELLED_APPOINTMENT = !data.active
    } else if (messageType == 'APPOINTMENT_REMINDER') {
      this.hideProgressBarAPPOINTMENT_REMINDER();
      this.APPOINTMENT_REMINDER_Msg = data.messageText;
      this.APPOINTMENT_REMINDER_Value = this.getStatusValue(data.active);
      this.disabled_APPOINTMENT_REMINDER = !data.active
    } else if (messageType == 'CONFIRM_APPOINTMENT') {
      this.hideProgressBarCONFIRM_APPOINTMENT();
      this.CONFIRM_APPOINTMENT_Msg = data.messageText;
      this.CONFIRM_APPOINTMENT_Value = this.getStatusValue(data.active);
      this.disabled_CONFIRM_APPOINTMENT = !data.active
    }
    this.richTextEditor(messageType, data, resetVal);
  }

  showProgressByType(messageType: string) {
    this.clearError()
    if (messageType == 'NEW_APPOINTMENT') {
      this.showProgressBarNEW_APPOINTMENT();
    } else if (messageType == 'CHANGE_APPOINTMENT') {
      this.showProgressBarCHANGE_APPOINTMENT();
    } else if (messageType == 'CANCELLED_APPOINTMENT') {
      this.showProgressBarCANCELLED_APPOINTMENT();
    } else if (messageType == 'APPOINTMENT_REMINDER') {
      this.showProgressBarAPPOINTMENT_REMINDER();
    } else if (messageType == 'CONFIRM_APPOINTMENT') {
      this.showProgressBarCONFIRM_APPOINTMENT();
    }
  }

  showErrorByType(messageType: string) {
    if (messageType == 'NEW_APPOINTMENT') {
      this.error_NEW_APPOINTMENT = true;
    } else if (messageType == 'CHANGE_APPOINTMENT') {
      this.error_CHANGE_APPOINTMENT = true;
    } else if (messageType == 'CANCELLED_APPOINTMENT') {
      this.error_CANCELLED_APPOINTMENT = true;
    } else if (messageType == 'APPOINTMENT_REMINDER') {
      this.error_APPOINTMENT_REMINDER = true;
    } else if (messageType == 'CONFIRM_APPOINTMENT') {
      this.error_CONFIRM_APPOINTMENT = true;
    }
  }

  clearError() {
    this.error_NEW_APPOINTMENT = false;
    this.error_CHANGE_APPOINTMENT = false;
    this.error_CANCELLED_APPOINTMENT = false;
    this.error_APPOINTMENT_REMINDER = false;
    this.error_CONFIRM_APPOINTMENT = false;
  }

  showAllProgressBar() {
    this.showProgressBarNEW_APPOINTMENT();
    this.showProgressBarCHANGE_APPOINTMENT();
    this.showProgressBarCANCELLED_APPOINTMENT();
    this.showProgressBarAPPOINTMENT_REMINDER();
    this.showProgressBarCONFIRM_APPOINTMENT();
  }

  hideAllProgressBar() {
    this.hideProgressBarNEW_APPOINTMENT();
    this.hideProgressBarCHANGE_APPOINTMENT();
    this.hideProgressBarCANCELLED_APPOINTMENT();
    this.hideProgressBarAPPOINTMENT_REMINDER();
    this.hideProgressBarCONFIRM_APPOINTMENT();
  }

  findChoicesIn(list: any[]) {
    return (searchText: any) =>
      list.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  };

  getChoiceLabel(choice: string) {
    return '${' + `${choice}}`;
  }

  getStatusValueInBoolean(state: string): boolean {
    let value = false;
    if (state == 'active') {
      value = true
    } else {
      value = false
    }
    return value;
  }
  getStatusValue(state: boolean): string {
    let value = '';
    if (state) {
      value = "active"
    } else {
      value = 'inactive'
    }
    return value;
  }

  showProgressBarNEW_APPOINTMENT() {
    this.messageService.clear();
    this.is_Loading_NEW_APPOINTMENT = true;
    this.disabled_NEW_APPOINTMENT = true;
  }

  hideProgressBarNEW_APPOINTMENT() {
    this.is_Loading_NEW_APPOINTMENT = false;
    this.disabled_NEW_APPOINTMENT = false;
  }

  showProgressBarCHANGE_APPOINTMENT() {
    this.messageService.clear();
    this.is_Loading_CHANGE_APPOINTMENT = true;
    this.disabled_CHANGE_APPOINTMENT = true;
  }

  hideProgressBarCHANGE_APPOINTMENT() {
    this.is_Loading_CHANGE_APPOINTMENT = false;
    this.disabled_CHANGE_APPOINTMENT = false;
  }

  showProgressBarCANCELLED_APPOINTMENT() {
    this.messageService.clear();
    this.is_Loading_CANCELLED_APPOINTMENT = true;
    this.disabled_CANCELLED_APPOINTMENT = true;
  }

  hideProgressBarCANCELLED_APPOINTMENT() {
    this.is_Loading_CANCELLED_APPOINTMENT = false;
    this.disabled_CANCELLED_APPOINTMENT = false;
  }

  showProgressBarAPPOINTMENT_REMINDER() {
    this.messageService.clear();
    this.is_Loading_APPOINTMENT_REMINDER = true;
    this.disabled_APPOINTMENT_REMINDER = true;
  }

  hideProgressBarAPPOINTMENT_REMINDER() {
    this.is_Loading_APPOINTMENT_REMINDER = false;
    this.disabled_APPOINTMENT_REMINDER = false;
  }

  showProgressBarCONFIRM_APPOINTMENT() {
    this.messageService.clear();
    this.is_Loading_CONFIRM_APPOINTMENT = true;
    this.disabled_CONFIRM_APPOINTMENT = true;
  }

  hideProgressBarCONFIRM_APPOINTMENT() {
    this.is_Loading_CONFIRM_APPOINTMENT = false;
    this.disabled_CONFIRM_APPOINTMENT = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      detail: msg,
    });
  }
  showInlineErrorMessage(key: string, msg: string) {
    this.messageService.add({
      key: key,
      severity: 'error',
      detail: msg,
    });
  }
  get state(): string {
    return this.utils.state;
  }

  // generate code friendly names
  getFontName(font:any) {
    return font.toLowerCase().replace(/\s/g, "-");
  }

 richTextEditor(messageType: any, setValue: any, resetVal: boolean){
  let btnCheck :any = [
    {
      "name": "Add calendar",
      "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=addcalendar" target="_blank" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=ADD+TO+CALENDAR&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=00f"></a></p>',
      "backgroundColour": null
    },
    {
        "name": "Update calendar",
        "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=updatecalendar" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=UPDATE+CALENDAR&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=00f"></a></p>',
        "backgroundColour": null
    },
    {
        "name": "Remove calendar",
        "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=removecalendar" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=REMOVE+FROM+CALENDAR&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=00f"></a></p>',
        "backgroundColour": null
    },
    {
        "name": "Cancel Appointment",
        "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=cancelappointment" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=CANCEL+APPOINTMENT&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=f00"></a></p>',
        "backgroundColour": null
    },
    {
        "name": "Change Appointment",
        "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=changeappointment" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=CHANGE+APPOINTMENT&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=00f"></a></p>',
        "backgroundColour": null
    },
    {
        "name": "Confirm Appointment",
        "value": '<p><a href="${BUSINESS_URL}/business-calendar-action/${BOOKING_ID}?action=confirmappointment" target="_blank"><img src="https://dabuttonfactory.com/button.png?t=CONFIRM+APPOINTMENT&f=Open+Sans-Bold&ts=10&tc=fff&hp=10&vp=10&c=11&bgt=unicolored&bgc=41b70e"></a></p>',
        "backgroundColour": null
    }
  ];
    // specify the fonts you would 
    var fonts = ['Arial', 'Verdana', 'Tahoma', 'Trebuchet', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Helvetica'];
    var fontNames = fonts.map(font => this.getFontName(font));
    // add fonts to style
    var fontStyles = "";
    var foc = this;
    fonts.forEach(function(font) {
        var fontName = foc.getFontName(font);
        fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
            "content: '" + font + "';" +
            "font-family: '" + font + "', sans-serif;" +
            "}" +
            ".ql-font-" + fontName + "{" +
            " font-family: '" + font + "', sans-serif;" +
            "}";
    });
    var node = document.createElement('style');
    node.innerHTML = fontStyles;
    document.body.appendChild(node);

    // Add fonts to whitelist
    var Font = Quill.import('formats/font');
    Font.whitelist = fontNames;
    Quill.register(Font, true);  

    let cutmBtnName:any = ['Add calendar', 'Update calendar', 'Remove calendar', 'Cancel Appointment', 'Change Appointment', 'Confirm Appointment'];
    this.arrayActionButton.forEach((element:any) => {
      cutmBtnName.push(element.name);
      btnCheck.push(element);
    });
  
  
  CKEDITOR.config.strinsert_strings = this.arrayVariables;

  if (messageType == 'NEW_APPOINTMENT' && resetVal == false) {
        
    CKEDITOR.replace('newAppointment', {
      toolbar: [
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'insert', items: ['Smiley'] },
        { name: 'links', items: [ 'Link', 'Unlink'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['my-combo', 'Maximize'] },
      ],
      removePlugins : 'elementspath',
	    resize_enabled  : false,
      on: {
        pluginsLoaded: function () {
          var editor: any = this,
            config = editor.config;
          config.resize_maxHeight = 300;
          editor.ui.addRichCombo('my-combo', {
            label: 'Variables',
            title: 'Variables',
            toolbar: 'basicstyles,0',

            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: { 'aria-label': 'My Dropdown Title' }
            },

            init: function () {
              let selectActionButton = CKEDITOR.config.strinsert_strings;

              this.startGroup('Variables');
              selectActionButton.forEach((elemet: any) => {
                this.add(elemet, elemet);
              });

            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            }
          });
        }
      },
    });
    CKEDITOR.instances['newAppointment'].setData(this.NEW_APPOINTMENT_Msg);
    
  } else if (messageType == 'CHANGE_APPOINTMENT' && resetVal == false) {

    CKEDITOR.replace('changeAppointment', {
      toolbar: [
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'insert', items: ['Smiley'] },
        { name: 'links', items: [ 'Link', 'Unlink'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['my-combo', 'Maximize'] },
      ],
      removePlugins : 'elementspath',
	    resize_enabled  : false,
      on: {
        pluginsLoaded: function () {
          var editor: any = this,
            config = editor.config;
          config.resize_maxHeight = 300;
          editor.ui.addRichCombo('my-combo', {
            label: 'Variables',
            title: 'Variables',
            toolbar: 'basicstyles,0',

            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: { 'aria-label': 'My Dropdown Title' }
            },

            init: function () {
              let selectActionButton = CKEDITOR.config.strinsert_strings;

              this.startGroup('Variables');
              selectActionButton.forEach((elemet: any) => {
                this.add(elemet, elemet);
              });

            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            }
          });
        }
      },
    });
    CKEDITOR.instances['changeAppointment'].setData(this.CHANGE_APPOINTMENT_Msg);

  } else if (messageType == 'CANCELLED_APPOINTMENT' && resetVal == false) {

    CKEDITOR.replace('cancelAppointment', {
      toolbar: [
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'insert', items: ['Smiley'] },
        { name: 'links', items: [ 'Link', 'Unlink'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['my-combo', 'Maximize'] },
      ],
      removePlugins : 'elementspath',
	    resize_enabled  : false,
      on: {
        pluginsLoaded: function () {
          var editor: any = this,
            config = editor.config;
          config.resize_maxHeight = 300;
          editor.ui.addRichCombo('my-combo', {
            label: 'Variables',
            title: 'Variables',
            toolbar: 'basicstyles,0',

            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: { 'aria-label': 'My Dropdown Title' }
            },

            init: function () {
              let selectActionButton = CKEDITOR.config.strinsert_strings;

              this.startGroup('Variables');
              selectActionButton.forEach((elemet: any) => {
                this.add(elemet, elemet);
              });

            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            }
          });
        }
      },
    });
    CKEDITOR.instances['cancelAppointment'].setData(this.CANCELLED_APPOINTMENT_Msg);

  } else if (messageType == 'APPOINTMENT_REMINDER' && resetVal == false) {

    CKEDITOR.replace('reminderAppointment', {
      toolbar: [
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'insert', items: ['Smiley'] },
        { name: 'links', items: [ 'Link', 'Unlink'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['my-combo', 'Maximize'] },
      ],
      removePlugins : 'elementspath',
	    resize_enabled  : false,
      on: {
        pluginsLoaded: function () {
          var editor: any = this,
            config = editor.config;
          config.resize_maxHeight = 300;
          editor.ui.addRichCombo('my-combo', {
            label: 'Variables',
            title: 'Variables',
            toolbar: 'basicstyles,0',

            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: { 'aria-label': 'My Dropdown Title' }
            },

            init: function () {
              let selectActionButton = CKEDITOR.config.strinsert_strings;

              this.startGroup('Variables');
              selectActionButton.forEach((elemet: any) => {
                this.add(elemet, elemet);
              });

            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            }
          });
        }
      },
    });
    CKEDITOR.instances['reminderAppointment'].setData(this.APPOINTMENT_REMINDER_Msg);

  } else if (messageType == 'CONFIRM_APPOINTMENT' && resetVal == false) {
        
    CKEDITOR.replace('confirmAppointment', {
      toolbar: [
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',] },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        { name: 'insert', items: ['Smiley'] },
        { name: 'links', items: [ 'Link', 'Unlink'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['my-combo', 'Maximize'] },
      ],
      removePlugins : 'elementspath',
	    resize_enabled  : false,
      on: {
        pluginsLoaded: function () {
          var editor: any = this,
            config = editor.config;
          config.resize_maxHeight = 300;
          editor.ui.addRichCombo('my-combo', {
            label: 'Variables',
            title: 'Variables',
            toolbar: 'basicstyles,0',

            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: { 'aria-label': 'My Dropdown Title' }
            },

            init: function () {
              let selectActionButton = CKEDITOR.config.strinsert_strings;

              this.startGroup('Variables');
              selectActionButton.forEach((elemet: any) => {
                this.add(elemet, elemet);
              });

            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            }
          });
        }
      },
    });
    CKEDITOR.instances['confirmAppointment'].setData(this.CONFIRM_APPOINTMENT_Msg);
    
  }

  if(messageType == 'NEW_APPOINTMENT' && resetVal == true){
    // this.newAppoinmentQuill.root.innerHTML = this.NEW_APPOINTMENT_Msg;
    CKEDITOR.instances['newAppointment'].setData(this.NEW_APPOINTMENT_Msg);
  }else if(messageType == 'CHANGE_APPOINTMENT' && resetVal == true){
    // this.changeAppoinmentQuill.root.innerHTML = this.CHANGE_APPOINTMENT_Msg;
    CKEDITOR.instances['changeAppointment'].setData(this.CHANGE_APPOINTMENT_Msg);
  }else if(messageType == 'CANCELLED_APPOINTMENT' && resetVal == true){
    // this.cancelAppoinmentQuill.root.innerHTML = this.CANCELLED_APPOINTMENT_Msg;
    CKEDITOR.instances['cancelAppointment'].setData(this.CANCELLED_APPOINTMENT_Msg);
  }else if(messageType == 'APPOINTMENT_REMINDER' && resetVal == true){
    // this.reminderAppoinmentQuill.root.innerHTML = this.APPOINTMENT_REMINDER_Msg;
    CKEDITOR.instances['reminderAppointment'].setData(this.APPOINTMENT_REMINDER_Msg);
  } else if(messageType == 'CONFIRM_APPOINTMENT' && resetVal == true){
    // this.confirmAppoinmentQuill.root.innerHTML = this.CONFIRM_APPOINTMENT_Msg;
    CKEDITOR.instances['confirmAppointment'].setData(this.CONFIRM_APPOINTMENT_Msg);
  }
  
  }

  listActionButton() {
    this.showAllProgressBar();
    this.actionButtonService.ActionButtonlistByBusinessIdAndStatus().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Keyword Variables  Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.arrayActionButton = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar();
      }
    );
  }

  showPreview(messageType:string){
    // this.showAllProgressBar();
    this.messageType =  messageType;
    // this.phonePreviewComponent?.childFunction(messageType);
    this.displayPreview = true;
    if(messageType == 'NEW_APPOINTMENT'){
      // this.newAppoinmentQuill.root.innerHTML = this.NEW_APPOINTMENT_Msg;
      this.previewText = CKEDITOR.instances['newAppointment'].getData();
    }else if(messageType == 'CHANGE_APPOINTMENT'){
      // this.changeAppoinmentQuill.root.innerHTML = this.CHANGE_APPOINTMENT_Msg;
      this.previewText = CKEDITOR.instances['changeAppointment'].getData();
    }else if(messageType == 'CANCELLED_APPOINTMENT'){
      // this.cancelAppoinmentQuill.root.innerHTML = this.CANCELLED_APPOINTMENT_Msg;
      this.previewText = CKEDITOR.instances['cancelAppointment'].getData();
    }else if(messageType == 'APPOINTMENT_REMINDER'){
      // this.reminderAppoinmentQuill.root.innerHTML = this.APPOINTMENT_REMINDER_Msg;
      this.previewText = CKEDITOR.instances['reminderAppointment'].getData();
    } else if(messageType == 'CONFIRM_APPOINTMENT'){
      // this.confirmAppoinmentQuill.root.innerHTML = this.CONFIRM_APPOINTMENT_Msg;
      this.previewText = CKEDITOR.instances['confirmAppointment'].getData();
    }
    this.phonePreviewComponent?.childFunction(messageType, this.previewText);
    // this.messagesTemplateService.getRenderTemplate(messageType).subscribe(
    //   (response) => {
    //     var json = JSON.parse(JSON.stringify(response));
    //     console.log('Get ' + messageType + ' Response --->> ' + JSON.stringify(json));
    //     if (json.response.status == 'SUCCESS') {
    //       this.showPreviewText = json.data;
    //       this.messageType =  messageType;
    //     } else {
    //       this.showErrorMessage(json.response.displayMessage)
    //     }
    //     this.hideAllProgressBar();
    //     this.displayPreview = true;
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.showErrorMessage(AppSettings.ERROR_MSG)
    //     this.hideAllProgressBar();
    //   }
    // );
  }


  getRenderString(messageType: string) {
    this.showProgressByType(messageType)
    this.messagesTemplateService.getRenderTemplate(messageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + messageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.setMessageValues(messageType, json.data, false)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar()
      }
    );
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  getChatRoom() {
    this.showAllProgressBar();
    this.chatRoomService.getChatRoom().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get chat room response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          // this.setData(json.data)
          this.backgroundColor = json.data.backgroundColor;
          if(json.data.image == null){
            this.profileUrl = 'assets/images/picture.svg';
          }else{
            this.profileUrl = environment.IMAGE_BASE_URL + json.data.image;
          }
          this.textColor = json.data.textColor;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideAllProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideAllProgressBar();
      }
    );
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 34;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }
}
