import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { CommonFunctions } from "src/app/Utilities/CommonFunctions";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private SubscriptionType: string = "";
  private Amount: string = "";
  private IsPaymentSuccessful: boolean = false;
  private SubscriptionId: string = "";
  constructor(private http: HttpClient) { }

  listSubscription() {
    const url = environment.API_URL + "/api/anonymous/subscriptions";
    // const TOKEN = CommonFunctions.getToken();
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: "Bearer " + TOKEN,
    //     "Content-Type": "application/json",
    //     'BusinessId': CommonFunctions.getBusinessId().toString()
    //   }),
    // };
    // return this.http.get(url, httpOptions).pipe(timeout(60000));
    return this.http.get(url).pipe(timeout(60000));
  }

  getSingleSubscription(subs_id: string) {
    const url = environment.API_URL + "/api/anonymous/subscription/" + subs_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  get amount(): string {
    return this.Amount;
  }
  set amount(val: string) {
    this.Amount = val;
  }

  get subscriptionType(): string {
    return this.SubscriptionType;
  }
  set subscriptionType(val: string) {
    this.SubscriptionType = val;
  }

  get isPaymentSuccessful(): boolean {
    return this.IsPaymentSuccessful;
  }
  set isPaymentSuccessful(val: boolean) {
    this.IsPaymentSuccessful = val;
  }

  get subscriptionId(): string {
    return this.SubscriptionId;
  }
  set subscriptionId(val: string) {
    this.SubscriptionId = val;
  }
}
