import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { MessageBotService } from '../services/message-bot/message-bot.service';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-message-bot-configuration',
  templateUrl: './message-bot-configuration.component.html',
  styleUrls: ['./message-bot-configuration.component.css'],
})
export class MessageBotConfigurationComponent implements OnInit {
  isLoadingAfterHour: boolean = false;
  disabledAfterHour: boolean = false;
  disabled: boolean = false;
  isLoadingSpecialOccassion: boolean = false;
  disabledSpecialOccassion: boolean = false;
  isFormValid = false;
  items: MenuItem[] = [{ label: 'Message Bot Configuration' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  stateOptions: any[] = [];

  afterHourValue: string = '';
  specialOccassionValue: string = '';
  afterHourMsg: string = '';
  specialOccassionMsg: string = '';
  unknowclientValue: string = '';
  unknowclientMsg: string = '';
  isLoadingUnknowClient: boolean = false;
  disabledUnknowClient: boolean = false;

  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private messageBotService: MessageBotService
  ) { }

  ngOnInit(): void {
    this.stateOptions = [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }];
    this.getMessages('AFTER_HOUR');
    this.getMessages('SPECIAL_OCCASION');
    this.getMessages('UNKNOWN_CLIENT');
  }


  getMessages(MessageType: string) {
    if (MessageType == 'AFTER_HOUR') {
      this.showProgressBarAfterHour();
    } else {
      this.showProgressBarSpecialOccassion();
    }
    this.messageBotService.getMessages(MessageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + MessageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (MessageType == 'AFTER_HOUR') {
            this.hideProgressBarAfterHour();
            this.afterHourMsg = json.data.text;
            this.afterHourValue = this.getStatusValue(json.data.active)
            this.disabledAfterHour = !json.data.active
          } else if(MessageType == 'SPECIAL_OCCASION') {
            this.hideProgressBarSpecialOccassion();
            this.specialOccassionMsg = json.data.text;
            this.specialOccassionValue = this.getStatusValue(json.data.active);
            this.disabledSpecialOccassion = !json.data.active
          } else if(MessageType == 'UNKNOWN_CLIENT') {
            this.hideProgressBarUnknowClient();
            this.unknowclientMsg = json.data.text;
            this.unknowclientValue = this.getStatusValue(json.data.active);
            this.disabledUnknowClient = !json.data.active;
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBarAfterHour();
        this.hideProgressBarSpecialOccassion();
      }
    );
  }

  saveMessage(MessageType: string, msgForm: NgForm) {
    if (MessageType == 'AFTER_HOUR') {
      this.showProgressBarAfterHour();
    } else if(MessageType == 'SPECIAL_OCCASION'){
      this.showProgressBarSpecialOccassion();
    } else if(MessageType == 'UNKNOWN_CLIENT'){
      this.showProgressBarUnknowClient();
    }
    if (this.afterHourMsg === '') {
      this.showErrorMessage('After Hour Field is empty.')
      this.hideProgressBarAfterHour();
      return;
    }
    if (this.specialOccassionMsg === '') {
      this.showErrorMessage('Special Occassion Field is empty.')
      this.hideProgressBarSpecialOccassion();
      return;
    }

    if (this.unknowclientMsg === '') {
      this.showErrorMessage('Unknow Client Field is empty.')
      this.hideProgressBarUnknowClient();
      return;
    }

    const Data = {
      data: {
        active: this.getStatusValueInBoolean(msgForm.value.Value),
        text: msgForm.value.Msg
      }
    };
    this.messageBotService.editMessage(Data, MessageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Message Post Response ' + MessageType + ' --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (MessageType == 'AFTER_HOUR') {
            this.hideProgressBarAfterHour();
            this.afterHourMsg = json.data.text;
            this.afterHourValue = this.getStatusValue(json.data.active)
            this.disabledAfterHour = !json.data.active
          } else if(MessageType == 'SPECIAL_OCCASION'){
            this.hideProgressBarSpecialOccassion();
            this.specialOccassionMsg = json.data.text;
            this.specialOccassionValue = this.getStatusValue(json.data.active);
            this.disabledSpecialOccassion = !json.data.active
          } else if(MessageType == 'UNKNOWN_CLIENT'){
            this.hideProgressBarUnknowClient();
            this.unknowclientMsg = json.data.text;
            this.unknowclientValue = this.getStatusValue(json.data.active);
            this.disabledUnknowClient = !json.data.active
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBarAfterHour();
        this.hideProgressBarSpecialOccassion();
      }
    );
  }

  activateDeactivateMessage(value: string, MessageType: string) {
    if (MessageType == 'AFTER_HOUR') {
      this.showProgressBarAfterHour();
    } else if (MessageType == 'SPECIAL_OCCASION') {
      this.showProgressBarSpecialOccassion();
    } else if (MessageType == 'UNKNOWN_CLIENT') {
      this.showProgressBarUnknowClient();
    }
    this.messageBotService.activateDeactivateMessage(this.getStatusValueInBoolean(value), MessageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Message Post Response ' + MessageType + ' --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (MessageType == 'AFTER_HOUR') {
            this.hideProgressBarAfterHour();
            this.disabledAfterHour = !json.data.active
          } else if (MessageType == 'SPECIAL_OCCASION') {
            this.hideProgressBarSpecialOccassion();
            this.disabledSpecialOccassion = !json.data.active
          } else if (MessageType == 'UNKNOWN_CLIENT') {
            this.hideProgressBarUnknowClient();
            this.disabledUnknowClient = !json.data.active
          }
          this.showSuccessMessage(json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBarAfterHour();
        this.hideProgressBarSpecialOccassion();
        this.hideProgressBarUnknowClient();
      }
    );
  }

  resetMessage(MessageType: string) {
    if (MessageType == 'AFTER_HOUR') {
      this.showProgressBarAfterHour();
    } else if (MessageType == 'SPECIAL_OCCASION') {
      this.showProgressBarSpecialOccassion();
    } else if (MessageType == 'UNKNOWN_CLIENT') {
      this.showProgressBarUnknowClient();
    }
    this.messageBotService.resetMessages(MessageType).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + MessageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (MessageType == 'AFTER_HOUR') {
            this.hideProgressBarAfterHour();
            this.afterHourMsg = json.data.text;
            this.afterHourValue = this.getStatusValue(json.data.active)
            this.disabledAfterHour = !json.data.active
          } else if (MessageType == 'SPECIAL_OCCASION') {
            this.hideProgressBarSpecialOccassion();
            this.specialOccassionMsg = json.data.text;
            this.specialOccassionValue = this.getStatusValue(json.data.active);
            this.disabledSpecialOccassion = !json.data.active
          }  else if (MessageType == 'UNKNOWN_CLIENT') {
            this.hideProgressBarUnknowClient();
            this.unknowclientMsg = json.data.text;
            this.unknowclientValue = this.getStatusValue(json.data.active);
            this.disabledUnknowClient = !json.data.active
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBarAfterHour();
        this.hideProgressBarSpecialOccassion();
        this.hideProgressBarUnknowClient();
      }
    );
  }



  getStatusValueInBoolean(state: string): boolean {
    let value = false;
    if (state == 'active') {
      value = true
    } else {
      value = false
    }
    return value;
  }
  getStatusValue(state: boolean): string {
    let value = '';
    if (state) {
      value = "active"
    } else {
      value = 'inactive'
    }
    return value;
  }

  showProgressBarAfterHour() {
    this.messageService.clear();
    this.isLoadingAfterHour = true;
    this.disabledAfterHour = true;
    this.disabled = true;

  }

  showProgressBarSpecialOccassion() {
    this.messageService.clear();
    this.isLoadingSpecialOccassion = true;
    this.disabledSpecialOccassion = true;
    this.disabled = true;
  }

  hideProgressBarAfterHour() {
    this.isLoadingAfterHour = false;
    this.disabledAfterHour = false;
    this.disabled = false;

  }

  hideProgressBarSpecialOccassion() {
    this.isLoadingSpecialOccassion = false;
    this.disabledSpecialOccassion = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      detail: msg,
    });
  }
  get state(): string {
    return this.utils.state;
  }

  hideProgressBarUnknowClient() {
    this.isLoadingUnknowClient = false;
    this.disabledUnknowClient = false;
    this.disabled = false;
  }

  showProgressBarUnknowClient() {
    this.messageService.clear();
    this.isLoadingUnknowClient = true;
    this.disabledUnknowClient = true;
    this.disabled = true;
  }
}
