import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { timeout } from "rxjs/operators";
import { CommonFunctions } from "src/app/Utilities/CommonFunctions";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private router: Router, private http: HttpClient) { }

  addUser(user: any) {
    console.log("Add User Service Data ---> " + JSON.stringify(user));
    const url = environment.API_URL + "/api/user/add";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, user, httpOptions).pipe(timeout(60000));
  }

  editUser(user: any, id: number) {
    console.log("Edit User Service Data ---> " + JSON.stringify(user));
    const url = environment.API_URL + "/api/user/" + id + "/profile/update";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, user, httpOptions).pipe(timeout(60000));
  }

  getCountries() {
    const url = environment.API_URL + "/api/global/country";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  resetPassword(password: any, id: any) {
    const url = environment.API_URL + '/api/user/' + id + '/resetPassword';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, password, httpOptions).pipe(timeout(60000));
  }

  removeUser(id: any) {
    console.log("Id zRS ---> " + id);
    const TOKEN = CommonFunctions.getToken();
    console.log(TOKEN);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + "/api/user/" + id + "/remove";
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  listUsers(pageNumber: any, searchText: string, sortData: any) {
    let params = new HttpParams().set('page', pageNumber)
    if (searchText != null && searchText != '') {
      params = params.set('search', searchText);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortBy', sortData.sortBy);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortDirection', sortData.sortDirection);
    }
    const url = environment.API_URL + "/api/user";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getUser(id: any) {
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const url = environment.API_URL + "/api/user/" + id + "/profile";
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  activateUser(
    id: any,
    subscriptionType: any,
    subscriptionId: any,
    paymentRefNo: any,
    price: any,
    duration: any
  ) {
    let params = new HttpParams().set('subscriptionType', subscriptionType);
    params = params.set('subscriptionId', subscriptionId);
    params = params.set('price', price);
    params = params.set('paymentRefNo', paymentRefNo);
    params = params.set('duration', duration);
    // console.log("Add User Service Data ---> " + JSON.stringify(user));
    const url = environment.API_URL + "/api/user/" + id + "/activate?";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
      }),
      params: params,
    };
    console.log(url + params);
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }


}
