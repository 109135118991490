import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AppointmentService } from '../services/appointment/appointment.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import * as XLSX from 'xlsx';

interface Service {
  serviceName: string;
  serviceStatus: string;
  numberOfBookings: number;
  totalRevenue: number;
}

interface Clients {
  id: number;
  name: string;
}

type ServiceCategoryDetail = {
  name: string;
  seat?: number | null;
};

@Component({
  selector: 'app-service-utilization-report',
  templateUrl: './service-utilization-report.component.html',
  styleUrls: ['./service-utilization-report.component.css']
})
export class ServiceUtilizationReportsComponent implements OnInit {

  clientSegments: Clients[] = [];

  filteredServiceUtilization: Service[] = [];

  selectedDurationServiceUtilization = 'YTD';
  startDateServiceUtilization: string | undefined;
  endDateServiceUtilization: string | undefined;

  startDate: string | undefined;
  endDate: string | undefined;

  selectedClientSegmentService: string = 'All';

  serviceTypeFilterList: any[] = [];

  serviceTypeList: any[]= [];

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = this.filteredServiceUtilization.length;

  isReportLoading: boolean = false;

  sortField: string = 'serviceName';
  sortOrder: number = 1;

  reportDurationOptions = [
    { id: 'All', name: 'All' },
    { id: 'YTD', name: 'Year to Date (YTD)' },
    { id: 'custom', name: 'Date Range' }
  ];
  

  get paginatedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredServiceUtilization.slice(startIndex, startIndex + this.itemsPerPage);
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  constructor(
    public utils: UtilsService,
    private reportService: ReportService,
    private clientSegmentService: ClientSegmentationService,
    private appointmentService: AppointmentService,
    private serviceTypeService: ServiceTypeService,
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  get state(): string {
    return this.utils.state;
  }

  initializeData() {
    console.log("csk",this.selectedClientSegmentService);
    
    this.getClientSegments();
    this.loadDataAndFetchServiceUtilization();
    this.getServiceTypeList();
  }

  loadDataAndFetchServiceUtilization() {
    if (!this.serviceTypeFilterList || this.serviceTypeFilterList.length === 0) {
      this.getAppointmentFilterOptions().subscribe(
        () => {
          this.fetchServiceUtilizationData();
        },
        (error: any) => {
          console.error('Error fetching Appointment Filter Options:', error);
        }
      );
    } else {
      this.fetchServiceUtilizationData();
    }
  }

  getClientSegments() {
    this.clientSegmentService.getClientSegmentationList().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        this.clientSegments = json.data;
        this.clientSegments = [{ id: 0, name: 'All' }, ...this.clientSegments];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  convertDate(dateString: string | undefined): string | undefined {
    if(dateString != undefined){
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.getFullYear() + '-' + 
                        (dateObject.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                        dateObject.getDate().toString().padStart(2, '0');
      return formattedDate;
    }
    return undefined;
  }

  fetchServiceUtilizationData() {
    this.isReportLoading = true;
    if(this.selectedDurationServiceUtilization === 'custom'){
      this.startDate = this.convertDate(this.startDateServiceUtilization);
      this.endDate = this.convertDate(this.endDateServiceUtilization);
    } 
    else if(this.selectedDurationServiceUtilization === 'All'){
      this.startDate = this.convertDate('2010-01-01');

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');

      this.endDate = this.convertDate(`${year}-${month}-${day}`);
    }

    this.reportService.getServiceUtilizationReport(
        this.selectedClientSegmentService,
        this.selectedDurationServiceUtilization,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response: any) => {
          var json = JSON.parse(JSON.stringify(response));  
          this.filteredServiceUtilization = json.data;
          const serviceTypeSet = new Set(this.serviceTypeFilterList.map(service => service.value));
          this.filteredServiceUtilization.forEach(service => {
            if (!serviceTypeSet.has(service.serviceName)) {
              service.serviceStatus = 'Inactive';
            }
          });
          if(this.selectedDurationServiceUtilization == "All"){
            this.processServices();
          }
          this.isReportLoading = false;
        },
        (error: any) => {
          console.error(error);
          this.isReportLoading = false;
        }
      );
  }

  onClientSegmentServiceChange() {
    this.fetchServiceUtilizationData(); 
  }  

  onReportDurationChange(event: any): void {
    if (this.selectedDurationServiceUtilization === 'YTD') {
      setTimeout(() => {
        this.fetchServiceUtilizationData();
        this.startDateServiceUtilization= undefined;
        this.endDateServiceUtilization= undefined;
      }, 0);
    } else if (this.selectedDurationServiceUtilization === 'All') {
      setTimeout(() => {
        this.fetchServiceUtilizationData();
      }, 0);
    }
  }

  getAppointmentFilterOptions() {
    return this.appointmentService.getAppointmentFilterOptions().pipe(
      switchMap((response) => {
        const json = JSON.parse(JSON.stringify(response));
        if (json.response.status === 'SUCCESS') {
          this.serviceTypeFilterList = json.data.serviceType.options;
        }
        return of(true);
      })
    );
  }

  exportTableToXLSX() {
    const data = this.filteredServiceUtilization;

    const headers = ['Service Name', 'Status', 'Bookings', 'Revenue'];
  
    const rows = data.map(service => [
      service.serviceName || '',
      service.serviceStatus || '',
      service.numberOfBookings ? `${service.numberOfBookings}` : '0',
      service.totalRevenue ? '$ '+Math.floor(service.totalRevenue) : '$ 0'
    ]);

    let xlsContent = `
      <table>
        <thead>
          <tr>
            <th style="text-align: center; border: 1px solid #000; width: 400px;">Service Name</th>
            <th style="text-align: center; border: 1px solid #000; width: 140px;">Status</th>
            <th style="text-align: center; border: 1px solid #000; width: 200px;">Bookings</th>
            <th style="text-align: center; border: 1px solid #000; width: 150px;">Revenue</th>
          </tr>
        </thead>
        <tbody>
    `;
  
    rows.forEach(row => {
      xlsContent += `
        <tr>
          <td style="text-align: left; vertical-align: middle; border: 1px solid #000">${row[0]}</td>
          <td style="text-align: center; border: 1px solid #000">${row[1]}</td>
          <td style="text-align: center; vertical-align: middle; border: 1px solid #000">${row[2]}</td>
          <td style="text-align: right; vertical-align: middle; border: 1px solid #000">${row[3]}</td>
        </tr>
      `;
    });
  
    xlsContent += `
        </tbody>
      </table>
    `;
  
    const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel' });
    const url = URL.createObjectURL(blob);

    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const fileName = `${CommonFunctions.getBusinessInfo().name}-Service Utilization Report-${year}${month}${day}-${hours}${minutes}.xls`;

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  
  printPage(id: any): void {
    const printContents = document.getElementById(id)?.innerHTML;
    const originalContents = document.body.innerHTML;

    const originalItemsPerPage = this.itemsPerPage;
    this.itemsPerPage = this.filteredServiceUtilization.length;
  
    if (printContents) {
      const printContainer = document.createElement('div');
      printContainer.id = 'print-container';
      printContainer.style.display = 'none';
      printContainer.innerHTML = printContents;
      document.body.appendChild(printContainer);
  
      const children = Array.from(document.body.children);
      children.forEach(child => {
        if (child.id !== 'print-container') {
          (child as HTMLElement).style.display = 'none';
        }
      });
  
      printContainer.style.display = 'block';
      window.print();
  
      children.forEach(child => {
        if (child.id !== 'print-container') {
          (child as HTMLElement).style.display = '';
        }
      });
  
      printContainer.remove();
    }

    this.itemsPerPage = originalItemsPerPage;
  }

  getServiceTypeList() {
    this.serviceTypeService.allServiceType().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeList = []
          this.serviceTypeList = json.data;
        }
      }, (error: any) => {
        console.error(error);
      })
  }

  processServices() {
    const today = new Date();
    const categoriesToCheck = ["Non-Recurring", "Recurring Monthly", "Recurring Custom"];
    const dailyBiToCheck= ["Recurring Bi-Weekly", "Recurring Daily"];
    
    this.serviceTypeList.forEach((service) => {
      console.log(service.name);
      
      const isServiceNameInFilter = this.filteredServiceUtilization.some((filter) => filter.serviceName === service.name);
        if (categoriesToCheck.includes(service.serviceCategory) && service.serviceCategoryDetails && !isServiceNameInFilter) {
            let details: ServiceCategoryDetail[] = [];
            try {
                details = JSON.parse(service.serviceCategoryDetails);
            } catch (e) {
                console.error(`Error parsing serviceCategoryDetails for service ${service.name}`, e);
            }

            const hasFutureDates = details.some((detail) => {
                const date = new Date(detail.name);
                return date > today;
            });

            const serviceStatus = hasFutureDates ? "Active" : "Inactive";

            this.filteredServiceUtilization.push({
                serviceName: service.name,
                serviceStatus,
                numberOfBookings: 0,
                totalRevenue: 0,
            });
        }
        else if(dailyBiToCheck.includes(service.serviceCategory) && !isServiceNameInFilter){
          this.filteredServiceUtilization.push({
            serviceName: service.name,
            serviceStatus: "Active",
            numberOfBookings: 0,
            totalRevenue: 0,
          });
        }
    });
  }
  
}