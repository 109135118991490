<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Support Tickets
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="p-mb-3 toolbar">
                    <div class="p-grid p-ai-center d-none d-lg-flex d-xl-flex">
                        <div class="p-col p-md-3 p-lg-3 p-fluid ">
                            <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" optionLabel="name"
                                optionValue="value" [showClear]="true" placeholder="Select Status"
                                (onChange)="onFilterValueChange($event)">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-3 p-lg-3 p-fluid ">

                        </div>
                        <div class="p-col p-md-6 p-lg-6 p-jc-end d-none d-lg-flex d-xl-flex">
                            <button pButton pRipple type="button" label="Add Ticket" class="p-button-sm p-button-raised"
                                style="font-size: 12px;" icon="pi pi-plus"
                                (click)="showHideModalDialog('add_ticket')"></button>
                        </div>
                    </div>
                    <div class="p-grid d-lg-none">
                        <div class="p-col p-d-flex p-jc-end">
                            <button pButton pRipple type="button" label="Add Ticket" class="p-button-sm p-button-raised"
                                style="font-size: 12px;" icon="pi pi-plus"
                                (click)="showHideModalDialog('add_ticket')"></button>
                        </div>
                    </div>

                </div>
                <p-messages key="successMsg" [style]="{'font-size': '12px'}"></p-messages>
                <p-table #dt1 [columns]="cols" [value]="ticketsList"
                    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                    [responsive]='true' [rowHover]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr style="font-size: small;">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                            <!-- <th>
                                        Action
                                    </th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ticket let-columns="columns">
                        <tr style="font-size: smaller;">
                            <td *ngFor="let col of columns">
                                <span class="p-column-title">{{col.header}}</span>
                                <p-button *ngIf="col.header == 'Title';else otherDetails" [label]="ticket[col.field]"
                                    styleClass="p-button-link p-button-sm" (click)="getTicket('view_ticket',ticket)">
                                </p-button>
                                <ng-template #otherDetails>
                                    {{ticket[col.field]}}
                                </ng-template>
                            </td>
                            <!-- <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        <a href="javascript:void(0);" data-toggle="modal" (click)="getTicket('edit_ticket',ticket)">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                        </a>
                                        &nbsp;
                                        <a href="javascript:void(0);" data-toggle="modal"
                                            (click)="confirmDeleteDialog(ticket)">
                                            <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                                        </a>
                                    </td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                <div *ngIf="isLoading" class="alignCenter">
                                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                    </p-progressSpinner>
                                </div>
                                <p-messages *ngIf="ticketsList?.length == 0 && !isLoading" key="errMsg"
                                    class="alignCenter">
                                    <ng-template pTemplate>
                                        <i class="pi pi-copy" style="font-size: 2rem"></i>
                                        <div class="p-ml-2">NO DATA FOUND</div>
                                    </ng-template>
                                </p-messages>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
                </p-paginator>
                <!-- <div class="p-d-flex p-jc-between p-p-2">
                            <button pButton label="Prev" icon="pi pi-angle-left" class="p-button-text p-button-sm"
                                (click)="prevList()" [disabled]="currentPage==1"></button>
                            <button pButton label="Next" icon="pi pi-angle-right" iconPos="right"
                                class="p-button-text p-button-sm" (click)="nextList()"
                                [disabled]="(ticketsList.length < 10)"></button>
                        </div> -->
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- REMOVE TICKET MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>


<!-- ADD/EDIT TICKET -->
<p-dialog #editDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,editDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <div style="min-width: 60vw;max-width: 70vw;">
        <div class="row">
            <div class="form-group col-md-12">
                <label>Title<span class="symbol required"></span></label>
                <input type="text" class="form-control border-black" name="titleText" placeholder="Enter Title"
                    [(ngModel)]="titleText" required />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Description<span class="symbol required"></span></label>
                <div style="position: relative;">
                    <textarea pInputTextarea type="text" class="form-control" name="descText"
                        placeholder="Enter Description..." [(ngModel)]="descText" maxlength="500">
                    </textarea>
                    <span class="remaning">{{descText.length}}/500</span>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Attachment</label>
                <div class="file_upload_container">
                    <div class="p-d-flex p-jc-between">
                        <div class="p-d-flex">
                            <input type="file" #fileInput class="file-input" name="fileInput"
                                accept=".csv, .pdf, .docx, .jpg, .png" />
                            <!-- <button pButton pRipple type="button" icon="pi pi-upload"
                        class="p-button-rounded p-button-raised p-ml-2" (click)="importExcelPreview(fileInput)"
                        [disabled]="uploadedFiles.length == 0"></button> -->
                        </div>
                    </div>
                    <div class="p-pt-1">Upload csv, pdf, jpg , docx only</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button *ngIf="headerName=='Add Ticket'" icon="pi pi-plus" (click)="addTicket(fileInput)" label="Add"
            [disabled]="disabled">
        </p-button>
        <!-- <p-button *ngIf="headerName=='Edit Ticket'" icon="pi pi-save" (click)="editTicket()" label="Save"
            [disabled]="disabled"></p-button> -->
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- ADD COMMENT -->
<p-dialog #commentDialog [header]="headerName" [(visible)]="displayCommentModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onShow)="showDialogMaximized($event,commentDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <div style="min-width: 60vw;max-width: 70vw;">
        <div class="row">
            <div class="form-group col-md-12">
                <label>Comment<span class="symbol required"></span></label>
                <div style="position: relative;">
                    <textarea pInputTextarea type="text" class="form-control" name="commentText"
                        placeholder="Enter Comment..." [(ngModel)]="commentText" maxlength="500">
                    </textarea>
                    <span class="remaning">{{commentText.length}}/500</span>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Attachment</label>
                <div class="file_upload_container">
                    <div class="p-d-flex p-jc-between">
                        <div class="p-d-flex">
                            <input type="file" #fileInputComment class="file-input"
                                accept=".csv, .pdf, .docx, .jpg, .png" />
                        </div>
                    </div>
                    <div class="p-pt-1">Upload csv, pdf, jpg , docx only</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button *ngIf="headerName=='Add Comment'" icon="pi pi-plus" (click)="addComment(fileInputComment)" label="Add"
            [disabled]="disabled">
        </p-button>
        <!-- <p-button *ngIf="headerName=='Edit Comment'" icon="pi pi-save" (click)="editTicket()" label="Save"
            [disabled]="disabled"></p-button> -->
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- VIEW TICKET -->
<p-sidebar [(visible)]="displayViewModal" position="right" [baseZIndex]="5000" [style]="{overflow:'auto'}">
    <div class="p-p-2">
        <div *ngIf="!isLoading">
            <div class="p-pb-2" style="border-bottom: 1px solid #eee;width:100%;">
                <button pButton pRipple icon="pi pi-comments" label="Comment" class="p-button-raised  p-button-sm"
                    (click)="showHideModalDialog('add_comment')" style="font-size: 12px;margin-left:0.5rem">
                </button>
                <button *ngIf="statusText == 5" pButton pRipple icon="pi pi pi-replay" label="Reopen"
                    class="p-button-raised p-button-success p-button-sm" style="font-size: 12px;margin-left:0.5rem"
                    (click)="confirmDialog('reopen')">
                </button>
                <button *ngIf="statusText == 2" pButton pRipple icon="pi pi-times" (click)="confirmDialog('close')"
                    label="Close Ticket" class="p-button-danger p-button-text"
                    style="font-size: 12px;margin-left:0.5rem">
                </button>
            </div>
            <!-- <div class="p-d-flex p-flex-column p-jc-center p-ai-center p-p-2">
            <div class="img-thumbnail">
                <img [src]="profileUrl" style="width: 100px;height: 100px;" alt="" />
            </div>
            <div class="p-d-flex p-flex-column p-mt-2"
                style="font-weight: bolder;text-align: center;box-sizing: border-box;">
                <div style="font-size: 18px;width: 100px;word-wrap: break-word">
                    Acme INC
                </div>
            </div>
        </div> -->
            <div class="sidebar_container">
                <div class="sidebar_info mb-2">
                    <span style="color: #495057;align-items: center;
                    display: flex;">
                        <span *ngIf="titleText != 'null'">{{titleText}}</span>
                    </span>
                </div>
                <div *ngIf="descText != 'null'" class="sidebar_info mb-2">
                    <span style="color: #919191;align-items: center;
                    display: flex;">{{descText}}</span>
                </div>
            </div>
            <div style="border-bottom: 1px solid #eee;">
                <div *ngIf="attachments?.length != 0" class="sidebar_container">
                    <div class="sidebar_info mb-2">
                        <span style="color: #495057;align-items: center;
                        display: flex;">
                            <span *ngIf="titleText != 'null'">Attachments</span>
                        </span>
                    </div>
                    <div *ngFor="let attachment of attachments;let i = index" class="sidebar_info">
                        <span style="color: #919191;flex:1">
                            <button pButton [label]="'Attachment '+ (i+1)" class="p-button-link"
                                [style]="{padding: '0.2rem'}"
                                (click)="downloadFile(attachment.attachment_url)"></button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="sidebar_container">
                <div class="sidebar_info mb-2">
                    <span style="color: #495057;align-items: center;display: flex;">
                        <span *ngIf="titleText != 'null'">Comments</span>
                    </span>
                </div>
                <div *ngFor="let comment of commentsList" class="mt-2" style="border-bottom: 1px solid #eee;">
                    <div class="sidebar_info">
                        <span style="color: #919191;flex:1">
                            {{comment.body_text}}
                        </span>
                        <div class="p-d-flex p-flex-column p-ai-end">
                            <span style="font-size: 12px;">{{comment.created_at | date:
                                'MM-dd-yyyy h:mm a'}}</span>
                            <span *ngIf="comment.user_id == '9003465061'" style="font-size: 14px;">Sent by System
                                Admin</span>
                        </div>


                    </div>
                    <div *ngFor="let attachment of comment.attachments;let i = index" class="sidebar_info mb-1 p-pb-1">
                        <span style="color: #919191;flex:1">
                            <button pButton [label]="'Attachment '+ (i+1)" class="p-button-link"
                                (click)="downloadFile(attachment.attachment_url)"
                                [style]="{padding: '0.2rem'}"></button>
                        </span>
                    </div>
                </div>

                <p-messages *ngIf="commentsList?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                    <ng-template pTemplate>
                        <i class="pi pi-comments" style="font-size: 2rem"></i>
                        <div class="p-ml-2">NO COMMENTS FOUND</div>
                    </ng-template>
                </p-messages>
            </div>
        </div>
        <div *ngIf="isLoading" class="p-p-2 p-mr-2">
            <div style="padding: 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="padding: 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>
    </div>
</p-sidebar>