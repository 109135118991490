<div class="p-d-flex p-jc-center p-ai-center" style="height: 100vh">
	<div class="card_width">
		<div class="p-d-flex p-jc-center p-ai-center p-mb-4">
			<img src="assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" style="height: 30%;width: 70%;" alt="Chatstasy" />
		</div>
		<p-card>
			<form class="form-login" (ngSubmit)="onSubmit(signInForm)" #signInForm="ngForm">
				<fieldset class="fieldset">
					<legend>
						Sign in to your account
					</legend>
					<p style="font-size: 15px;">
						Please enter your name and password to log in.
					</p>
					<div class="p-fluid">
						<div *ngIf="showBusinessName" class="p-field">
							<span class="p-input-icon-left">
								<i class="pi pi-id-card" style="color: #007AFF;"></i>
								<input type="text" pInputText class="form-control" name="businessname"
									placeholder="Business name" autofocus [(ngModel)]="businessUrl"
									(blur)="getBusinessInfoByShortname()" />
							</span>
						</div>
						<div class="p-field">
							<span class="p-input-icon-left">
								<i class="pi pi-user" style="color: #007AFF;"></i>
								<input type="text" pInputText class="form-control" name="username"
									placeholder="Username" required autofocus ngModel />
							</span>
						</div>
						<div class="p-field">
							<span class="p-input-icon-left">
								<i class="pi pi-lock" style="color: #007AFF;"></i>
								<input pInputText type="password" class="form-control password" name="password"
									placeholder="Password" required ngModel />
								<a class="forgot" href="javascript:void(0)" routerLink="/forgot-password">
									I forgot my password
								</a>
							</span>
						</div>
					</div>
					<!-- <a class="forgot" href="javascript:void(0)" routerLink="/service-gallery/12">
						I forgot my password
					</a> -->
					<div>
						<div *ngIf="error" class="center mb-2">
							<label style="color: red;">
								{{error}}
							</label>
						</div>
						<div class="p-d-flex p-flex-column p-jc-center p-ai-center">
							<p-button type="submit" label="Login" icon="pi pi-arrow-circle-right" iconPos="right"
								(click)="onSubmit(signInForm)" [disabled]="disabled"></p-button>
						</div>
					</div>
					<!-- <div class="new-account center">Don't have an account yet? <a href="javascript:void(0)"
							(click)="goToRegistration()">Create an
							account</a></div> -->
				</fieldset>
				<div *ngIf="isLoading" class="progressLayout">
					<div class="progressContainer">
						<p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
						</p-progressSpinner>
					</div>
				</div>
			</form>
			<div class="copy_right">
				&copy;
				<span class="current-year"></span>
				<span style="font-weight: bold !important;text-transform: uppercase !important;"> Chatstasy</span>.
				<span>All rights reserved</span>
			</div>
		</p-card>
	</div>

</div>

<p-dialog header="Two Factor Authentication" [(visible)]="showTwoFactorAuth" [closable]="false" [modal]="true"
  [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" [baseZIndex]="10000">
  <h4 style="text-align: center;"> Verify your identity to access your account.</h4>
  <p class="p-m-0" style="text-align: center;" *ngIf="userStatus">Please confirm access on your Chatstasy App</p>
  <p class="p-m-0" style="text-align: center;color: red;font-weight: 600;padding-bottom: 10px;" *ngIf="!userStatus">Access declined</p>
  <div style="text-align: center;" *ngIf="!userStatus">
    <button pButton type="button" label="OK" class="p-button-danger" (click)="showTwoFactorAuth = false; userStatus = true"></button>
  </div>
</p-dialog>

<p-toast position="bottom-right" key="br"></p-toast>