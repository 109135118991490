<div [ngClass]="state=='open' ? 'main_content_opens' : 'main_content'">
    <!-- <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc> -->

        <div class="report-container">
          
          <div style="display: flex; justify-content: flex-end;">
            <button
            class="p-button-raised p-button-sm" pButton pRipple
            label="Export" style="font-size: 14px;margin-right: 4px;" 
            (click)="exportTableToXLSX()"></button>
            <button
            class="p-button-raised p-button-sm" pButton pRipple
            label="Print" style="font-size: 14px;margin-right: 4px;" 
            (click)="printPage('printableArea')"></button>
          </div>
             
            <h2 class="report-title">Client Engagement Report</h2>

            <div class="filter-section">
                <div>
                    <label for="clientSegmentClient">Client Segment:</label>
                    <p-dropdown
                        [options]="clientSegments" 
                        [(ngModel)]="selectedClientSegmentClient" 
                        optionLabel="name" 
                        optionValue="id" 
                        (onChange)="onClientSegmentClientChange()">
                    </p-dropdown>
                </div>
                
                <div class="date-range-section" style="position: relative; display: flex; align-items: center;">
                    <label for="reportDurationClient"  style="width: 105px;">Report Duration:</label>
                    <p-dropdown 
                        [options]="reportDurationOptions" 
                        [(ngModel)]="selectedDurationClientEngagement" 
                        optionLabel="name" 
                        optionValue="id" 
                        (onChange)="onReportDurationChange($event)">
                    </p-dropdown>
                    
                    <div class="date-range" [ngClass]="selectedDurationClientEngagement !== 'custom' ?'disabledbutton':'' ">
                        <label for="startDateClient">Start Date:</label>
                        <p-calendar 
                          class="service-category"
                          id="startDateClient" 
                          [(ngModel)]="startDateClientEngagement" 
                          dateFormat="mm/dd/yy"
                          inputId="startDateClient"
                          [showIcon]="true"
                          placeholder="Enter Start Date" 
                          >
                        </p-calendar>
                    
                        <label for="endDateClient">End Date:</label>
                        <p-calendar 
                          class="service-category"
                          id="endDateClient" 
                          [(ngModel)]="endDateClientEngagement" 
                          dateFormat="mm/dd/yy"
                          inputId="endDateClient"
                          [showIcon]="true"
                          placeholder="Enter End Date"
                          >
                        </p-calendar>
                    
                        <button 
                          (click)="fetchClientEngagementData()" 
                          style="margin-left: 20px; cursor: pointer; padding: 5px 10px; background-color: #2196F3;  color: #fff; border: none;">
                          Apply
                        </button>
                      </div>
                </div>
              </div>
            <div id="printableArea">

            <div *ngIf="isReportLoading" class="alignCenter">
              <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
              </p-progressSpinner>
            </div>

          <p-table 
              [value]="filteredClientEngagement" 
              [paginator]="true" 
              [rows]="10" 
              [rowsPerPageOptions]="[5, 10, 20, 50, 100]"
              class="report-table"
              [sortMode]="'single'"
              [sortField]="sortField"
              [sortOrder]="sortOrder">
              
              <ng-template pTemplate="header">
                  <tr>
                      <th class="custom-header" pSortableColumn="clientName">
                          Client Name
                          <p-sortIcon field="clientName"></p-sortIcon>
                      </th>
                      <th class="custom-header" pSortableColumn="servicesUsed">
                          List of Services Used
                          <p-sortIcon field="servicesUsed"></p-sortIcon>
                      </th>
                      <th class="custom-header" pSortableColumn="totalRevenue">
                          Total Revenue
                          <p-sortIcon field="totalRevenue"></p-sortIcon>
                      </th>
                  </tr>
              </ng-template>

              <ng-template pTemplate="body" let-client>
                  <tr>
                      <td>{{ client.clientName }}</td>
                      <td>{{ client.servicesUsed.join(', ') }}</td>
                      <td>{{ client.totalRevenue | currency }}</td>
                  </tr>
              </ng-template>

              <ng-template pTemplate="paginatorLeft" let-state>
                  <span>Total Clients: {{ state.totalRecords }}</span>
              </ng-template>
          </p-table>
          
          </div>
        </div>          
    <!-- </p-scrollPanel> -->
  </div>
  