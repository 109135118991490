<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                How To Guide
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="p-mb-3 toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-6 p-lg-6 ">
                            <div class="p-d-flex p-ml-auto">
                                <span class="p-input-icon-right ">
                                    <i *ngIf="searchInputText !== ''" class="pi pi-times" style="cursor: pointer;"
                                        (click)="resetHowToList()"></i>
                                    <input #searchInput pInputText type="text" [(ngModel)]="searchInputText"
                                        placeholder="Search how to guide"
                                        (keydown)="onEnterSearchHowTo($event,searchInput.value)" />
                                </span>
                                <button pButton pRipple type="button" icon="pi pi-search"
                                    [style]="{width:'2.1rem',height:'2.1rem'}"
                                    class="p-button-rounded p-button-raised p-ml-2"
                                    (click)="listHowTo('1',searchInput.value)"></button>
                            </div>

                        </div>
                        <!-- <div class="p-col p-md-6 p-lg-6 p-jc-end d-none d-lg-flex d-xl-flex">
                                    <button pButton pRipple type="button" label="Add How To"
                                        class="p-button-sm p-button-raised" style="font-size: 12px;" icon="pi pi-plus"
                                        (click)="showHideModalDialog('add_how_to')"></button>
                                </div> -->
                    </div>
                    <!-- <div class="p-grid d-lg-none">
                                <div class="p-col p-d-flex p-jc-center">
                                    <button pButton pRipple type="button" label="Add How To"
                                        class="p-button-sm p-button-raised" style="font-size: 12px;" icon="pi pi-plus"
                                        (click)="showHideModalDialog('add_how_to')"></button>
                                </div>
                            </div> -->

                </div>
                <p-table #dt1 [columns]="cols" [value]="howToList"
                    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                    [responsive]='true' [rowHover]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr style="font-size: small;">
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                            <!-- <th>
                                        Action
                                    </th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-how let-columns="columns">
                        <tr style="font-size: smaller;">
                            <td *ngFor="let col of columns">
                                <span class="p-column-title">{{col.header}}</span>
                                <p-button *ngIf="col.header == 'Question';else otherDetails" [label]="how[col.field]"
                                    styleClass="p-button-link p-button-sm" (click)="getHowTo('view_how_to',how)">
                                </p-button>
                                <ng-template #otherDetails>
                                    {{how[col.field]}}
                                </ng-template>
                            </td>
                            <!-- <td class="last_border">
                                        <span class="p-column-title">Action</span>
                                        <a href="javascript:void(0);" data-toggle="modal" (click)="getHowTo('edit_how_to',how)">
                                            <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                                        </a>
                                        &nbsp;
                                        <a href="javascript:void(0);" data-toggle="modal"
                                            (click)="confirmDeleteDialog(how)">
                                            <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                                        </a>
                                    </td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                <div *ngIf="isLoading" class="alignCenter">
                                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                                    </p-progressSpinner>
                                </div>
                                <p-messages *ngIf="howToList?.length == 0 && !isLoading" key="errMsg"
                                    class="alignCenter">
                                    <ng-template pTemplate>
                                        <i class="pi pi-copy" style="font-size: 2rem"></i>
                                        <div class="p-ml-2">NO DATA FOUND</div>
                                    </ng-template>
                                </p-messages>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)">
                </p-paginator>
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- REMOVE HOW TO MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>


<!-- ADD/EDIT HOW TO -->
<p-dialog #editDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,editDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <div style="min-width: 60vw;max-width: 70vw;">
        <div class="row">
            <div class="form-group col-md-12">
                <label>Question<span class="symbol required"></span></label>
                <input type="text" class="form-control border-black" name="questionText" placeholder="Enter Question"
                    [(ngModel)]="questionText" required />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Answer<span class="symbol required"></span></label>
                <!-- <p-editor [(ngModel)]="answerText" [modules]="modules" [style]="{'min-height':'240px'}"></p-editor> -->
                <quill-editor [(ngModel)]="answerText" class="editor-container" [modules]="modules">
                </quill-editor>
            </div>
        </div>


    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button *ngIf="headerName=='Add How To'" icon="pi pi-plus" (click)="addHowTo()" label="Add"
            [disabled]="disabled">
        </p-button>
        <p-button *ngIf="headerName=='Edit How To'" icon="pi pi-save" (click)="editHowTo()" label="Save"
            [disabled]="disabled"></p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- VIEW APPOINTMENT -->
<p-sidebar [(visible)]="displayViewModal" position="right" [baseZIndex]="5000" [style]="{overflow:'auto'}">
    <div class="p-pt-3">
        <div class="p-mt-2" *ngIf="!isLoading">
            <div style="padding: 12px 15px;">
                <h4><strong>{{questionText}}</strong></h4>
            </div>
            <div class="ql-container ql-snow" style="border-width: 0;">
                <div class="ql-editor" [innerHTML]="answerText">
                </div>
            </div>
        </div>

        <div *ngIf="isLoading" class="p-p-2 p-mr-2">
            <div style="padding: 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div style="margin: 15px 10px 10px 10px;">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>
    </div>

</p-sidebar>