import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppSettings } from '../Constant/AppSettings';
import { Room } from '../models/room.model';
import { ClientDataService } from '../services/client-data/client-data.service';
import { ClientcommunicationService } from '../services/clientcommunication/clientcommunication.service';
import { UtilsService } from '../services/utils/utils.service';
import firebase from 'firebase';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { Router } from '@angular/router';
import { PrivilegeService } from '../services/privilege/privilege.service';

@Component({
  selector: 'app-clientcommunication',
  templateUrl: './clientcommunication.component.html',
  styleUrls: ['./clientcommunication.component.css']
})
export class ClientcommunicationComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Client Communication' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  seedValue: string = '';
  clients: any[] = [];
  user_id: any;
  bussinesInfo: any = {};
  searchText = '';
  isLoading: boolean = false;
  visibleBtn: boolean = true;
  // Dialog display varaible
  displayAddModal: boolean = false;
  disabled: boolean = false;
  public innerWidth: any;
  colorCode: any = CommonFunctions.getUser().chatWindowColorCode;
  @Output() passFirebaseValue: any = new EventEmitter();

  constructor(
    public utils: UtilsService,
    private clientcommunicationService: ClientcommunicationService,
    private clientDataService: ClientDataService,
    private privilegeService: PrivilegeService,
    private router: Router,
    private messageService: MessageService,
    private utilsService: UtilsService
  ) {
    clientcommunicationService.setDbRef('client');
  }

  ngOnInit(): void {
    this.user_id = CommonFunctions.getUserId();
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    this.listClient()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  listClient() {
    this.showProgressBar();
    let type = null;
    if(this.bussinesInfo.state == "offline"){
      type = "test";
    }
    this.clientDataService.getAllSubscribedClientList(type).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clients = json.data;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  createNewChat(room: Room) {
    this.showProgressBar()
    this.clientcommunicationService.checkRoomExist(room.recepient_id?.toString()).snapshotChanges().pipe(take(1))
      .pipe(
        map((changes: any) =>
          changes.map((c: any) =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() })
          )
        )
      ).subscribe((response: any) => {
        console.log(response);
        if (response.length > 0) {
          // alert('Sorry, room already present');
          console.log("Sorry, room already present");
          if (response[0] != null) {
            this.setloadFirebaseMessage(response[0]?.id);
            this.router.navigate(['client-communication/room/' + response[0]?.id]);
          }
        } else {
          console.log("Does not exist.");
          console.log("Room Added.")
          this.clientcommunicationService.createRoom(room).then((response: any) => {
            console.log(response);
            if (response != null) {
              this.setloadFirebaseMessage(response?.id);
              this.router.navigate(['client-communication/room/' + response?.id]);
            }
          });
        }
        this.showHideModalDialog('add_chat')
        this.hideProgressBar()
      });

  }


  addChatRoom(client: any) {
    let room: Room = {}
    // room.business_id = CommonFunctions.getUserId().toString()  //company id
    room.business_id = CommonFunctions.getBusinessInfo().businessId;  //company id
    room.business_logo = "";
    // room.business_name = "Test ACME Industries";
    room.business_name = CommonFunctions.getBusinessInfo().name;

    room.recepient_id = client.id.toString();
    room.recepient_name = client.firstName + ' ' + client.lastName;
    room.recepient_image = "";
    room.last_message = "";
    room.preference_type = client.userType;
    room.timestamp = firebase.firestore.FieldValue.serverTimestamp()
    this.createNewChat(room)
  }


  onChangeTab(event: any) {
    console.log(event)
    if (event.index == 1) {
      this.visibleBtn = false;
    } else {
      this.visibleBtn = true;
    }
  }


  clearFormData() {
    // this.messageService.clear('errMsg');

  }

  showProgressBar() {
    this.isLoading = true;
  }

  hideProgressBar() {
    this.isLoading = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }
  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'add_chat':
        this.clearFormData();
        this.displayAddModal = !this.displayAddModal;
        break;
    }
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  // seedData(ev: string): void {
  //   console.log(ev)
  //   this.seedValue = ev;
  // }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  get state(): string {
    return this.utils.state;
  }

  setloadFirebaseMessage(isVisible: string) {
    this.utilsService.setloadFirebaseMessage(isVisible);
  }
}
