<div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
        <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
</div>
<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Chat Button Promotion
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>

        <div class="p-mb-5">
            <p-card>
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-4 nopadding" [ngClass]="disabledPublish ? 'disabled' : ''">
                            <fieldset style="padding: 10px !important;margin: 0;">
                                <legend>Chat Button Promotion</legend>
                                <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
                                <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;" data-provides="fileinput">
                                    <div class="user-image mt-12" style="display: flex; align-items: baseline;">
                                        <span style="color: #858585;">Chat Button Image &nbsp; &nbsp; </span>
                                        <div class="fileinput-new img-thumbnail">
                                            <img style="width: 100px;height: 100px;object-fit: contain;"
                                                [src]="chatButtonImageBase64" alt="" (error)="chatButtonFileFailedToLoad($event)" />
                                        </div>
                                        <div class=" user-image-buttons" style="right: 90px !important;">
                                            <span class="btn btn-azure btn-file btn-sm"
                                                (click)="chatButtonCropModel()">
                                                <span class="fileinput-new">
                                                    <i class="fa fa-pencil"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">                                
                                    <label class="col-sm-5  control-label nopadding">Client Segments</label>
                                    <div class="col-sm-7 nopadding">
                                        <p-multiSelect [options]="clientSegmentationList" [ngModelOptions]="{standalone: true}" [(ngModel)]="clientSegments" optionLabel="name" defaultLabel="Select Segments" [autoZIndex]="true" name="clientSegments" [style]="{ width: '99%'}" [showHeader]="false"></p-multiSelect>
                                    </div>                                
                                </div>

                                <div class="row form-group">                                
                                    <label class="col-sm-5  control-label nopadding">Image Action</label>
                                    <div class="col-sm-7 nopadding">
                                        <div class="col-sm-12" style=" padding-top: 4px; padding-bottom: 4px; padding-left: 0px;">
                                            <p-radioButton name="imageAction" value="No Action" label="No Action" [(ngModel)]="imageActionType"></p-radioButton>
                                        </div>
                                        <div class="col-sm-12" style=" padding-top: 4px; padding-bottom: 4px;  padding-left: 0px;">
                                            <p-radioButton name="imageAction" value="URL" label="URL" [(ngModel)]="imageActionType"></p-radioButton>
                                        </div>
                                        <div class="col-sm-12" style=" padding-top: 4px; padding-bottom: 4px;  padding-left: 0px;">
                                            <p-radioButton name="imageAction" value="Schedule an Appointment" label="Schedule an Appointment" [(ngModel)]="imageActionType"></p-radioButton>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="row form-group" *ngIf="imageActionType == 'URL' ">
                                    <label class="col-sm-5  control-label nopadding">Image Action URL</label>
                                    <div class="col-sm-7 nopadding">
                                        <input type="text" name="imageAction" class="form-control"
                                            placeholder="Enter Image Action URL" [(ngModel)]="imageAction" />
                                    </div>
                                </div>

                                <div class="row form-group" *ngIf="imageActionType == 'Schedule an Appointment' ">
                                    <label class="col-sm-5  control-label nopadding">Services</label>
                                    <div class="col-sm-7 nopadding">
                                    <p-multiSelect name="selectedApplicableServices" [(ngModel)]="selectedApplicableServices"  [autoZIndex]="true"  [options]="applicableServices" optionLabel="name" [style]="{height:'34px'}" [style]="{ width: '99%'}" defaultLabel="Select services">
                                        </p-multiSelect>
                                    </div>
                                </div>

                                <div class="row form-group">                                
                                    <label class="col-sm-5 nopadding control-label" for="promotionDuration">Promotion Duration (Days)</label>
                                    <div class="col-sm-7 nopadding ">
                                        <!-- <p-inputNumber [(ngModel)]="promotionDuration" inputId="minmax" mode="decimal" [min]="1" [max]="28" [inputStyle]="{ width: '99%'}" [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                                        </p-inputNumber> -->
                                        <!-- <p-inputNumber [(ngModel)]="promotionDuration" inputId="minmax" mode="decimal" [min]="1" [max]="28" [showButtons]="true" [inputStyle]="{ width: '99%'}"></p-inputNumber> -->

                                        <input [style]="{ width: '55px' }" name= "promotionDuration" type="number" [(ngModel)]="promotionDuration" class="form-control" min="1" max="365" (ngModelChange)="onSizeChange()" maxlength="3" onKeyPress="if(this.value.length==3) return false;"/>
                                    </div>                               
                                </div>

                                <div class="fileinput fileinput-new text-center" style="margin-bottom: 1rem;" data-provides="fileinput">
                                    <div class="user-image mt-12" style="display: flex; align-items: baseline;">
                                        <span style="color: #858585;">Promotion Graphic &nbsp; &nbsp; </span>
                                        <div class="fileinput-new img-thumbnail">
                                            <img style="width: 100px;height: 100px;object-fit: contain;"
                                                [src]="promotionGraphicImageBase64" alt="" (error)="promotionGraphicFileFailedToLoad($event)" />
                                        </div>
                                        <div class=" user-image-buttons" style="right: 90px !important;">
                                            <span class="btn btn-azure btn-file btn-sm"
                                                (click)="promotionGraphicCropModel()">
                                                <span class="fileinput-new">
                                                    <i class="fa fa-pencil"></i>
                                                </span>
                                            </span>
                                            <a *ngIf="!graphicImageInputError" href="javascript:void(0)"
                                                (click)="confirmDeleteDialog()"
                                                class="btn fileinput-exists btn-red btn-sm">
                                                <i class="fa fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group center mt-2" style="padding-top: 40px;">
                                    <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }" strokeWidth="3"> </p-progressSpinner>
                                    
                                    <button pButton pRipple type="button" label="Reset"
                                        class="p-button-text p-button-plain p-ml-2 p-mr-4"
                                        (click)="resetChatButtonPromotion()" [disabled]="disabledPublish"></button>

                                    <button pButton pRipple type="button" class="p-button-raised "
                                        (click)="editChatButtonPromotion()" label="Save" [disabled]="disabledPublish"></button>
                                </div>

                            </fieldset>
                            
                        </div>
                        <div class="col-md-8">
                            <fieldset style="padding: 10px !important;margin: 0;">
                                <legend>Preview</legend>
                                <div class="col-md-6 nopadding">
                                    <app-phone-preview [messageType]="'NEW_APPOINTMENT'" [headerShow]= headerShow [cropImage] = chatButtonImageBase64 ></app-phone-preview>
                                </div>
    
                                <div class="col-md-6 nopadding">
                                    <app-phone-preview [messageType]="'NEW_APPOINTMENT'" [headerShow]= "'promotionGraphic'" [cropImage] = "promotionGraphicImageBase64" ></app-phone-preview>
                                </div>


                                <div class="p-grid">
                                    <div class="p-col-8 p-offset-2">
                                        <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center">
                                            <p-progressSpinner *ngIf="isLoading" [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                            <button *ngIf="!disabledPublish" pButton pRipple type="button" label="Publish"
                                                class="p-button-raised p-mb-2" (click)="confirmPublishDialog()"
                                                [disabled]="disabledPublish"></button>
        
                                            <small *ngIf="!disabledPublish" class="col-sm-12 center" style="color: #858585;">
                                                Publish your changes to customers in Chatstasy App.
                                            </small>
                                        </div>
                                        <div class="p-col-8 p-offset-2" *ngIf="disabledPublish">                                            
                                                <div class="p-col-12">
                                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                                        <button pButton type="button" label="STOP" class="p-button-danger" style="margin-left: 15px;" (click)="confirmStopPublishDialog()"></button>
                                                        <div style="display: flex; align-items: center; margin-top: 8px;">
                                                            <i class="pi pi-check-circle" style="font-size: 1.5rem; font-weight: 900; color: #3bb309;"></i>
                                                            <p style="margin-left: 5px;">IN PROGRESS</p>
                                                        </div>
                                                    </div>                                                    
                                                </div>                                                                                      
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>   
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>

<!-- REMOVE LOGO MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- RESIZE CROP MODAL -->
<app-image-resize-cropper-dialog  #renew [displayCropModal]="displayCropModal" [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" [aspectRatio] = aspectRatio (hideCropModal)="hideCropModal($event)"
    (croppedImage)="croppedImage($event)" [ratioChanged] = aspectRatio>
</app-image-resize-cropper-dialog>