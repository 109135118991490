<p-dialog #cropDialog header="Crop Image" [(visible)]="displayCropModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,cropDialog)" (onHide)="clearFormData()">
    <div style="min-width: 60vw;">
        <p-messages key="errMsgCropper" [style]="{'font-size': '12px'}"></p-messages>
        <div class="file_upload_container">
            <div class="p-d-flex p-jc-between">
                <div class="p-d-flex">
                    <input #imageInput type="file" accept="image/*" (change)="selectFile($event)" />
                </div>
            </div>
            <div class="p-pt-1">Upload jpeg, jpg, png only</div>
        </div>
        <div class="file_upload_content">
            <image-cropper [style]="{'max-height': '50vh','max-width':'70vw'}" [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [maintainAspectRatio]="maintainAspectRatio"
                format="jpeg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <p-button icon="fa fa-crop" label="Save" (click)="setImageToProfile()" [disabled]="disabled">
        </p-button>
    </ng-template>

</p-dialog>