import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, ConfirmEventType } from 'primeng/api';
import { UtilsService } from '../services/utils/utils.service';
import { Dialog } from 'primeng/dialog';
import { ClientSegmentationService } from '../services/client-segmentation/client-segmentation.service';
import { AppSettings } from '../Constant/AppSettings';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-client-segmentation',
  templateUrl: './client-segmentation.component.html',
  styleUrls: ['./client-segmentation.component.css']
})
export class ClientSegmentationComponent implements OnInit {
  
  public innerWidth: any;
  items: MenuItem[] = [{ label: 'Client Segmentation' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  headerName: string = '';
  displayAddEditModal: boolean = false;
  name: String = '';
  description = '';
  isLoading: boolean = false;
  disabled: boolean = false;
  clientSegmentationList: any[] = [];
  cols: any[] = [];
  id: any = '';
  bussinesInfo: any = {};

  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private clientsegmentationservice: ClientSegmentationService,
    private confirmationService: ConfirmationService
  ) { }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Name' },
      { field: 'description', header: 'Description' },
    ]
    this.listServiceType();
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
  }

  listServiceType() {
    this.showProgressBar();
    this.clientsegmentationservice.getClientSegmentationList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client Segmentation Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clientSegmentationList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_clientsegmentation':
        this.headerName = 'Edit Client Segmentation';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_clientsegmentation':
        this.clearFormData();
        this.headerName = 'Add Client Segmentation';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
    }
  }

  addAndUpdateClientSegmentation(type:any){
    // this.showProgressBar();
    this.messageService.clear('errMsg');
    if (this.ValidateClientSegmentation() == 'null') {
      return;
    }
    console.log(this.ValidateClientSegmentation());
    const serviceData = {
      name: this.name,
      description: this.description
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    console.log('Client Segmentation Form Data --->> ' + JSON.stringify(data));

    if(type == "add"){
      this.clientsegmentationservice.addClient(data).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Add Client Segmentation Post Response --->> ' + JSON.stringify(json));
          console.log('Status --->> ' + json.response.status);
  
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.displayMessage)
            // segmentationForm.reset();
            this.displayAddEditModal = !this.displayAddEditModal;
            this.hideProgressBar();
            this.listServiceType();
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
      });
    }

    if(type == "update"){
      this.clientsegmentationservice.UpdateClientSegmentation(data, this.id).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Update Client Segmentation Post Response --->> ' + JSON.stringify(json));
          console.log('Status --->> ' + json.response.status);
  
          if (json.response.status == 'SUCCESS') {
            this.showSuccessMessage(json.response.status, json.response.displayMessage)
            // segmentationForm.reset();
            this.displayAddEditModal = !this.displayAddEditModal;
            this.hideProgressBar();
            this.listServiceType();
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
      });
    }

  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  ValidateClientSegmentation(){

    if (this.name == '' || this.name == undefined) {
      this.showErrorMessage('Name is required.')
      return 'null';
    }
    if (this.description == '' || this.description == undefined) {
      this.showErrorMessage('Description is required.')
      return 'null';
    }
    if (this.name.length >= 50) {
      this.showErrorMessage('Name Should not be greater than 50.')
      return 'null';
    }
    if (this.description.length >= 200) {
      this.showErrorMessage('Description Should not be greater than 200.')
      return 'null';
    }
    return 'success';
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.name = '';
    this.description = '';
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  removeClientSegmentation(clientSegmentation: any){

    this.clientsegmentationservice.removeClientSegmentation(clientSegmentation.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: json.response.status,
            detail: json.response.message,
          });
          this.listServiceType();
        } else {
          this.messageService.add({
            key: 'br',
            severity: 'error',
            summary: json.response.status,
            detail: json.response.displayMessage,
          });
        }
        console.log('Remove User Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.messageService.add({
          key: 'br',
          severity: 'error',
          detail: AppSettings.ERROR_MSG,
        });
      }
    );

  }

  confirmDeleteDialog(clientSegmentation: any) {
    console.log('ID --->> ' + clientSegmentation.id);
    // this.id = role.id;
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this client segmentation?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeClientSegmentation(clientSegmentation);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: 'br',
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  getOneClientSegmentation(clientSegmentation:any){
    this.id = clientSegmentation.id;
    this.name = clientSegmentation.name;
    this.description = clientSegmentation.description;

    this.showHideModalDialog('edit_clientsegmentation');
  }

  onToggle(event: any, clientSegmentation: any) {
    this.editEnableDisable(event.checked, clientSegmentation);
  }

  editEnableDisable(value: any, clientSegmentation: any) {
    this.showProgressBar();
    console.log(value, ',', clientSegmentation.id)
    this.clientsegmentationservice.enableDisable(clientSegmentation.id, value).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Enable/Disable Client Segmentation Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  get state(): string {
    return this.utils.state;
  }

}
