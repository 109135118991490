import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { ProfileService } from '../services/profile/profile.service';
import { UserService } from '../services/user/user.service';
import {
  MessageService,
  ConfirmEventType,
  ConfirmationService,
  MenuItem,
} from 'primeng/api';
import { RoleService } from '../services/role/role.service';
import { AppSettings } from '../Constant/AppSettings';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

interface Role {
  name: string;
  id: number;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('p', { static: false }) paginator?: Paginator;

  items: MenuItem[] = [{ label: 'Manage Users' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  isLoading: boolean = false;
  @Input() id: any;
  firstName: string = '';
  lastName: string = '';
  mobile: string = '';
  email: string = '';
  country = null;
  password: string = '';
  confirmPassword = '';
  countries: any[] = [];
  users: User[] = [];
  searchInputText: string = '';
  isValidMobile: boolean = false;

  //Paginator variable
  timer: any;
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;

  // PRIMENG variable
  roles: Role[] = [];
  addSelectedRoles: Role[] = [];
  selectedRoles: Role[] = [];
  cols: any[] = [];
  sortBy: any[] = [];
  selectedSortBy: any;

  // Dialog display varaible
  displayResetPasswordModal: boolean = false;
  displayEditModal: boolean = false;
  displayAddModal: boolean = false;
  disabled: boolean = false;
  public innerWidth: any;
  bussinesInfo: any = {};

  constructor(
    public utils: UtilsService,
    public userService: UserService,
    public roleService: RoleService,
    private router: Router,
    private profileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public privilegeService: PrivilegeService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getCountries();
    this.listUsers(this.currentPage, this.searchInputText);
    this.listRoles();
    this.cols = [
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'mobile', header: 'Mobile' },
      { field: 'email', header: 'Email' },
      { field: 'countryName', header: 'Country' },
      // { field: 'action', header: 'Action' }
    ];
    this.sortBy = [
      { name: 'FirstName Asc', value: 'firstname_asc' },
      { name: 'FirstName Desc', value: 'firstname_desc' },
      { name: 'LastName Asc', value: 'lastname_asc' },
      { name: 'LastName Desc', value: 'lastname_desc' },
    ];
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth)
  }


  addUser(userForm: NgForm) {
    this.showProgressBar();
    if (userForm.value.firstName === '') {
      this.showErrorMessage('First Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.lastName === '') {
      this.showErrorMessage('Last Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.mobile.length <= 9) {
      this.showErrorMessage('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.AddUserEmail === '') {
      this.showErrorMessage('Email Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.country === '') {
      this.showErrorMessage('Country Field is empty.')
      this.hideProgressBar();
      return;
    } else if (this.addSelectedRoles.length === 0) {
      this.showErrorMessage('Please select atleast one role.')
      this.hideProgressBar();
      return;
    } if (userForm.value.addNewPassword.length === 0) {
      this.showErrorMessage('Please enter a new password.')
      this.hideProgressBar();
      return;
    } if (userForm.value.addNewPassword !== userForm.value.addConfirmPassword) {
      this.showErrorMessage('Passwords do not match.')
      this.hideProgressBar();
      return;
    }
    let roleList: any[] = [];
    if (userForm?.value?.editRoles !== null) {
      for (let index = 0; index < userForm.value.roles.length; index++) {
        const element = userForm.value.roles[index];
        roleList.push({ id: element });
      }
    }
    let mob = '+1' + userForm.value.mobile;
    console.log('Mobile Form Data --->> ' + userForm.value.mobile);
    console.log('Role Array --->> ' + JSON.stringify(roleList));
    const userData = new User(
      userForm.value.firstName,
      userForm.value.lastName,
      userForm.value.AddUserEmail,
      mob,
      userForm.value.country,
      userForm.value.addNewPassword,
      roleList
    );
    var data = { data: JSON.parse(JSON.stringify(userData)) };
    console.log('Add User Form Data --->> ' + JSON.stringify(data));
    this.userService.addUser(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add User Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_user');
          this.showSuccessMessage(json.response.status, json.response.message)
          userForm.reset();
          this.listUsers(this.currentPage, this.searchInputText);
          this.addSelectedRoles = [];
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editUser(userForm: NgForm) {
    this.showProgressBar();
    if (userForm.value.firstName === '') {
      this.showErrorMessage('First Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.lastName === '') {
      this.showErrorMessage('Last Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.mobile === '') {
      this.showErrorMessage('Mobile Field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.mobile.length <= 9) {
      this.showErrorMessage('Mobile Number should be 10 digits.')
      this.hideProgressBar();
      return;
    }
    if (userForm.value.email === '') {
      this.showErrorMessage('Email Field is empty.')
      this.hideProgressBar();
      return;
    } if (userForm.value.country === '') {
      this.showErrorMessage('Country Field is empty.')
      this.hideProgressBar();
      return;
    } if (this.selectedRoles.length === 0) {
      this.showErrorMessage('Please select atleast one role.')
      this.hideProgressBar();
      return;
    }

    let editedRoleList: any[] = [];
    if (userForm?.value?.editRoles !== null) {
      for (let index = 0; index < userForm.value.editRoles.length; index++) {
        const element = userForm.value.editRoles[index];
        editedRoleList.push({ id: element });
      }
    }
    let mob = '+1' + userForm.value.mobile;
    const userData = new User(
      userForm.value.firstName,
      userForm.value.lastName,
      userForm.value.email,
      mob,
      userForm.value.country,
      userForm.value.password,
      editedRoleList
    );
    var id = userForm.value.id;
    console.log('Eid --->> ' + id);
    var data = { data: JSON.parse(JSON.stringify(userData)) };
    this.userService.editUser(data, id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit User Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_user');
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listUsers(this.currentPage, this.searchInputText);
          // this.router.navigate(['/user']);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }

        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  reset(resetPassword: NgForm) {
    this.showProgressBar();
    if (resetPassword.value.password.length === 0) {
      this.showErrorMessage('Please enter a new password.')
      this.hideProgressBar();
      return;
    } else if (
      resetPassword.value.confirmPassword !==
      resetPassword.value.confirmPassword
    ) {
      this.showErrorMessage('Passwords do not match.')
      this.hideProgressBar();
      return;
    }
    const pass = resetPassword.value.password;
    const confirmPass = resetPassword.value.confirmPassword;

    const id = resetPassword.value.id;
    var passObj = { password: pass };
    var data = { data: JSON.parse(JSON.stringify(passObj)) };

    this.userService.resetPassword(data, id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Reset Password Response --->> ' + JSON.stringify(json));

        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('reset_password');
          this.showSuccessMessage(json.response.status, json.response.message)
          resetPassword.reset();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listRoles() {
    this.isLoading = true;
    this.roleService.listRoles().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        // console.log('Roles --->> ' + JSON.stringify(json));
        json.data.forEach((c: any) => {
          this.selectedRoles.push(c.id);
        });
        this.roles = json.data;

        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
        this.messageService.add({
          key: 'br',
          severity: 'error',
          detail: AppSettings.ERROR_MSG,
        });
      }
    );
  }


  confirmDeleteDialog(user: any) {
    console.log('ID --->> ' + user.id);
    this.id = user.id;
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this user ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeUser(user);
      },
      reject: (type: any) => {
        switch (type) {
          // case ConfirmEventType.REJECT:
          //   this.messageService.add({ key: 'br', severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
          //   break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: 'br',
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  removeUser(user: any) {
    this.userService.removeUser(user.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listUsers(this.currentPage, this.searchInputText);
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        console.log('Remove User Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  listUsers(pageNumber: any, searchValue: any) {
    console.log('page number:' + pageNumber)
    this.users = [];
    this.isLoading = true;
    let sortData;
    if (this.selectedSortBy == 'firstname_asc') {
      sortData = {
        sortBy: 'firstName',
        sortDirection: 'ASC'
      }
    }
    if (this.selectedSortBy == 'firstname_desc') {
      sortData = {
        sortBy: 'firstName',
        sortDirection: 'DESC'
      }
    }
    if (this.selectedSortBy == 'lastname_asc') {
      sortData = {
        sortBy: 'lastName',
        sortDirection: 'ASC'
      }
    }
    if (this.selectedSortBy == 'lastname_desc') {
      sortData = {
        sortBy: 'lastName',
        sortDirection: 'DESC'
      }
    }
    this.userService.listUsers(pageNumber, searchValue, sortData).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Users --->> ' + JSON.stringify(json));
        this.users = json.data;
        this.pageSize = json.additionalData.pageSize;
        this.totalCount = json.additionalData.totalCount;
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  getCountries() {
    this.profileService.getCountries().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        this.countries = json.data;
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
      }
    );
  }

  getUser(user: any, type: string) {
    this.clearFormData();
    console.log('ID --->> ' + user.id);
    this.id = user.id;
    this.showHideModalDialog(type);
    if (type === 'edit_user') {
      this.showProgressBar();
      this.userService.getUser(user.id).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get User Response --->> ' + JSON.stringify(json));
          this.firstName = json.data.firstName;
          this.lastName = json.data.lastName;
          this.country = json.data.country;
          // var mob = (json.data.mobile)
          this.mobile = json.data.mobile?.replace('+1', '');
          console.log(this.mobile);
          this.email = json.data.email;
          this.id = json.data.id;

          let uniqueChars: any[] = [];
          json.data.roles.forEach((c: any) => {
            if (!uniqueChars.includes(c.id)) {
              uniqueChars.push(c.id);
              this.selectedRoles.push(c.id);
            }
          });
          console.log(uniqueChars);
          // this.selectedRoles = json.data.roles;
          this.hideProgressBar();
        },
        (error) => {
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.hideProgressBar();
        }
      );
    }
  }

  labelByValue(id: any) {
    let i = this.roles.find(item => item.id == id);
    return i ? i.name : "";
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }



  clearFormData() {
    this.messageService.clear('errMsg');
    this.firstName = '';
    this.lastName = '';
    this.mobile = '';
    this.email = '';
    this.country = null;
    this.addSelectedRoles = [];
    this.selectedRoles = [];
    this.password = '';
    this.confirmPassword = '';
  }

  resetUserList() {
    this.searchInputText = '';
    this.currentPage = 1;
    this.paginator?.changePage(0);
    this.listUsers(this.currentPage, this.searchInputText);
  }

  onEnterSearchUser(event: any, searchValue: string) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.currentPage = 1;
      this.paginator?.changePage(0);
      this.listUsers(this.currentPage, searchValue)
    }, 900);
    // if (event.keyCode === 13) {
    //   console.log("pressed enter")
    // } else {
    //   console.log("else typed")
    // }
  }

  onFilterValueChange(event: any) {
    this.listUsers(this.currentPage, this.searchInputText);
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_user':
        this.displayEditModal = !this.displayEditModal;
        break;
      case 'reset_password':
        this.displayResetPasswordModal = !this.displayResetPasswordModal;
        break;
      case 'add_user':
        this.clearFormData();
        this.displayAddModal = !this.displayAddModal;
        break;
    }
  }



  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  // inputPasswordValidator(event: any) {
  //   const pattern = /^[a-z]+$/;
  //   if (!pattern.test(event.target.value)) {
  //     event.target.value = event.target.value.replace(/[^a-zA-Z]/g, "");
  //   }
  // }


  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  inputMobileValidator(event: any) {
    const pattern = /^[1-9]\d{1,14}$/;
    if (!pattern.test(event.target.value)) {
    }
    console.log(
      'Mobile Validator Event --->> ' + pattern.test(event.target.value)
    );

    this.isValidMobile = pattern.test(event.target.value);
  }

  paginate(event: any) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    console.log(event)
    this.listUsers(event.page + 1, this.searchInputText)
  }
  get state(): string {
    return this.utils.state;
  }
}
