<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Subscription & Payments
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card class="p-mb-2">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="cols" [value]="subscriptionList"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-subs let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        <p-button *ngIf="col.header == 'Subscription';else otherDetails"
                                            [label]="subs[col.field]" styleClass="p-button-link p-button-sm"
                                            (click)="showHideModalDialog('view_subscription',subs['coreSubscriptionId'])">
                                        </p-button>
                                        <ng-template #otherDetails>
                                            {{subs[col.field]}}
                                        </ng-template>
                                    </td>
                                    <td class="last_border" style="width: 190px;">
                                        <span class="p-column-title">Action</span>
                                        <select (change)="openInvoice($event, subs)" class="form-control">
                                            <option>Please select action</option>
                                            <option *ngFor="let list of getMenuItemsForItem(subs)" [ngValue]="list">{{list.label}}</option>
                                        </select>
                                        <!-- <button type="button" pButton label="Please select action" iconPos="right"
                                            icon="pi pi-angle-down" [style]="{'font-size': '12px'}"
                                            class="p-button-outlined p-button-secondary p-button-sm"
                                            (click)="menu.toggle($event)"></button>
                                        <p-menu #menu [popup]="true" [model]="getMenuItemsForItem(subs)"
                                            appendTo="body"></p-menu> -->
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+1">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg" *ngIf="subscriptionList?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator #p [rows]="pageSize" [totalRecords]="totalCount"
                            (onPageChange)="paginate($event,'subscription')">
                        </p-paginator>
                    </div>
                </div>
            </p-card>
            <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
                <div class="page_title">
                    Message Credit
                </div>
            </div>
            <p-card>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <p-table [columns]="colsMsgCredit" [value]="subscriptionMsgCreditList"
                            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
                            [responsive]='true' [rowHover]="true">

                            <ng-template pTemplate="header" let-columns>
                                <tr style="font-size: small;">
                                    <th *ngFor="let col of columns">
                                        {{col.header}}
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-subs let-columns="columns">
                                <tr style="font-size: smaller;">
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.header}}</span>
                                        <p-button *ngIf="col.header == 'Subscription';else otherDetails"
                                            [label]="subs[col.field]" styleClass="p-button-link p-button-sm"
                                            (click)="showHideModalDialog('view_subscription',subs['coreSubscriptionId'])">
                                        </p-button>
                                        <ng-template #otherDetails>
                                            {{subs[col.field]}}
                                        </ng-template>
                                    </td>
                                    <td class="last_border" style="width: 190px;">
                                        <span class="p-column-title">Action</span>
                                        <select (change)="openInvoice($event, subs)" class="form-control">
                                            <option>Please select action</option>
                                            <option *ngFor="let list of getMenuItemsForItem(subs)" [ngValue]="list">{{list.label}}</option>
                                        </select>
                                        <!-- <button type="button" pButton label="Please select action" iconPos="right"
                                            icon="pi pi-angle-down" [style]="{'font-size': '12px'}"
                                            class="p-button-outlined p-button-secondary p-button-sm"
                                            (click)="menu.toggle($event)"></button>
                                        <p-menu #menu [popup]="true" [model]="getMenuItemsForItem(subs)"
                                            appendTo="body"></p-menu> -->
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length+1">
                                        <div *ngIf="isLoading" class="alignCenter">
                                            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"
                                                strokeWidth="3">
                                            </p-progressSpinner>
                                        </div>
                                        <p-messages key="errMsg"
                                            *ngIf="subscriptionMsgCreditList?.length == 0 && !isLoading"
                                            class="alignCenter">
                                            <ng-template pTemplate>
                                                <i class="pi pi-copy" style="font-size: 2rem"></i>
                                                <div class="p-ml-2">NO DATA FOUND</div>
                                            </ng-template>
                                        </p-messages>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator #p [rows]="pageSizeCredit" [totalRecords]="totalCountCredit"
                            (onPageChange)="paginate($event,'message_credit')">
                        </p-paginator>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>



<!-- VIEW PACKAGE MODAL -->
<p-dialog #viewDialog [(visible)]="displayViewModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,viewDialog)">
    <ng-template pTemplate="header">
        <div class="text-center">
            <h1 class="display-4" style="font-size: 2rem;margin:0.5rem 0 1.5rem 0; height: 30px;"> </h1>
        </div>
    </ng-template>
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <div class="pricing p-p-4">
        <h5 class="card-title text-muted text-uppercase text-center">{{singlePackage?.name}}</h5>
        <h6 class="card-price text-center">$ {{singlePackage?.monthlyPrice}}<span class="period">/month</span></h6>
        <hr>
        <ul class="fa-ul" style="padding-bottom: 70px;">
            <li *ngFor="let feature of singlePackage?.features"><span class="fa-li"><i
                        class="pi pi-check"></i></span><strong>{{feature?.amount}}</strong>
                {{feature?.description}}</li>
        </ul>
    </div>

    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- VIEW INVOICE MODAL -->
<p-dialog #viewInvoiceDialog [(visible)]="displayViewInvoiceModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    [style]="{'width':'70vw'}" (onShow)="showDialogMaximized($event,viewInvoiceDialog)">
    <ng-template pTemplate="header">
        <div class="text-center">
            <h1 class="display-4" style="font-size: 2rem;margin:0.5rem 0 1.5rem 0; height: 30px;"> </h1>
        </div>
    </ng-template>
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>

    <div class="invoice-box" id="invoiceContent" #invoiceContent>
        <table>
            <tr class="top">
                <td colspan="2">
                    <table style="font-weight: bold;">
                        <tr>
                            <td style="vertical-align: middle;">
                                {{businessName}}<br />
                                {{businessAddress}}<br />
                                {{businessAddressTwo}}<br *ngIf="businessAddressTwo != '' "/>
                                {{businessCity}}<br />
                                {{businessStateAndZipcode}}<br />
                            </td>
                            <td class="p-d-flex p-flex-column p-ai-end">
                                <img src="/assets/images/ChatstasyCorpLogoAndSlogan-June2023-1.png" alt="Company logo"
                                    style="width: 100%; max-width: 200px;object-fit: contain;" />
                                P.O. Box 1234<br />
                                San Diego,CA 92123
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- <tr class="information">
                <td>
                    <table>
                        <tr>
                            <td>
                                Invoice No: {{invoiceData.invoiceNo}}<br />
                                Created: {{invoiceData.createdDate | date: 'MMMM dd, yyyy'}}
                            </td>
                            <td>
                                P.O. Box 1234<br />
                                San Diego,CA 92123
                            </td>
                        </tr>
                    </table>
                </td>
            </tr> -->
            <table>
                <tr class="heading">
                    <td>Item</td>
                    <td>Price</td>
                </tr>
                <tr class="item" *ngFor="let invoice of invoiceData.paymentLineItems">
                    <td *ngIf="invoice.subscriptionPackageId != null">Basic Package</td>

                    <td *ngIf="invoice.messageCreditPackageId != null">Messaging Credit ({{invoice.messageCredits}})
                    </td>
                    <td>$ {{twoDigitDecimal(invoice.price)}}</td>
                </tr>
                <tr *ngIf="invoiceData.proratedCharge != null" class="item">
                    <td>Prorated Charge </td>
                    <td>$ {{twoDigitDecimal(invoiceData.proratedCharge)}}</td>
                </tr>
                <tr *ngIf="invoiceData?.tax != null" class="item">
                    <td>Tax (0%)</td>
                    <td>$ {{twoDigitDecimal(invoiceData.tax)}}</td>
                </tr>
                <tr *ngIf="invoiceData?.setupFee != null" class="item">
                    <td>Setup Fee</td>
                    <td>$ {{twoDigitDecimal(invoiceData.setupFee)}}</td>
                </tr>
                <tr *ngIf="invoiceData?.discountAmount != null" class="item">
                    <td>Discount Amount</td>
                    <td>- $ {{twoDigitDecimal(invoiceData.discountAmount)}}</td>
                </tr>
                <tr class="item last">
                    <td></td>
                    <td>Total: $ {{twoDigitDecimal(invoiceData.totalPrice)}}</td>
                </tr>
            </table>
            <table style="margin-top: 30px;">
                <tr class="heading">
                    <td>Payment Details</td>
                    <td></td>
                </tr>
                <tr class="item">
                    <td>Paid On</td>
                    <td>{{invoiceData.createdDate | date: 'MMMM dd, yyyy'}}</td>
                </tr>
                <tr class="item">
                    <td>Payment Amount</td>
                    <td>$ {{twoDigitDecimal(invoiceData.totalPrice)}}</td>
                </tr>
                <tr class="item">
                    <td>Payment Mode</td>
                    <td>{{invoiceData.paymentMode}}</td>
                </tr>
                <tr class="item">
                    <td>Payment Reference</td>
                    <td>{{invoiceData.paymentReference=='null'?'NA':invoiceData.paymentReference}}</td>
                </tr>
            </table>
        </table>
    </div>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<app-renew-modal #renew [displayRenewModal]="displayRenewModal" from="subs&pay"
    (hideModalRenewDialog)="hideModalRenewDialog($event)" (isSuccess)='isSuccess($event)'>
</app-renew-modal>
<!-- TOAST -->
<p-toast position=" bottom-right" key="br">
</p-toast>