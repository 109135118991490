import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  constructor(
    private http: HttpClient
  ) { }

  serviceProviderList() {
    const url = environment.API_URL + "/api/serviceProvider";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  addServiceProvider(serviceProvider: any) {
    const url = environment.API_URL + "/api/serviceProvider/add";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, serviceProvider, httpOptions).pipe(timeout(60000));
  }

  editServiceProvider(service_id: any, serviceProvider: any) {
    const url = environment.API_URL + "/api/serviceProvider/" + service_id + '/update';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, serviceProvider, httpOptions).pipe(timeout(60000));
  }


  getSingleServiceProvider(service_id: any) {
    const url = environment.API_URL + "/api/serviceProvider/" + service_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getServiceProviderByServicetypeList(data: any) {
    const url = environment.API_URL + "/api/serviceProvider/serviceTypes";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  removeServiceProvider(service_id: any) {
    const url = environment.API_URL + "/api/serviceProvider/" + service_id + "/remove";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

}
