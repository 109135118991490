import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {

  selectedReport: string | undefined;
  
  reportOptions = [
    { label: 'Service Utilization Report', value: 'serviceUtilization' },
    { label: 'Client Engagement Report', value: 'clientEngagement' }
  ];

  constructor(public utils: UtilsService){}

  ngOnInit() {
    this.selectedReport = 'serviceUtilization'
  }

  onReportChange(event: any) {
    this.selectedReport = event.value;
    console.log('Selected report:', this.selectedReport, event);
  }

  get state(): string {
    return this.utils.state;
  }

}
