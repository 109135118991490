import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment';
import { SignInData } from '../../model/signInData';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  TOKEN: any;
  constructor(private router: Router, private http: HttpClient) { }


  getBusinessInfoByShortname(shortname: any) {
    let url = environment.API_URL + '/api/anonymous/business?shortname=' + shortname;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  checkDuplicateUser(email: any) {
    let url = environment.API_URL + '/api/anonymous/user/check?email=' + email;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  authenticate(signInData1: any) {
    console.log(signInData1);
    let url = environment.API_URL + '/api/auth/login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString(),
      }),
    };
    return this.http.post(url, signInData1, httpOptions).pipe(timeout(60000));
  }

  logout() {
    let url = environment.API_URL + '/api/me/logout';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
      }),
    };

    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  forgotPassword(email: any, password: any) {
    let params = new HttpParams().set('email', email);
    params = params.set('password', password);
    let url = environment.API_URL + '/api/anonymous/user/forgotPassword';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  isLoggedIn() {
    return !!CommonFunctions.getToken();
  }

  isSubscribed(): boolean {
    return CommonFunctions.isSubscribed() || false;
  }


  getToken() {
    return CommonFunctions.getToken();
  }

  adminAuthenticate(signInData1: any) {
    console.log(signInData1);
    let url = environment.API_URL + '/api/admin-login/login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, signInData1, httpOptions).pipe(timeout(60000));
  }
}
