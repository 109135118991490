import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElapsedService {

  constructor() { }


  getElapsedTime(dateTime: string, timeZone: string): string {
    
    // Parse the dateTime string into a Date object
    const parsedDate = new Date(dateTime);
    // Get the current date and time
    const now = new Date(new Date(this.getCurrentTimeInTimezone(timeZone)));
    // Calculate the difference in milliseconds
    const diffInMs = parsedDate.getTime() - now.getTime();

    // Convert the difference to a readable format
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const seconds = diffInSeconds % 60;

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const minutes = diffInMinutes % 60;

    const diffInHours = Math.floor(diffInMinutes / 60);
    const hours = diffInHours % 24;

    const diffInDays = Math.floor(diffInHours / 24);

    return `${diffInDays},${hours},${minutes},${seconds}`;
  }

  getCurrentTimeInTimezone(timezone: string): string {
    
    const parts = timezone.split(") ");
    const region = parts[1];
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: region.trim(),
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
    return formatter.format(new Date());
  }
}
