import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, FullCalendarComponent, CalendarApi, EventContentArg, EventHoveringArg, DatesSetArg } from '@fullcalendar/angular';
import * as moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ContextMenu } from 'primeng/contextmenu';
import { Dialog } from 'primeng/dialog';
import { Menu } from 'primeng/menu';
import tippy from 'tippy.js';
import { AppSettings } from '../Constant/AppSettings';
import { AppointmentService } from '../services/appointment/appointment.service';
import { ClientDataService } from '../services/client-data/client-data.service';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { ServiceProviderService } from '../services/service-provider/service-provider.service';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { BusinessHolidaysService } from '../services/business-holidays/business-holidays.service';
import { BusinessHoursService } from '../services/business-hours/business-hours.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { promise } from 'protractor';
import { log } from 'console';
import { ImageSnippet } from '../Constant/ImageSnippet';
import { start } from 'repl';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-business-calendar-modified',
  templateUrl: './business-calendar-modified.component.html',
  styleUrls: ['./business-calendar-modified.component.css'],
})
export class BusinessCalendarComponentModified implements OnInit {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('cd') cd!: ConfirmDialog;
  @ViewChild('contextMenus', { static: false }) contextMenus!: ElementRef;
  menuTopLeftPosition = { x: '0', y: '0' }

  @ViewChild('menu', { static: false }) menu!: Menu;
  @ViewChild('contextMenu') contextMenu: any;
  @ViewChild('calendarContainer', { static: false }) calendarContainer?: ElementRef;
  items: MenuItem[] = [{ label: 'Business Calendar' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  menuItems: MenuItem[] = [];
  origin: any = {};
  //form variable
  appointmentId: any = '';
  appointmentNumber: any = '';
  title: string = '';
  description: string = '';
  isCalendarLoading: boolean = false;
  isLoading: boolean = false;
  appointmentStartDateTime: any = '';
  appointmentEndDateTime: any = '';
  serviceProviderList: any[] = [];
  serviceProvider: string = 'null';
  serviceProviderName: string = '';
  serviceTypeList: any[] = [];
  serviceType: string = "null";
  serviceTypeName: string = '';
  clients: any[] = [];
  client: string = 'null';
  singleClientData: any = {};
  singleClientConfirm: boolean = false;
  singlePaymentConfirm: boolean = false;
  availableDates: any = {};
  selectedClientValue: any = null;
  prePayment: boolean = false;
  specialInstruction = '';
  sendNotification: boolean = true;
  disabledSendNotification: boolean = false;
  isReminder: boolean = false;
  //Filter variable
  serviceProviderFilterList: any[] = [];
  selectedServiceProvider: any;
  serviceTypeFilterList: any[] = [];
  selectedServiceType: any;

  selectedSlot: string | null = null;
  selectedClient: any = null;

  //calendar variable
  private month: number = new Date().getMonth() + 1;
  public year = new Date().getFullYear();
  selectInfo?: DateSelectArg;
  clickInfo?: EventClickArg;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  INITIAL_EVENTS: EventInput[] = [];
  SELECTED_EVENTS: EventInput[] = [];
  calendarVisible = true;
  events: EventInput[] = [];
  holidays: any[] = [];
  invalidDates: any[] = [];
  availabledataForAllDay:any = {};
  isAdding: boolean = false;
  isEditing: boolean = false;
  businessHoursAllDay: any[] = [];
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    // timeZone: 'America/New_York',
    customButtons: {
      today: {
        text: 'Today',
        click: this.getEventsByToday.bind(this)
      },
      prev: {
        text: '<',
        click: this.getEventsByMonthBefore.bind(this)
      },
      next: {
        text: '>',
        click: this.getEventsByMonthAfter.bind(this)
      },
      dayGridMonth: {
        text: 'Month',
        click: this.getEventsByMonth.bind(this)
      },
      timeGridWeek: {
        text: 'Week',
        click: this.getEventsByWeek.bind(this)
      },
      timeGridDay: {
        text: 'Day',
        click: this.getEventsByDay.bind(this)
      }
    },
    initialView: 'dayGridMonth',
    allDaySlot: false,
    initialEvents: this.INITIAL_EVENTS,
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    contentHeight: 'auto',
    validRange: {
      start: undefined,
      end: undefined
    },
    views: {
      timeGridDay: {
        slotDuration: '00:05:00', // 5-minute intervals for day view
      },
      timeGridWeek: {
        slotDuration: '00:15:00', // 15-minute intervals for week view
      }},
      

    datesSet: this.handleDatesSet.bind(this),
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventContent: this.renderEventContent.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this),    
    // dayCellDidMount: this.handleEventRenderService.bind(this),

    // viewDidMount: this.handleEventRenderService.bind(this)
  };
  currentEvents: EventApi[] = [];
  //Sidebar variable
  visibleViewAppointment: boolean = false;
  visibleTestAppointment: boolean = false;
  view_disabled: boolean = false;
  // Dialog display varaible
  blockId = '';
  serviceDuration: any = null;
  blockComment: string = '';
  headerName = '';
  isUserNonchatstasy: boolean = false;
  twillioNumber = CommonFunctions.getBusinessInfo().twilioNumber
  slotDisabled: boolean = false
  displayAddEditModal: boolean = false;
  visibleEditAppoinment: boolean = false;
  visibleAllAppointment: boolean = false;
  displayBlockModal: boolean = false;
  disabled: boolean = false;
  checkDisabled: boolean = false
  innerWidth: any;
  bussinesInfo: any = {};
  appointmentList: any[] = [];
  checked: boolean = true;
  availableslots: any[] = [];
  visibleAvailableSlots: boolean = false;
  selectedDate: string = "";
  businessHoursList: any = [];
  timeList: any;
  visibleAddNewAppoinment: boolean = false;
  AvailableSlotsForProvider: any = [];
  searchText: string = '';
  holidayEvents: any[] = [];

  //import variable
  displayImportModal: boolean = false;
  uploadedFiles: any[] = [];
  importDisabled: boolean = true;
  errorDataList: any[] = [];
  @ViewChild('fileInput') fileInput?: ElementRef;
  numErrorRecords: number = 0;
  selectedFile: ImageSnippet | undefined;
  noOfData: number = 0;
  successFileName: string = '';
  errorFileName: string = '';
  currentPage: any = 1;
  searchInputText: string = '';
  selectedSortBy: any;
  selectedClientType: any;
  pageSize: any = 0;
  totalCount: any = 0;

  //service type details
  serviceTypeNameValue: any;
  serviceTypeCategory: any;
  serviceTypeCategoryDetail: any;
  serviceTypeStartDate: any;
  dataLoaded: boolean = false;
  isButtonDisabled: boolean = false;
  showConfirmButton: boolean = false;


  oldServiceType: string = '';
  oldServiceProvider: string = '';
  oldSlot: string = '';
  oldClient: string = '';
  oldAppointmentDate: string = '';

  newServiceType: any;
  newServiceProvider: any;

  showExportOptions: boolean = false;
  showDateRangePicker: boolean = false;
  startDate: string | undefined;
  endDate: string | undefined;

  applicableServices: any;
  newServiceTypeFilterList: { name: string; value: string }[] = [];

  clientDropdown: boolean = false;
  idvalue: any;

  constructor(
    public utils: UtilsService,
    private appointmentService: AppointmentService,
    private clientDataService: ClientDataService,
    private serviceTypeService: ServiceTypeService,
    private serviceProviderService: ServiceProviderService,
    private privilegeService: PrivilegeService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public businessHolidaysService: BusinessHolidaysService,
    public  businessHoursService: BusinessHoursService,
    private datePipe: DatePipe,

  ) {
    let notificationData = this.router.getCurrentNavigation()?.extras.state?.notification;
    if (notificationData != undefined) {
      this.showHideModalDialog('view_appointment');
      this.getAppointment(notificationData)
    }
    this.utils.notificationData.subscribe(
      (notification: any) => {
        if (notification != undefined) {
          this.showHideModalDialog('view_appointment');
          this.getAppointment(notification)
          utils.onClickNotification(undefined)
        }
      }
    )
    console.log('notiData:', this.router.getCurrentNavigation()?.extras.state);
  }

  async ngOnInit(): Promise<void> {
    this.innerWidth = window.innerWidth;
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    console.log(history.state);
    this.getServiceTypeList();
    this.listClient()
    this.getAppointmentFilterOptions();
    if (this.twillioNumber == null) {
      this.sendNotification = false
      this.disabledSendNotification = true
    } else {
      this.sendNotification = true
      this.disabledSendNotification = false
    }
    this.menuItems = [{
      label: 'File',
      items: [
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'Download', icon: 'pi pi-fw pi-download' }
      ]
    },
    {
      label: 'Edit',
      items: [
        { label: 'Add User', icon: 'pi pi-fw pi-user-plus' },
        { label: 'Remove User', icon: 'pi pi-fw pi-user-minus' }
      ]
    }];

    await this.getBusinessHours();
    this.getBusinessHoursFromData();
  }

  ngAfterViewInit() {
    this.listAppointment();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  toggleMenu(command: string) {
    this.contextMenu.nativeElement.style.display = command === "show" ? "block" : "none";
  };

  setPosition(data: any) {
    this.contextMenu.nativeElement.style.left = `${data.left}px`;
    this.contextMenu.nativeElement.style.top = `${data.top}px`;
    this.toggleMenu('show');
  };

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.contextMenu.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.toggleMenu('hide')
    }
  }

  handleEventRenderService(e: any) {
    const dateStr = e?.date?.toISOString().split('T')[0];
    const dayName = moment(dateStr).format('dddd').toUpperCase();
    const businessDay = this.businessHoursList.find((day: { name: any; }) => day.name === dayName);

    if (Object.keys(this.availabledataForAllDay).length === 0) {
        e.el.style.backgroundColor = '';
        e.el.style.color = '';
    }
    if (this.isHoliday(dateStr)) {
        e.el.firstChild.classList.add('fc-non-business');
    }
    if (businessDay && !businessDay.isActive) {
      e.el.style.backgroundColor = ''; 
      e.el.style.color = '';
      return;
    }
    if (Object.keys(this.availabledataForAllDay).length > 0) {
        if (this.AvailableDates(dateStr) && this.isAdding && !this.isHoliday(dateStr) && this.selectedServiceType != null && this.selectedServiceProvider != null) {
          e.el.style.backgroundColor = '#008000';
          e.el.style.color = '#fff';
        }
    }
}

  async getBusinessHoursFromData() {

  await this.getBusinessHours();

  this.businessHoursList.forEach((day: { isActive: any; name: string; startTime: any; endTime: string; hasOffHour: any; selectedOffHour: any[]; }) => {
    if (day.isActive) {
      const dayOfWeek = this.getDayOfWeekIndex(day.name);

      this.businessHoursAllDay.push({
        daysOfWeek: [dayOfWeek],
        startTime: day.startTime,
        endTime: day.endTime !== '01:00' ? day.endTime : '23:59'
      });
    }
  });
  this.updateCalendarOptions();
}

updateCalendarOptions() {
  this.calendarOptions = {
    ...this.calendarOptions,
    businessHours: this.businessHoursAllDay,
  };
}

getDayOfWeekIndex(dayName: string): number {
  return dayName.toUpperCase() === 'SUNDAY' ? 0 :
         dayName.toUpperCase() === 'MONDAY' ? 1 :
         dayName.toUpperCase() === 'TUESDAY' ? 2 :
         dayName.toUpperCase() === 'WEDNESDAY' ? 3 :
         dayName.toUpperCase() === 'THURSDAY' ? 4 :
         dayName.toUpperCase() === 'FRIDAY' ? 5 :
         dayName.toUpperCase() === 'SATURDAY' ? 6 :
         -1;
}


  triggerDayCellDidMountManually() {
    const calendarApi = this.calendarComponent.getApi();
    
    // Get all rendered day cells
    const dayCells = document.querySelectorAll('.fc-daygrid-day, .fc-timegrid-slot');

    dayCells.forEach(dayCell => {
        const dateStr = dayCell.getAttribute('data-date') || dayCell.getAttribute('data-time');
        if (dateStr) {
            const date = new Date(dateStr);
            const fakeArg = { 
                el: dayCell, 
                date: date, 
                dateStr: dateStr, 
                view: calendarApi.view 
            };
            // Manually call the dayCellDidMount method
            this.handleEventRenderService(fakeArg);
        }
    });
}


  // handleContextMenu(ev: any) {
  //   ev.preventDefault();
  //   this.origin = {
  //     left: ev.pageX,
  //     top: ev.pageY
  //   };

  //   this.setPosition(this.origin);
  // }

  handleDateSelect(selectInfo: DateSelectArg) {
    console.log('selectInfo:', selectInfo);
    this.selectedDate = selectInfo.startStr;
    // if (this.isHoliday(this.selectedDate)) {
    //   this.toggleMenu('hide');
    //   return;
    // }
    
    // if daygrid is selected i want to show popup for showning available slots
    // if (selectInfo.view.type == 'dayGridMonth') {
    //   this.availableslots = this.getAvailableSlots(selectInfo, this.INITIAL_EVENTS);
    //   this.visibleAvailableSlots = true;
    //   console.log('availableslots:', this.availableslots);
    // }
    
    this.clearFormData();
    const calendarApi = this.calendarComponent.getApi();
    this.appointmentStartDateTime = new Date(selectInfo.startStr);
    this.appointmentEndDateTime = new Date(selectInfo.endStr);
    const origin = {
      left: selectInfo.jsEvent?.pageX,
      top: selectInfo.jsEvent?.pageY
    };
    var now = new Date();
    // if (this.appointmentStartDateTime < now) {
    //   
    //   calendarApi?.unselect();
    // } else {
      
      if (this.getPrivilege('manage_appointment')) {
        if (this.bussinesInfo.state != 'offline') {          
          if(this.isAdding && this.availabledataForAllDay.hasOwnProperty(selectInfo.startStr)){
            this.setPosition(origin);
          }
          else if(!this.isAdding){
            this.setPosition(origin);
          }
        } else {
          calendarApi?.unselect();
        }
      } else {
        calendarApi?.unselect();
      }
    // }
    // calendarApi?.unselect(); // clear date selection
    if (selectInfo.jsEvent?.button === 2) {
      console.log('setting...', selectInfo)
      // this.currentPasteData = info
    }
  }

  isHoliday(date: string): boolean {
    const inputDate = new Date(date);

    const formattedInputDate = inputDate.toISOString().split('T')[0];

    return this.holidays.some(holiday => {

      const holidayDate = new Date(holiday);
      const formattedHolidayDate = holidayDate.toISOString().split('T')[0];

      return formattedHolidayDate === formattedInputDate;
    });
  }

  AvailableDates(date: string): boolean {
    const inputDate = new Date(date);
    const formattedInputDate = inputDate.toISOString().split('T')[0];
      return this.availabledataForAllDay.hasOwnProperty(formattedInputDate);    
  }
  

  async openDialogFromMenu(type: string) {
    switch (type) {
      case 'add_appointment':
        this.headerName = 'Add Appointment';
        this.displayAddEditModal = !this.displayAddEditModal;
        this.checkConflict(type)
        break;
      case 'add_block_slots':
        this.headerName = 'Add Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        this.checkConflict(type)
        break;
      case 'add_new_appoinment':
        this.headerName = 'Add New Appointment';
        await this.getAppointmentForServiceAndProvider();
        
        this.visibleAddNewAppoinment = true;
        break;
    }
  }


  handleEventClick(clickInfo: EventClickArg) {
    this.clickInfo = clickInfo;
    if(clickInfo.event.backgroundColor == "#ccc"){
      const eventStart = clickInfo.event?.start;
      if (eventStart) {
        const eventDate = eventStart.toISOString().split('T')[0];
        if (this.isHoliday(eventDate)) {
          // alert('This date is disabled.');
          this.toggleMenu('show');
          return;
        }
      }
    }
    if (clickInfo.event.extendedProps.isBlocked) {
      this.showHideModalDialog('view_block_slots');
      this.getBlockSlot(clickInfo.event);
      this.slotDisabled = true;
    } else {
      this.showHideModalDialog('view_appointment');
      this.getAppointment(clickInfo.event)
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }


  getEventsByToday() {
    this.calendarComponent.getApi().today();
    this.listAppointment();
  }

  getEventsByMonth() {
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().changeView('dayGridMonth');
    if(!this.isAdding){
      this.listAppointment();
    }
  }

  getEventsByWeek() {
    this.calendarComponent.getApi().changeView('timeGridWeek');
    if(!this.isAdding){
      this.listAppointment();
    }
  }

  getEventsByDay() {
    this.calendarComponent.getApi().changeView('timeGridDay');
    if(!this.isAdding){
      this.listAppointmentbyDay();
    }
  }


  getEventsByMonthBefore() {
    this.month--;
    if (this.month == -1) {
      this.month = 11;
      this.year--;
    }
    const monthString = this.month > 9 ? this.month.toString() : '0' + this.month.toString();
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().prev();
    if(!this.isAdding){
      this.listAppointment();
    }
    else{
      this.triggerDayCellDidMountManually();
    }
  }
  getEventsByMonthAfter() {
    // if(this.isAdding){
    //   this.isAdding = !this.isAdding;
    // }
    this.month++;
    if (this.month == 12) {
      this.month = 0;
      this.year++;
    }
    const monthString = this.month > 9 ? this.month.toString() : '0' + this.month.toString();
    const cbm: any = {
      year: this.year,
      month: this.month
    };
    this.calendarComponent.getApi().next();
    if(!this.isAdding){
      this.listAppointment();
    }
    else{
      this.triggerDayCellDidMountManually();
    }
  }

  renderEventContent(eventInfo: EventClickArg, createElement: any) {
    var innerHtml;
    
    let color = "#3788d8";
    let textColor = "#fff";
    const currentDate = new Date();
    const scheduledOnFrom = eventInfo.event.start;
    const serviceTypeName = eventInfo.event.title;

    const serviceType = this.serviceTypeList.find(service => service.name === serviceTypeName);

    if (eventInfo.event.extendedProps.clientConfirm) {  
        color = "#009945";
    } else if (serviceType && scheduledOnFrom != null) {
        const isPrePaymentTrue = serviceType.prePayment === "true";
        const isScheduledForToday = scheduledOnFrom.toDateString() === currentDate.toDateString();
        const reminderDays = parseInt(serviceType.reminderDays, 10) || 0; 
    
        const reminderDate = new Date(scheduledOnFrom.getTime() - reminderDays * 24 * 60 * 60 * 1000);
        const remainderSent = currentDate >= reminderDate;

        if (isPrePaymentTrue) {
            if (!eventInfo.event.extendedProps.paymentConfirm) {
                if (isScheduledForToday) {
                    color = "#cc0000";
                } else {
                    color = "#e6e600";
                    textColor = "#000"; 
                }
            } else {
                color = "#3788d8";
            }
        } else {
            if (remainderSent) {
                color = "#e066ff";
                textColor = "#000"; 
            } else {    
                color = "#3788d8";
            }
        }
    } else {
        color = "#009945"; 
    }   

    if (eventInfo.view.type == 'dayGridMonth') {
      if (eventInfo.event.extendedProps.isBlocked) {
        if(eventInfo.event.backgroundColor == "#ccc"){
          innerHtml =
          '<div class="fc-daygrid-event-dot" style="border:4px solid ' + eventInfo.event.backgroundColor + ';"></div>' +
          '<div class="fc-event-title">' + eventInfo.event.title + '</div>'
        }else{
          innerHtml =
            '<div class="fc-daygrid-event-dot" style="border:4px solid ' + eventInfo.event.backgroundColor + ';"></div>' +
            '<div class="fc-event-time">' + moment(eventInfo.event.start).format("h:mm a") + '</div>' +
            '<div class="fc-event-title">' + eventInfo.event.title + '</div>'
        }
      } else {
        innerHtml =
          '<div class="fc-daygrid-event-dot" style="border:4px solid ' + color + ';"></div>' +
          '<div class="fc-event-time">' + moment(eventInfo.event.start).format("h:mm a") + '</div>' +
          '<div class="fc-event-title">' + eventInfo.event.extendedProps.clientName + '</div>'
      }
    } 
    else {
      if (eventInfo.event.title != '') {
        if (eventInfo.event.extendedProps.isBlocked) {
          innerHtml =
            '<div class="fc-event-main-frame" style="background: #607D8B;border: 1px solid #607D8B;">' +
            '<div class="fc-event-title-container">' +
            '<div class="fc-event-time" style="font-size: 1em;font-weight: bold;white-space: pre-line;">' +
            '<div>' + eventInfo.event.title + '</div>' +
            '</div>' +
            '<div class="fc-event-title">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + (eventInfo.event.end ? moment(eventInfo.event.end).format("h:mm A") : moment(eventInfo.event.start).add(23, 'hours').add(59, 'minutes').format("h:mm A")) + '</div>' +
            '</div>' +
            '</div>'
        } else {
          innerHtml =
            '<div class="fc-event-main-frame">' +
            '<div class="fc-event-title-container">' +
            '<div class="fc-event-time" style="font-size: 1em;font-weight: bold;white-space: pre-line; color:' + textColor + ';">' +
            eventInfo.event.extendedProps.clientName + '<br>' +
            eventInfo.event.title +
            '<div style="font-weight: normal;"> Provided By ' + eventInfo.event.extendedProps.serviceProvider + '</div>' +
            '</div>' +
            '<div class="fc-event-title" style="color:' + textColor + ';">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</div>' +
            '</div>' +
            '</div>'
        }
      } else {
        innerHtml =
          '<div class="fc-event-title">' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</div>'
      }
    }
    return createElement = { html: innerHtml }
  }

  handleEventMouseEnter(eventInfo: EventHoveringArg) {
    if (!eventInfo.event.extendedProps.isBlocked) {
      tippy(eventInfo.el, {
        allowHTML: true,
        content:
          '<div>' +
          '<strong>' + eventInfo.event.extendedProps.clientName + '</strong> <br>' +
          '<strong>' + eventInfo.event.title + '</strong>' +
          '<header> Provided By ' + eventInfo.event.extendedProps.serviceProvider + '</header>' +
          '<footer>' + moment(eventInfo.event.start).format("h:mm A") + ' - ' + moment(eventInfo.event.end).format("h:mm A") + '</footer>' +
          '</div>',
      });
    }
  }


  addAppointment() {
    this.showProgressBar();
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      this.hideProgressBar();
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      this.hideProgressBar();
      return;
    }
    var isoDateString = new Date(this.appointmentStartDateTime).toISOString();
    console.log(isoDateString);
    const appointmentData = {
      title: "Test 1",
      description: "Apppointment 1",
      scheduledOnFrom: this.toISOStringLocal(this.appointmentStartDateTime),
      scheduledOnTo: this.toISOStringLocal(this.appointmentEndDateTime),
      specialInstructions: this.specialInstruction,
      serviceType: {
        id: this.serviceType
      },
      serviceProvider: {
        id: this.serviceProvider
      },
      clientData: {
        id: this.client
      }
    }
    var data = { data: JSON.parse(JSON.stringify(appointmentData)) };
    this.appointmentService.addAppointment(data, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.visibleAddNewAppoinment = false;
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
          this.clearFormData();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  

  editAppointment() {
    this.showProgressBar();
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      this.hideProgressBar();
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      this.hideProgressBar();
      return;
    }
    const appointmentData = {
      title: "Test 2",
      description: "Apppointment 2",
      scheduledOnFrom: this.toISOStringLocal(this.appointmentStartDateTime),
      scheduledOnTo: this.toISOStringLocal(this.appointmentEndDateTime),
      specialInstructions: this.specialInstruction,
      serviceType: {
        id: this.serviceType
      },
      serviceProvider: {
        id: this.serviceProvider
      },
      clientData: {
        id: this.client
      }
    }
    var data = { data: JSON.parse(JSON.stringify(appointmentData)) };
    console.log('Edit Appointment Form Data --->> ' + JSON.stringify(data));
    this.appointmentService.editAppointment(this.appointmentId, data, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Apoointment Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_appointment');
          if (this.visibleViewAppointment) {
            this.headerName = 'Cancel Appointment';
          }
          this.showSuccessMessage(json.response.status, json.response.message)
          this.getAppointment(json.data)
          this.listAppointment();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
        this.visibleViewAppointment = false;
        this.visibleEditAppoinment = false;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  cancelAppointment() {
    this.showProgressBar()
    this.appointmentService.cancelAppointment(this.appointmentId, this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('view_appointment')
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  listAppointment() {
    return new Promise((resolve) => {
    this.isCalendarLoading = true;
    var beginOfWeek = new Date(this.calendarComponent.getApi().view.currentStart);
    var endOfWeek = new Date(this.calendarComponent.getApi().view.currentEnd);

    this.appointmentService.listAppointment(this.toISOStringLocal(beginOfWeek), this.toISOStringLocal(endOfWeek), this.selectedServiceType, this.selectedServiceProvider).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          
          this.INITIAL_EVENTS = []
          json.data.forEach((element: any) => {

            let color = "#3788d8";
            let textColor = "#fff";
            let currentDate = new Date();
            let scheduledOnFrom = new Date(element.scheduledOnFrom);

            const serviceTypeName = element.serviceType?.name;

            const serviceType = this.serviceTypeList.find(service => service.name === serviceTypeName);

            if (element.clientConfirm) {
              color = "#009945";
            } else if (serviceType && scheduledOnFrom != null) {
              const isPrePaymentTrue = serviceType.prePayment === "true";
              const isScheduledForToday = scheduledOnFrom.toDateString() === currentDate.toDateString();
              const reminderDays = parseInt(serviceType.reminderDays, 10) || 0;
              const reminderDate = new Date(scheduledOnFrom.getTime() - reminderDays * 24 * 60 * 60 * 1000);
              const remainderSent = currentDate >= reminderDate;

              if (isPrePaymentTrue) {
                if (!element.paymentConfirm) {
                    if (isScheduledForToday) {
                        color = "#cc0000";
                    } else {
                        color = "#e6e600";
                        textColor = "#000"; 
                    }
                } else {
                    color = "#3788d8";
                }
              } else {
                  if (remainderSent) {
                      color = "#e066ff";
                      textColor = "#000"; 
                  } else {    
                      color = "#3788d8";
                  }
              }
            } else {
                color = "#009945"; 
            }

            this.INITIAL_EVENTS.push({
              id: element.id,
              clientName: element.clientData?.firstName?.charAt(0) + ' ' + element.clientData?.lastName,
              title: element.serviceType ? element.serviceType?.name : '',
              start: element.scheduledOnFrom,
              end: element.scheduledOnTo,
              serviceProvider: element.serviceProvider ? element.serviceProvider?.name : '',
              isBlocked: false,
              clientConfirm: element.clientConfirm,
              paymentConfirm: element.paymentConfirm,
              backgroundColor: color,
              borderColor: color,
              color: color,
            })
          });
          this.listBlockSlots();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isCalendarLoading = false;
        resolve(true);
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.isCalendarLoading = false;
      }
    );
  });
  }
  
  // exportAppointments() {
  //   this.isCalendarLoading = true;
  
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);

  //   const endOfAllAppointments = new Date(2100, 11, 31);
  
  //   this.appointmentService.listAppointment(
  //     this.toISOStringLocal(today),
  //     this.toISOStringLocal(endOfAllAppointments),
  //     this.selectedServiceType,
  //     this.selectedServiceProvider
  //   ).subscribe(
  //     (response) => {
  //       var json = JSON.parse(JSON.stringify(response));
  //       if (json.response.status == 'SUCCESS') {
  //         const appointments = json.data;
  
  //         const csvHeaders = ['ID', 'Client First Name', 'Client Last Name', 'Mobile', 'Service Name', 'Start Time', 'End Time', 'Service Provider', 'Confirmed'];
          
  //         const csvData = appointments
  //           .filter((appointment: any) => {
  //             if (appointment.scheduledOnFrom) {
  //               const eventStart = new Date(appointment.scheduledOnFrom);
  //               return eventStart >= today;
  //             }
  //             return false;
  //           })
  //           .map((appointment: any) => ({
  //             id: appointment.id,
  //             clientFirstName: appointment.clientData?.firstName,
  //             clientLastName:  appointment.clientData?.lastName,
  //             mobileNo: appointment.clientData?.mobileNo,
  //             title: appointment.serviceType ? appointment.serviceType?.name : '',
  //             start: appointment.scheduledOnFrom ? new Date(appointment.scheduledOnFrom).toLocaleString() : '',
  //             end: appointment.scheduledOnTo ? new Date(appointment.scheduledOnTo).toLocaleString() : '',
  //             serviceProvider: appointment.serviceProvider ? appointment.serviceProvider?.name : '',
  //             confirmed: appointment.clientConfirm ? 'Yes' : 'No',
  //           }));
  
  //         if (csvData.length === 0) {
  //           console.log('No appointments found for the selected period.');
  //           this.showErrorToastMsg('No appointments found from today onwards.');
  //           this.isCalendarLoading = false;
  //           return;
  //         }
  
  //         const csvContent = [
  //           csvHeaders.join(','),
  //           ...csvData.map((row: { id: any; clientFirstName: any; clientLastName:any; mobileNo: any; title: any; start: any; end: any; serviceProvider: any; isBlocked: any; confirmed: any; }) => [
  //             row.id,
  //             `"${row.clientFirstName}"`,
  //             `"${row.clientLastName}"`,
  //             `"${row.mobileNo}"`,
  //             `"${row.title}"`,
  //             `"${row.start}"`,
  //             `"${row.end}"`,
  //             `"${row.serviceProvider}"`,
  //             row.confirmed
  //           ].join(','))
  //         ].join('\n');

  //         const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
  //         const link = document.createElement('a');
  //         // if (link.download !== undefined) {
  //         //   const url = URL.createObjectURL(blob);
  //         //   link.setAttribute('href', url);
  //         //   link.setAttribute('download', `appointments_from_${today.toISOString().split('T')[0]}.csv`);
  //         //   link.style.visibility = 'hidden';
  //         //   document.body.appendChild(link);
  //         //   link.click();
  //         //   document.body.removeChild(link);
  //         // }
  //         if (link.download !== undefined) {
  //           const url = URL.createObjectURL(blob);

  //           const today = new Date();
  //           const year = today.getFullYear();
  //           const month = ('0' + (today.getMonth() + 1)).slice(-2);
  //           const day = ('0' + today.getDate()).slice(-2);
  //           const hours = ('0' + today.getHours()).slice(-2);
  //           const minutes = ('0' + today.getMinutes()).slice(-2);

  //           const fileName = `${CommonFunctions.getBusinessInfo().name}-Service Appointments-${year}${month}${day}-${hours}${minutes}.csv`;

  //           link.setAttribute('href', url);
  //           link.setAttribute('download', fileName);
  //           link.style.visibility = 'hidden';
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //       }
        
  //       } else {
  //         this.showErrorMessage(json.response.displayMessage);
  //       }
  //       this.isCalendarLoading = false;
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.showErrorToastMsg('An error occurred while fetching appointments.');
  //       this.isCalendarLoading = false;
  //     }
  //   );
  // }

  toggleExportOptions() {
    this.showExportOptions = !this.showExportOptions;
    this.showDateRangePicker = false;
    this.startDate = undefined;
    this.endDate = undefined;
  }

  toggleDateRangePicker() {
    this.showDateRangePicker = !this.showDateRangePicker;
  }

  onDateChangeExport(event: Date, dataCheck: string): void {
    const year = event.getFullYear();
    const month = String(event.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(event.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;

    if(dataCheck === "start"){
      this.startDate = formattedDate;
    }else if(dataCheck === "end"){
      this.endDate = formattedDate;
    }    
  }

  onDateRangeSelect() {
    this.showDateRangePicker = false;
    this.showExportOptions = false;
    this.exportAppointments('date_range_appointments');
  }
  
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (
      this.contextMenus &&
      !this.contextMenus.nativeElement.contains(targetElement) &&
      !(targetElement.closest('button')?.textContent === 'Cancel')
    ) {
      this.showExportOptions = true;
    }
  }
  
  
  exportAppointments(type: string) {
    this.isCalendarLoading = true;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const endOfAllAppointments = new Date(2100, 11, 31);
  
    let beginOfWeek: Date = new Date();
    let endOfWeek: Date = new Date();
  
    if (type === 'all_appointments') {
      beginOfWeek = today;
      endOfWeek = endOfAllAppointments;
    } else if (type === 'month_appointments') {
      beginOfWeek = new Date(this.calendarComponent.getApi().view.currentStart);
      endOfWeek = new Date(this.calendarComponent.getApi().view.currentEnd);
    } else if (type === 'future_appointments') {
      beginOfWeek = today;
      endOfWeek = endOfAllAppointments;
    } else if (type === 'date_range_appointments') {
      if (!this.startDate || !this.endDate) {
        alert('Please select a valid date range');
        this.isCalendarLoading = false;
        return;
      }

      let utcStartDate = new Date(this.startDate + 'T00:00:00Z');
      let utcEndDate = new Date(this.endDate + 'T23:59:59Z');

      let pstOffset = -8 * -60;
      let localStartTime = utcStartDate.getTime() + pstOffset * 60000;
      let localEndTime = utcEndDate.getTime() + pstOffset * 60000;

      beginOfWeek = new Date(localStartTime);
      endOfWeek = new Date(localEndTime);
    }

    this.appointmentService.listAppointment(
      this.toISOStringLocal(beginOfWeek),
      this.toISOStringLocal(endOfWeek),
      this.selectedServiceType,
      this.selectedServiceProvider
    ).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status === 'SUCCESS') {
          let appointments = json.data;

          appointments = appointments.sort((a: any, b: any) => {
            return new Date(a.scheduledOnFrom).getTime() - new Date(b.scheduledOnFrom).getTime();
          });

          const xlsxHeaders = [
            'ID', 'Client First Name', 'Client Last Name', 'Mobile #', 'Service Name',
            'Service Date', 'Start Time', 'End Time', 'Service Provider', 'Confirmed'
          ];

          const xlsxData = appointments
            .map((appointment: any) => {
              const startDate = new Date(appointment.scheduledOnFrom);
              const endDate = new Date(appointment.scheduledOnTo);
              return [
                appointment.id? `${appointment.id}` : '',
                appointment.clientData?.firstName || '',
                appointment.clientData?.lastName || '',
                appointment.clientData?.mobileNo || '',
                appointment.serviceType?.name || '',
                startDate.toLocaleDateString(),
                startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
                endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
                appointment.serviceProvider?.name || '',
                appointment.clientConfirm ? 'Yes' : 'No',
                appointment.paymentConfirm ? 'Yes' : 'No'
              ];
            });

          if (xlsxData.length === 0) {
            console.log('No appointments found for the selected period.');
            this.showErrorToastMsg('No appointments found from today onwards.');
            this.isCalendarLoading = false;
            return;
          }

          const worksheetData = [xlsxHeaders, ...xlsxData];
      
          const ws = XLSX.utils.aoa_to_sheet(worksheetData);
      
          ws['!cols'] = [
            { wch: 12 },
            { wch: 18 },
            { wch: 18 },
            { wch: 18 },
            { wch: 33 },
            { wch: 12 },
            { wch: 12 },
            { wch: 12 },
            { wch: 25 },
            { wch: 12 },
          ];

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Service Appointments');
      
          const todaydt = new Date();
          const year = todaydt.getFullYear();
          const month = ('0' + (todaydt.getMonth() + 1)).slice(-2);
          const day = ('0' + todaydt.getDate()).slice(-2);
          const hours = ('0' + todaydt.getHours()).slice(-2);
          const minutes = ('0' + todaydt.getMinutes()).slice(-2);
          const fileName = `${CommonFunctions.getBusinessInfo().name}-Service Appointments-${year}${month}${day}-${hours}${minutes}.xlsx`;
      
          XLSX.writeFile(wb, fileName);

        } else {
          this.showErrorMessage(json.response.displayMessage);
        }
        this.isCalendarLoading = false;
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg('An error occurred while fetching appointments.');
        this.isCalendarLoading = false;
      }
    );
    this.showDateRangePicker = false;
    this.showExportOptions = false;
  }

  listAppointmentbyDay(){
    return new Promise((resolve, reject) => {
      
      this.isCalendarLoading = true;

      let utcDate = new Date(this.selectedDate);
      let targetOffset = -8 * -60;
      let localTime = utcDate.getTime() + targetOffset * 60000;

      var currentDate = new Date(localTime);
      var startDate = new Date(currentDate.setHours(0, 0, 0, 0)); 
      var endDate = new Date(currentDate.setHours(23, 59, 59, 999)); 

      this.appointmentService.listAppointment(this.toISOStringLocal(startDate), this.toISOStringLocal(endDate), this.selectedServiceType, this.selectedServiceProvider).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get Appointment List Response --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            this.SELECTED_EVENTS= []
            json.data.forEach((element: any) => {
              let color = "#3788d8";
              let textColor = "#fff";
              let currentDate = new Date();
              let scheduledOnFrom = new Date(element.scheduledOnFrom);

              const serviceTypeName = element.serviceType?.name;

              const serviceType = this.serviceTypeList.find(service => service.name === serviceTypeName);

              if (element.clientConfirm) {
                color = "#009945";
              } else if (serviceType && scheduledOnFrom != null) {
                const isPrePaymentTrue = serviceType.prePayment === "true";
                const isScheduledForToday = scheduledOnFrom.toDateString() === currentDate.toDateString();
                const reminderDays = parseInt(serviceType.reminderDays, 10) || 0;
                const reminderDate = new Date(scheduledOnFrom.getTime() - reminderDays * 24 * 60 * 60 * 1000);
                const remainderSent = currentDate >= reminderDate;

                if (isPrePaymentTrue) {
                  if (!element.paymentConfirm) {
                      if (isScheduledForToday) {
                          color = "#cc0000";
                      } else {
                          color = "#e6e600";
                          textColor = "#000"; 
                      }
                  } else {
                      color = "#3788d8";
                  }
                } else {
                    if (remainderSent) {
                        color = "#e066ff";
                        textColor = "#000"; 
                    } else {    
                        color = "#3788d8";
                    }
                }
              } else {
                  color = "#009945"; 
              }
              this.SELECTED_EVENTS.push({
                id: element.id,
                clientName: element.clientData?.firstName?.charAt(0) + ' ' + element.clientData?.lastName,
                title: element.serviceType ? element.serviceType?.name : '',
                start: element.scheduledOnFrom,
                end: element.scheduledOnTo,
                serviceProvider: element.serviceProvider ? element.serviceProvider?.name : '',
                isBlocked: false,
                clientConfirm: element.clientConfirm,
                paymentConfirm: element.paymentConfirm,
                backgroundColor: color,
                borderColor:  color,
                color:  color,
              })
            });
            this.listBlockSlots();
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.isCalendarLoading = false;
          resolve(true);
        },
        (error) => {
          console.log(error);
          this.showErrorToastMsg(AppSettings.ERROR_MSG)
          this.isCalendarLoading = false;
        }
      );
    });
  }

  listClient() {
    this.showProgressBar();
    let type = null;
    if(this.bussinesInfo.state == "offline"){
      type = "test";
    }
    this.clientDataService.getAllClientList(type).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.clients = [];
          json.data.forEach((element: any) => {
            this.clients.push({ name: element.firstName + ' ' + element.middleName + ' ' + element.lastName, value: element.id, userType: element.userType, mobileNo: element.mobileNo, firstName: element.firstName , middleName: element.middleName, lastName:  element.lastName})
          });
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  async toggleButton() {
    this.isAdding = !this.isAdding; // Toggle the state
    // this.getEventsByMonth();
    this.calendarComponent.getApi().changeView('dayGridMonth');
    this.calendarComponent.getApi().removeAllEvents();
    
    this.selectedServiceType = this.selectedServiceType || this.serviceTypeFilterList[0]?.name;

    await this.getServiceProviderByServiceTypeList(this.selectedServiceType);

    if (this.isAdding === true) {
        this.showHideModalDialog('add_new_appointment');
    }
    else{
      this.isEditing = false;
      this.clearFormData();
      this.selectedServiceType = null;
      this.selectedServiceProvider = null;
      this.availabledataForAllDay = {};
      this.calendarComponent.getApi().removeAllEvents();
      this.triggerDayCellDidMountManually();
      this.calendarComponent.getApi().addEventSource(this.INITIAL_EVENTS);
      this.listAppointment();
    }
  }

  getAppointmentFilterOptions() {
    this.showProgressBar();
    this.appointmentService.getAppointmentFilterOptions().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeFilterList = json.data.serviceType.options;
          this.serviceProviderFilterList = json.data.serviceProvider.options
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listServiceType() {
    this.showProgressBar();
    this.serviceTypeService.serviceTypeListWithoutPagination().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ServiceType List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          
          this.applicableServices = json.data;

          this.filterServicesByDate();
          
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  filterServicesByDate() {
    const selectedDateObj = new Date(this.selectedDate);
    this.newServiceTypeFilterList = [];

    this.applicableServices.forEach((service: { serviceExpiry: any; serviceCategoryDetails: string; serviceCategory: string; id: { toString: () => any; }; name: any; }) => {

        if (service.serviceExpiry) {
          return;
        }
        
        const serviceCategoryDetails = service.serviceCategoryDetails ? JSON.parse(service.serviceCategoryDetails) : null;
        let isServiceAvailable = false;

        if (service.serviceCategory === "Recurring Daily") {
            isServiceAvailable = true;
        } else if (service.serviceCategory === "Recurring Weekly" && Array.isArray(serviceCategoryDetails)) {
            isServiceAvailable = serviceCategoryDetails.some((detail: { name: string; seat: number }) => {
                return detail.name === this.getDayOfWeek(selectedDateObj);
            });
        } else if (service.serviceCategory === "Recurring Monthly" && Array.isArray(serviceCategoryDetails)) {
            isServiceAvailable = serviceCategoryDetails.some((detail: { name: string; seat: number }) => {
                const detailDate = new Date(detail.name);
                return detailDate.getDate() === selectedDateObj.getDate();
            });
        } else if (service.serviceCategory === "Recurring Bi-Weekly" && Array.isArray(serviceCategoryDetails)) {
            isServiceAvailable = serviceCategoryDetails.some((detail: { name: string; seat: number }) => {
                return detail.name === this.getDayOfWeek(selectedDateObj);
            });
        } else if (service.serviceCategory === "Non-Recurring") {
            const detailDate = new Date(serviceCategoryDetails);
            isServiceAvailable = detailDate.toISOString().split('T')[0] === selectedDateObj.toISOString().split('T')[0];
        }

        if (isServiceAvailable) {
            this.newServiceTypeFilterList.push({ name: service.id.toString(), value: service.name });
        }
      });
  }

  getDayOfWeek(date: Date): string {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[date.getUTCDay()];
  }

  getAppointment(appointment: any) {
    this.showProgressBar();
    
    this.appointmentService.getAppointment(appointment.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Appointment Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.getServiceProviderByServiceTypeList(json.data.serviceType?.id)
          this.appointmentId = json.data.id
          this.appointmentNumber = json.data.appointmentId;
          this.appointmentStartDateTime = new Date(json.data.scheduledOnFrom);
          this.appointmentEndDateTime = new Date(json.data.scheduledOnTo);
          this.serviceType = json.data.serviceType?.id || '';
          this.serviceTypeName = json.data.serviceType?.name || '';
          this.serviceProvider = json.data.serviceProvider?.id || '';
          this.serviceProviderName = json.data.serviceProvider?.name || '';
          this.specialInstruction = json.data.specialInstructions || '';
          this.client = json.data.clientData?.id || '';
          this.singleClientData = json.data.clientData || '';
          this.singleClientConfirm = json.data.clientConfirm == "true" ? true : false;
          this.singlePaymentConfirm = json.data.paymentConfirm == "true" ? true : false;
          this.checkUserType(json.data.clientData?.userType || '')
          
          this.getServiceType(this.serviceType).subscribe(() => {
            this.updateConfirmButtonState();
          });
          
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      }
    );
  }

getServiceType(service: any): Observable<any> {
    return this.serviceTypeService.getSingleServiceType(service).pipe(
      tap((response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ServiceType Response --->> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          this.prePayment = json.data.prePayment == "true" ? true : false;
        } else {
          this.showErrorMessage(json.response.displayMessage);
        }
        this.hideProgressBar();
      }),
      catchError((error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG);
        this.hideProgressBar();
        return of(null);
      })
    );
  }

  updateConfirmButtonState() {

    this.checkAppointmentTime();
  
    if (this.isButtonDisabled) {
      this.showConfirmButton = false;
      return;
    }

    if (!this.prePayment) {
      this.showConfirmButton = false;
      return;
    } else {
      if (this.singlePaymentConfirm) {
        this.showConfirmButton = false;
        return;
      } else {
        this.showConfirmButton = true;
        return;
      }
    }
  }

checkAppointmentTime() {
    var now = new Date();
    if (this.appointmentStartDateTime < now) {
        this.isButtonDisabled = true;
    } else {
        this.isButtonDisabled = false;
    }
}


  checkConflict(type: string) {
    console.log('date:' + this.appointmentEndDateTime)
    this.showProgressBar()
    this.messageService.clear('checkMsg');
    this.checkDisabled = true
    if (this.appointmentStartDateTime == '' || this.appointmentStartDateTime === undefined) {
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime == '' || this.appointmentEndDateTime === undefined) {
      this.hideProgressBar();
      return;
    }
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.hideProgressBar();
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.hideProgressBar();
      return;
    }

    this.appointmentService.checkConflict(this.toISOStringLocal(this.appointmentStartDateTime), this.toISOStringLocal(this.appointmentEndDateTime), this.serviceType, this.serviceProvider).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Conflict Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (type == 'add_appointment') {
            if (json.data.appointments > 0 && json.data.blockSlots > 0) {
              this.showCheckMessage('warn', json.data.appointments + ' Appointments found in the selected slot')
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments > 0) {
              this.showCheckMessage('warn', json.data.appointments + ' Appointments found in the selected slot')
              this.checkDisabled = false;
            } else if (json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments == 0 && json.data.blockSlots == 0) {
              this.checkDisabled = false;
            }
          } else {
            if (json.data.appointments > 0 && json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.appointments + ' Appointments found in the selected slot')
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments > 0) {
              this.showCheckMessage('error', json.data.appointments + ' Appointments found in the selected slot')
              this.confirmCancelDialog('cancel_all')
              this.checkDisabled = true;
            } else if (json.data.blockSlots > 0) {
              this.showCheckMessage('error', json.data.blockSlots + ' Blocked slots found in the selected slot')
              this.checkDisabled = true;
            } else if (json.data.appointments == 0 && json.data.blockSlots == 0) {
              this.checkDisabled = false;
            }
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  addBlockSlot() {
    this.showProgressBar();
    if (this.appointmentStartDateTime == '' || this.appointmentStartDateTime == 'null') {
      this.showErrorMessage('Start date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.appointmentEndDateTime.toString().trim() == '' || this.appointmentEndDateTime == 'null') {
      this.showErrorMessage('End date/time field is empty.')
      this.hideProgressBar();
      return;
    } if (this.blockComment == '' || this.blockComment == 'null') {
      this.showErrorMessage('Comments field is empty.')
      this.hideProgressBar();
      return;
    }
    const blockData = {
      startDate: this.toISOStringLocal(this.appointmentStartDateTime),
      endDate: this.toISOStringLocal(this.appointmentEndDateTime),
      comments: this.blockComment
    }
    var data = { data: JSON.parse(JSON.stringify(blockData)) };
    console.log('Add Block Form Data --->> ' + JSON.stringify(data));
    this.appointmentService.addBlockSlot(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Block Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_block_slots');
          this.showSuccessMessage(json.response.status, json.response.message)
          this.listAppointment();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  cancelAllAppointment() {
    this.showProgressBar();
    this.appointmentService.cancelAllAppointment(this.toISOStringLocal(this.appointmentStartDateTime), this.toISOStringLocal(this.appointmentEndDateTime), this.sendNotification).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Appointment Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.checkConflict('add_block_slots')
          this.addBlockSlot();
        } else {
          this.showErrorMessage(json.response.displayMessage)

          this.hideProgressBar();
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }



  getBlockSlot(slot: any) {
    this.showProgressBar();
    this.blockId = slot.id;
    
    this.appointmentService.getBlockSlot(slot.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Single Block Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.appointmentStartDateTime = new Date(json.data.startDate);
          this.appointmentEndDateTime = new Date(json.data.endDate);
          this.blockComment = json.data.comments;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getAvailableSlots(selectedDate: any, blockerSlots: any[]): string[] {
    const startOfDay = new Date(selectedDate.startStr);
    const endOfDay = new Date(selectedDate.endStr);

    endOfDay.setDate(endOfDay.getDate() + 1);

    const filteredBlockerSlots = blockerSlots.filter(slot => {
        const slotStart = new Date(slot.start);
        const slotEnd = new Date(slot.end);

        return !(slotEnd <= startOfDay || slotStart >= endOfDay);
    });

    const generateAvailableSlots = (start: Date, end: Date): { start: string, end: string }[] => {
        const slots: { start: string, end: string }[] = [];
        let current = new Date(start);

        // Cap the end time at midnight
        const midnight = new Date(current);
        midnight.setHours(24, 0, 0, 0); // Set to 00:00 next day

        while (current < midnight) {
            const nextHour = new Date(current);
            nextHour.setHours(current.getHours() + 1);

            // If the nextHour exceeds midnight, set it to midnight
            if (nextHour > midnight) {
                nextHour.setTime(midnight.getTime());
            }

            const isBlocked = filteredBlockerSlots.some(slot => {
                const slotStart = new Date(slot.start);
                const slotEnd = new Date(slot.end);

                return !(nextHour <= slotStart || current >= slotEnd);
            });

            if (!isBlocked) {
                slots.push({
                    start: current.toISOString(),
                    end: nextHour.toISOString()
                });
            }

            current = nextHour;
        }

        return slots;
    };

    const availableSlots = generateAvailableSlots(startOfDay, endOfDay);
    
    // Format available slots
    return availableSlots.map(slot => this.formatSlot(slot.start, slot.end));
}




  formatSlot(start: string, end: string): string {
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const startDate = new Date(start);
    const endDate = new Date(end);

    const startStr = new Intl.DateTimeFormat('en-US', options).format(startDate);
    const endStr = new Intl.DateTimeFormat('en-US', options).format(endDate);

    return `${startStr} - ${endStr}`;
  }




  unlockSlot() {
    this.showProgressBar();
    this.appointmentService.unblockSlot(this.blockId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Unblock Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('view_block_slots');
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.listAppointment()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listBlockSlots() {
    this.isCalendarLoading = true;
    this.appointmentService.listBlockedSlots().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          json.data.forEach((element: any) => {
            this.INITIAL_EVENTS.push({
              id: element.id,
              title: element.comments,
              start: element.startDate,
              end: element.endDate,
              backgroundColor: '#607D8B',
              borderColor: '#607D8B',
              color: '#607D8B',
              isBlocked: true
            })
          });
          this.calendarComponent!.options!.events = this.INITIAL_EVENTS;
          this.checkHolidaysConflict();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        
        this.isCalendarLoading = false;
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.isCalendarLoading = false;
      }
    );
  }

  sendReminder() {
    this.showProgressBar()
    this.appointmentService.sendReminder(this.appointmentId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send reminder response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.isReminder = false
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  getServiceTypeList() {
    this.showProgressBar();
    this.serviceTypeService.serviceTypeList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeList = []          
          json.data.forEach((element: any) => {
            this.serviceTypeList.push({ name: element.name, value: element.id, serviceDuration: element.serviceDuration, specialInstructions: element.specialInstructions, prePayment: element.prePayment, reminderDays: element.reminderDays })
          });

        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

//  async getServiceProviderByServiceTypeList(value: any) {
//     this.showProgressBar();
//     const serviceData = {
//       id: value
//     }
//     var data = { data: JSON.parse(JSON.stringify(serviceData)) };
//     this.serviceProviderService.getServiceProviderByServicetypeList(data).subscribe(
//       (response) => {
//         var json = JSON.parse(JSON.stringify(response));
//         console.log('Service provider response --> ' + JSON.stringify(json));
//         if (json.response.status == 'SUCCESS') {
//           this.serviceProviderList = []
//           json.data.forEach((element: any) => {
//             this.serviceProviderList.push({ name: element.name, value: element.id })
//           });
//           if(this.isAdding){
//             this.selectedServiceProvider = this.selectedServiceProvider || this.serviceProviderList[0]?.name;
//           }
//           // console.log(this.serviceProviderList);
//         } else {
//           this.showErrorMessage(json.response.displayMessage)
//         }
//         this.hideProgressBar()
//       }, (error) => {
//         console.log(error);
//         this.showErrorMessage(AppSettings.ERROR_MSG)
//         this.hideProgressBar();
//       })  
//   }

async getServiceProviderByServiceTypeList(value: any): Promise<void> {
  this.showProgressBar();
  const serviceData = { id: value };
  const data = { data: JSON.parse(JSON.stringify(serviceData)) };
  
  return new Promise((resolve, reject) => {
    this.serviceProviderService.getServiceProviderByServicetypeList(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Service provider response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceProviderFilterList = [];
          json.data.forEach((element: any) => {
            this.serviceProviderFilterList.push({ value: element.name, name: element.id });
          });
          if (this.isAdding) {
            this.selectedServiceProvider = this.selectedServiceProvider || this.serviceProviderFilterList[0]?.name;
          }
          resolve();
        } else {
          this.showErrorMessage(json.response.displayMessage);
          reject(new Error(json.response.displayMessage));
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG);
        this.hideProgressBar();
        reject(error);
      }
    );
  });
}

  confirmAddEditDialog(type: any) {
    this.messageService.clear('errMsg');
    if (this.serviceType == '' || this.serviceType == 'null') {
      this.showErrorMessage('Service Type field is empty.')
      return;
    } if (this.serviceProvider == '' || this.serviceProvider == 'null') {
      this.showErrorMessage('Service Provider field is empty.')
      return;
    } if (this.client == '' || this.client == 'null') {
      this.showErrorMessage('Client field is empty.')
      return;
    } if (this.appointmentStartDateTime == '') {
      this.showErrorMessage('Appointment start date/time field is empty.')
      return;
    } if (this.appointmentEndDateTime == '') {
      this.showErrorMessage('Appointment end date/time field is empty.')
      return;
    }
    var selectedClient = this.clients.find(x => x.value == this.client).userType;
    this.checkUserType(selectedClient || '')

    const formattedOldDate = this.datePipe.transform(this.oldAppointmentDate, 'MM-dd-yyyy');
    const formattedNewDate = this.datePipe.transform(this.appointmentStartDateTime, 'MM-dd-yyyy');
    
    const newService = this.serviceTypeFilterList.find(item => item.name === `${this.serviceType}`);
    const newProvider = this.serviceProviderFilterList.find(item => item.name === this.serviceProvider);

    this.confirmationService.confirm({
      message: type == 'add_appointment' ? 'Do you want to add this appointment ?' : type == 'confirm_appointment_payment' ? 'Do you want to confirm this appointment ?' :
       `Please confirm your changes<br><br>
            <b>Old Appointment:</b><br>
            Date: ${formattedOldDate}<br>
            Service Name: ${this.oldServiceType}<br>
            Provider: ${this.oldServiceProvider}<br>
            Client: ${this.singleClientData.firstName} ${this.singleClientData.middleName} ${this.singleClientData.lastName}<br>
            Slot: ${this.oldSlot}<br><br>
            
            <b>New Appointment:</b><br>
            Date: ${formattedNewDate}<br>
            Service Name: ${newService ? newService.value : ''}<br>
            Provider: ${newProvider ? newProvider.value : ''}<br>
            Client: ${this.singleClientData.firstName} ${this.singleClientData.middleName} ${this.singleClientData.lastName}<br>
            Slot: ${this.selectedSlot}<br>`,
      header: type == 'add_appointment' ? 'Add Confirmation' : type == 'confirm_appointment_payment' ? 'Appointment Confirmation' : 'Change Confirmation',
      icon: type == 'add_appointment' ? 'pi pi-calendar-plus' : type == 'confirm_appointment_payment' ? 'pi pi-check-circle' : '',
      accept: () => {
        if (type == 'add_appointment') {
          this.addAppointment()
        } else if(type == 'confirm_appointment_payment'){
          this.clientConfirmAppointment()
        } else {
          this.editAppointment()
        }
      }
    });
  }

  confirmCancelDialog(type: string) {
    let messageTxt = '';
    let headerTxt = '';
    let iconValue = '';
    if (type == 'cancel_appointment') {
      headerTxt = 'Cancel Confirmation';
      messageTxt = 'Do you want to cancel this appointment ?';
      iconValue = 'pi pi-calendar-times';
    } else if (type == 'send_reminder') {
      this.isReminder = true;
      headerTxt = 'Send Reminder Confirmation';
      messageTxt = 'Do you want to send reminder ?';
      iconValue = 'pi pi-bell';
    } else {
      headerTxt = 'Cancel All Confirmation';
      messageTxt = 'There are existing appointments in the slot. Do you want to cancel the appointment ?';
      iconValue = 'pi pi-calendar-times';
    }
    this.confirmationService.confirm({
      message: messageTxt,
      header: headerTxt,
      icon: iconValue,
      accept: () => {
        // this.clickInfo?.event.remove();
        if (type == 'cancel_appointment') {
          this.cancelAppointment();
        } else if (type == 'send_reminder') {
          this.sendReminder();
        } else {
          this.cancelAllAppointment();
        }

      }, reject: () => {
        if (type == 'send_reminder') {
          this.isReminder = false;
        }
      }
    });
  }

  formatTime(date: Date): string {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let hoursStr = hours < 10 ? '0' + hours : hours.toString();
    let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    return hoursStr + ':' + minutesStr + ' ' + ampm;
}

  getAppointmentsByClient(){
    
    this.appointmentService.getAppointmentsbyClientId(this.idvalue).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        const currentDate = new Date();
        this.SELECTED_EVENTS= []
            json.data.forEach((element: any) => {
              const appointmentStart = new Date(element.scheduledOnFrom);
              if (appointmentStart > currentDate) {
              this.SELECTED_EVENTS.push({
                id: element.id,
                clientName: element.clientData?.firstName?.charAt(0) + ' ' + element.clientData?.lastName,
                title: element.serviceType ? element.serviceType?.name : '',
                start: element.scheduledOnFrom,
                end: element.scheduledOnTo,
                serviceProvider: element.serviceProvider ? element.serviceProvider?.name : '',
                isBlocked: false,
                clientConfirm: element.clientConfirm,
                paymentConfirm: element.paymentConfirm,
                backgroundColor: element.clientConfirm ? "#009945": "",
                borderColor:  element.clientConfirm ? "#009945": "",
                color:  element.clientConfirm ? "#009945": "",
              })}
            });
            // this.clientDropdown = false;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      });    
  }

  onClientChange(client: any): void {
    this.idvalue = client.value;
    this.getAppointmentsByClient();
  }

  async viewEdit(appointment: any): Promise<void> {
    await this.getAppointment(appointment);

    this.showHideModalDialog('view_appointment');
    this.visibleAllAppointment = false;
  }

  handleDialogClose(): void {
    this.idvalue = null;
    this.searchText = '';
  }

  async showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_appointment_slot':
        this.visibleEditAppoinment = !this.visibleEditAppoinment;

        this.headerName = 'Edit Appointment';

        let utcDate = new Date(this.appointmentStartDateTime);
        let targetOffset = -8 * -60;
        let localTime = utcDate.getTime() + targetOffset * 60000;
        let date = new Date(localTime);

        this.selectedDate = date.getFullYear() + '-' +
                            String(date.getMonth() + 1).padStart(2, '0') + '-' +
                            String(date.getDate()).padStart(2, '0');
        
        await this.getAppointmentForServiceAndProvider();

        break;
      case 'edit_appointment':
        this.headerName = 'Edit Appointment';
        this.isEditing = !this.isEditing;
        
        this.serviceProvider = this.serviceProvider ? this.serviceProvider : this.selectedServiceProvider;
        this.serviceType = this.serviceType ? `${this.serviceType}` : this.selectedServiceType;

        const oldService = this.serviceTypeFilterList.find(item => item.name === `${this.serviceType}`);
        const oldProvider = this.serviceProviderFilterList.find(item => item.name === this.serviceProvider);
        
        this.selectedServiceType = `${this.serviceType}`;
        this.selectedServiceProvider = this.serviceProvider;
        this.selectedClientValue = this.client;

        this.visibleViewAppointment = !this.visibleViewAppointment;

        let utcDate2 = new Date(this.appointmentStartDateTime);
        let targetOffset2 = -8 * -60;
        let localTime2 = utcDate2.getTime() + targetOffset2 * 60000;
        let date2 = new Date(localTime2);

        this.selectedDate = date2.getFullYear() + '-' +
                            String(date2.getMonth() + 1).padStart(2, '0') + '-' +
                            String(date2.getDate()).padStart(2, '0');

        await this.getAppointmentForServiceAndProvider();

        this.newServiceType = this.serviceTypeFilterList.find(item => item.name === `${this.serviceType}`);
        this.newServiceProvider = this.serviceProviderFilterList.find(item => item.name === this.serviceProvider);                      
        
        this.oldServiceType = oldService ? oldService.value : '';
        this.oldServiceProvider = oldProvider ? oldProvider.value : '';
        this.oldSlot = this.selectedSlot ? this.selectedSlot : '';
        this.oldAppointmentDate = this.appointmentStartDateTime;

        await this.getAvailableSlotsSelectedDate();
        this.toggleButton();

        break;
      case 'add_appointment':
        this.clearFormData();
        this.headerName = 'Add Appointment';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_new_appointment':
        this.isAdding = true;
        this.getAvailableSlotsSelectedDate();
      break;
      case 'view_appointment':
        // this.clearFormData();
        this.headerName = 'View Appointment';
        this.visibleViewAppointment = !this.visibleViewAppointment
        break;
      case 'add_block_slots':
        this.clearFormData();
        this.headerName = 'Add Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        break;
      case 'view_block_slots':
        this.headerName = 'View Block Slots';
        this.displayBlockModal = !this.displayBlockModal
        break;
      case 'send_reminder':
        this.headerName = 'View Appointment';
        this.confirmCancelDialog(type)
        break;
      case 'cancel_appointment':
        this.headerName = 'View Appointment';
        this.confirmCancelDialog(type)
        break;
      case 'remove_test_appointment':
        await this.getTestAppointment();
        this.headerName = 'Test Appointment';
        this.visibleTestAppointment = !this.visibleTestAppointment
        break;
      case 'view_all_appoinment':
        await this.listAppointment();
        this.headerName = 'All Appointments';
        this.visibleAllAppointment = !this.visibleAllAppointment
        console.log('view_all_appoinment', this.visibleAllAppointment)
        break;
      case 'view_all_client_appoinment':
        this.getAppointmentsByClient();
        this.headerName = 'Client Appointments';
        this.visibleAllAppointment = !this.visibleAllAppointment
        break;
      case 'view_all_appoinment_day':
          this.SELECTED_EVENTS = [];
          await this.listAppointmentbyDay();
          this.headerName = 'Appointments';
          this.visibleAllAppointment = !this.visibleAllAppointment
          break;
      case 'import_client':
          this.headerName = 'Upload Clients';
          this.displayImportModal = !this.displayImportModal;
          break;

    }
  }

async getAvailableSlotsSelectedDate() {
  try {

    // this.availabledataForAllDay = {
    //   '2024-09-15': [],
    //   '2024-09-14': [],
    // };

    const response: any = await this.appointmentService.getServiceTypeDetails(this.selectedServiceType);
    
    var json = JSON.parse(JSON.stringify(response));
    console.log('Service type new Response --->> ' + JSON.stringify(json));

    if (json.response.status == 'SUCCESS') {
      this.serviceTypeNameValue = json.data.name;
      this.serviceTypeCategory = json.data.serviceCategory;
      this.serviceTypeCategoryDetail = json.data.serviceCategoryDetails;
      this.serviceTypeStartDate = json.data.serviceStartDate;

      this.populateDates(
        this.serviceTypeNameValue,
        this.serviceTypeCategory,
        JSON.parse(this.serviceTypeCategoryDetail),
        this.serviceTypeStartDate,
      );

      // Trigger re-render
      this.triggerDayCellDidMountManually();
      console.log('holiday set', this.holidayEvents);
      this.calendarComponent.getApi().addEventSource(this.holidayEvents);

      this.serviceType = this.selectedServiceType || this.serviceTypeFilterList[0]?.name;
      console.log('selectedServiceType:', this.serviceTypeFilterList);
      console.log('serviceProviderFilterList:', this.serviceProviderFilterList);

      // const anyProvider = this.serviceProviderList.find(obj => obj.value === 'Any Provider');
      this.serviceProvider = this.selectedServiceProvider || this.serviceProviderFilterList[0]?.name;
      console.log('serviceType:', this.serviceType, ',serviceProvider:', this.serviceProvider);
    } else {
      this.showErrorMessage(json.response.displayMessage);
    }
  } catch (error) {
    console.log(error);
    this.showErrorMessage(AppSettings.ERROR_MSG);
  } finally {
    this.hideProgressBar();
  }  
}

populateDates(serviceName: string, serviceCategory: string, serviceCategoryDetails: any, serviceStartDate: any)  {
    // const currentDate = new Date(serviceStartDate);
    // const endDate = new Date();
    // endDate.setMonth(currentDate.getMonth() + 12);
    // let startDate = new Date(serviceStartDate);
    
    let startDate = new Date();
    if(serviceStartDate != null){
      const [day, month, year] = serviceStartDate.split('-').map(Number);;
      startDate = new Date(year, month - 1, day);
    }

    const endDate = new Date(startDate); 
    endDate.setMonth(startDate.getMonth() + 12);

    const getFormattedDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const addToAvailableData = (date: Date) => {
      if (date < startDate) {
          return;
      }
      const formattedDate = getFormattedDate(date);
      
      if (!this.availabledataForAllDay[formattedDate]) {
          this.availabledataForAllDay[formattedDate] = [];
      }        
  };

    switch (serviceCategory) {
        case "Recurring Bi-Weekly":
            serviceCategoryDetails.forEach((dayDetail: any) => {
                let dayOfWeek = dayDetail.name;
                for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                    if (d.toLocaleDateString('en-US', { weekday: 'long' }) === dayOfWeek) {
                        addToAvailableData(d);
                        d.setDate(d.getDate() + 7);
                    }
                }
            });
            break;

        case "Recurring Weekly":
          if (Array.isArray(serviceCategoryDetails)) {
            serviceCategoryDetails.forEach((dayDetail: any) => {
              
                let dayOfWeek = dayDetail.name;
                for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                    if (d.toLocaleDateString('en-US', { weekday: 'long' }) === dayOfWeek) {
                        addToAvailableData(d);     
                    }
                }
            });
        }
        break;


        case "Recurring Monthly":
            serviceCategoryDetails.forEach((dateDetail: any) => {

                let utcDate = new Date(dateDetail.name);
                let targetOffset = -8 * -60;
                let localTime = utcDate.getTime() + targetOffset * 60000;
                let date = new Date(localTime);
                
                if (date >= startDate && date <= endDate) {
                    addToAvailableData(date);
                }
            });
            break;

        case "Recurring Daily":
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                addToAvailableData(d);
            }
            break;

        case "Non-Recurring":
            let utcDate = new Date(serviceCategoryDetails);
            let targetOffset = -8 * -60;
            let localTime = utcDate.getTime() + targetOffset * 60000;
            let nonRecurringDate = new Date(localTime);

            if (nonRecurringDate >= startDate && nonRecurringDate <= endDate) {
                addToAvailableData(nonRecurringDate);
            }
            break;

        default:
            console.log("Unknown service category");
    }
  }


  clearFormData() {
    if(!this.isAdding) {
      this.messageService.clear('checkMsg');
      this.messageService.clear('errMsg');
      this.appointmentStartDateTime = '';
      this.appointmentEndDateTime = '';
      this.serviceType = 'null';
      this.serviceProvider = 'null';
      this.client = 'null';
      this.specialInstruction = '';
      this.serviceDuration = null;
      this.searchText = '';
      this.serviceProvider = '';
      this.serviceType= '';
      this.selectedSlot = '';
      this.selectedClient = '';

      this.uploadedFiles = []
      this.errorDataList = []
      this.fileInput!.nativeElement.value = null;
      this.successFileName = '';
      this.errorFileName = '';

      if (this.twillioNumber == null) {
        this.sendNotification = false
        this.disabledSendNotification = true
      } else {
        this.sendNotification = true
        this.disabledSendNotification = false
      }
      this.blockComment = '';
      this.slotDisabled = false;
    }
  }


  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showCheckMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'checkMsg',
      severity: status,
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  checkUserType(type: string) {
    if (type == 'nonchatstasy') {
      this.isUserNonchatstasy = true
    } else {
      this.isUserNonchatstasy = false
    }

  }

  setEndDateTime() {
    if (this.appointmentStartDateTime != '') {
      let startDateTime = new Date(this.appointmentStartDateTime)
      if (this.serviceDuration) {
        let minute = (this.serviceDuration.split(':')[0] * 60) + parseInt(this.serviceDuration.split(':')[1])
        this.appointmentEndDateTime = this.dateAdd(startDateTime, 'minute', minute);
        console.log('minute:', minute, ',date:', this.dateAdd(startDateTime, 'minute', minute))
      }
    }
  }

  // onServiceTypeChange(event: any, from: string) {
  //   this.serviceDuration = this.serviceTypeList.find(obj => obj.value == event.value).serviceDuration
  //   this.setEndDateTime()
  //   this.specialInstruction = this.serviceTypeList.find(obj => obj.value == event.value).specialInstructions || ''
  //   this.getServiceProviderByServiceTypeList(event.value)
  //   this.serviceProvider = '';
  //   this.checkConflict('add_appointment');
  // }

  onServiceTypeChange(event: any, from: string) {
    
    this.serviceDuration = this.serviceTypeList.find(obj => obj.value === event.value)?.serviceDuration || '';
    this.setEndDateTime();
    this.specialInstruction = this.serviceTypeList.find(obj => obj.value === event.value)?.specialInstructions || '';
    this.selectedServiceType = event.value; 
    this.serviceProvider = ''; 
    this.AvailableSlotsForProvider = []; 
    this.selectedSlot = null; 
  
    
    this.getServiceProviderByServiceTypeList(event.value);
  
    
    this.getAppointmentForServiceAndProvider()
      .then(() => {
        
        if (this.AvailableSlotsForProvider.length === 0) {
          console.warn('No slots available for the selected service type and provider.');
        }
      })
      .catch((error) => {
        console.error('Error while fetching slots:', error);
      });
  
    this.checkConflict('add_appointment');
  }

  onServiceProviderChange(event: any){
    this.selectedServiceProvider = event.value; 
    this.AvailableSlotsForProvider = []; 
    this.selectedSlot = null; 
  
    this.getAppointmentForServiceAndProvider()
      .then(() => {
        
        if (this.AvailableSlotsForProvider.length === 0) {
          console.warn('No slots available for the selected service type and provider.');
        }
      })
      .catch((error) => {
        console.error('Error while fetching slots:', error);
      });
  
    this.checkConflict('add_appointment');
  }

  onDateChange(event: any, type: string) {
    this.setEndDateTime()
    this.checkConflict(type);
  }

  onFilterValueChange(event: any) {

    if(this.selectedServiceType){
      this.getServiceProviderByServiceTypeList(this.selectedServiceType);
    } 

    if(this.isAdding){
      this.availabledataForAllDay = {};
      this.triggerDayCellDidMountManually();
      this.calendarComponent.getApi().removeAllEvents();
      this.getAvailableSlotsSelectedDate();
    }
    else{
      this.listAppointment();
    }

    if(this.isEditing){
      this.newServiceType = this.serviceTypeFilterList.find(item => item.name === this.selectedServiceType);
      this.newServiceProvider = this.serviceProviderFilterList.find(item => item.name === this.selectedServiceProvider);                    
    }
  }

  dateAdd(date: any, interval: any, units: any) {
    if (!(date instanceof Date))
      return undefined;
    var ret: any = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }

  toISOStringLocal(d: any) {
    function z(n: any) { return (n < 10 ? '0' : '') + n }
    return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' +
      z(d.getDate()) + 'T' + z(d.getHours()) + ':' +
      z(d.getMinutes()) + ':' + z(d.getSeconds())
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  get state(): string {
    return this.utils.state;
  }

  getTestAppointment(){
    this.appointmentService.ListTestAppointment().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Test Appointment Response --->> ' + JSON.stringify(json));
        this.appointmentList = json.data;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      });    
  }

  removeTestAppointment(){
    this.showProgressBar();
    this.appointmentService.removeTestAppointment().subscribe(
      (response) =>{
        var json = JSON.parse(JSON.stringify(response));
        console.log('Remove Test Appointment Response --->> ' + JSON.stringify(json));
        this.hideProgressBar();
        this.visibleTestAppointment = !this.visibleTestAppointment;
        this.showSuccessMessage(json.response.status, json.response.message);
        this.listAppointment();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    )
  }

  checkHolidaysConflict(){
    this.isCalendarLoading = true;
    this.businessHolidaysService.getConfirmHolidaysList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          if(json.data != null){
            this.holidays = json.data.map((holiday:any) => holiday.date);
            this.invalidDates = json.data.map((dateString:any) => new Date(dateString.date));
            json.data.forEach((element:any) => {              
              this.INITIAL_EVENTS.push({
                id: element.id,
                title: element.name,
                clientName: element.name,
                start: element.date,
                end: element.date,
                allDay: true,
                backgroundColor: "#ccc",
                borderColor: "#ccc",
                color: "#ccc",
                isBlocked: true,
              });
            });
            this.holidayEvents = this.INITIAL_EVENTS.filter((event: any) => event.allDay);
            this.calendarComponent!.options!.events = this.INITIAL_EVENTS;
          }       
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isCalendarLoading = false;
    },
    (error) => {
      console.log(error);
      this.showErrorToastMsg(AppSettings.ERROR_MSG)
      this.isCalendarLoading = false;
    });
  }

  // Method to print calendar as PDF
  // printCalendarAsPDF() {

  //   this.dummy();
  
    
  //   const calendarContainer = this.calendarContainer?.nativeElement;
  //   console.log(calendarContainer);

  //   if (calendarContainer) {
  //     html2canvas(calendarContainer).then(canvas => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const imgWidth = 210;
  //       const pageHeight = 295;
  //       const imgHeight = canvas.height * imgWidth / canvas.width;
  //       let heightLeft = imgHeight;
  //       let position = 0;

  //       pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       pdf.save('calendar.pdf');
  //     });
  //   }
  // }

 printDiv(): void {
    const printContents = document.getElementById('print-page')?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
        const printContainer = document.createElement('div');
        printContainer.id = 'print-container';
        printContainer.style.display = 'none';
        printContainer.innerHTML = printContents;
        document.body.appendChild(printContainer);

        const children = Array.from(document.body.children);
        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = 'none';
            }
        });

        printContainer.style.display = 'block';
        window.print();

        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = '';
            }
        });

        printContainer.remove();
    }
}


  // getColorForEvent(event: any) {
  //   const colors = ['#674ea7', '#6aa84f', '#B30000', '#0066ff'];
  //   return colors[event.id % colors.length];
  // }

  getBusinessHours(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.businessHoursService.getListBusinessHours().subscribe((response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          let i = 0
          json.data.forEach((element: any) => {
            let possibleTimeslotsTempList: any[] = []
            element.possibleTimeslots.forEach((element: any) => {
              possibleTimeslotsTempList.push({ name: element.startTimeSlotDisplay + ' - ' + element.endTimeSlotDisplay, code: element.startTimeSlot + '-' + element.endTimeSlot });
            });
            let offHourTemp: any[] = [];
            element.offHour.forEach((j: any) => {
              offHourTemp.push({ name: j.startTimeDisplay + ' - ' + j.endTimeDisplay, code: j.startTime + '-' + j.endTime })
            });
            this.businessHoursList.push(
              {
                id: element.id,
                name: element.nameOfDay,
                possibleTimeslots: possibleTimeslotsTempList,
                selectedOffHour: offHourTemp,
                startTime:element.startTime,
                endTime:element.endTime,
                hasOffHour: element.hasOffHour,
                isActive: element.isActive
              }
            );
            // this.getBusinessHourTimeSlots(i, this.timeList[this.getPositionOfTime(element.startTime)], this.timeList[this.getPositionOfTime(element.endTime)])
            i = i + 1;
          });
        }
        resolve(true);
      })
    })
  }

  async handleDatesSet(arg: DatesSetArg) {
      await this.getBusinessHours();
      const calendarApi = arg.view.calendar;
      const startDate = new Date(arg.start);
      const endDate = new Date(arg.end);
  
      let globalMinTime = '23:59:59';
      let globalMaxTime = '00:00:00';
  
      let minTimes: { [key: number]: string } = {};
      let maxTimes: { [key: number]: string } = {};
      let current = new Date(startDate);
      while (current <= endDate) {
          const day = current.getDay();
          let dayName = '';
          switch(day) {
              case 0: dayName = 'SUNDAY'; break;
              case 1: dayName = 'MONDAY'; break;
              case 2: dayName = 'TUESDAY'; break;
              case 3: dayName = 'WEDNESDAY'; break;
              case 4: dayName = 'THURSDAY'; break;
              case 5: dayName = 'FRIDAY'; break;
              case 6: dayName = 'SATURDAY'; break;
          }
          
          const businessHour = this.businessHoursList.find((bh:any) => bh.name === dayName);
          
          if (businessHour) {
              minTimes[day] = businessHour.startTime;
              maxTimes[day] = businessHour.endTime;
              if (businessHour.startTime < globalMinTime) {
                  globalMinTime = businessHour.startTime;
              }
              if (businessHour.endTime > globalMaxTime) {
                  globalMaxTime = businessHour.endTime;
              }
          }
          current.setDate(current.getDate() + 1);
      }
  
      calendarApi.setOption('slotMinTime', globalMinTime);
      calendarApi.setOption('slotMaxTime', globalMaxTime);
  }

  rowsPerPageOptions: number[] = [10, 20, 30];
  rows: number = 10;
  first: number = 0;

  filterByPhoneNumber(searchText: string): any[] {
    if (!searchText) {
        return this.clients;
    }
    return this.clients.filter(client =>
        client.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        client.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        client.mobileNo.includes(searchText)
    );
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  onSlotClick(slot: string) {
    this.selectedSlot = slot;
    console.log('Selected Slot:', slot);
  }

  onClientRowSelect(event: any) {
    this.selectedClient = event;
    this.client = event.value;
    console.log('Selected Client:', this.selectedClient);
  }

  onConfirm() {
    if (this.selectedSlot && this.selectedClient) {
      console.log('Selected Slot:', this.selectedSlot);
      console.log('Selected Client:', this.selectedClient);
    } else {
      console.error('Please select both a slot and a client');
    }
  }

  printPage(id: any): void {
    const printContents = document.getElementById(id)?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
        const printContainer = document.createElement('div');
        printContainer.id = 'print-container';
        printContainer.style.display = 'none';
        printContainer.innerHTML = printContents;
        document.body.appendChild(printContainer);

        const children = Array.from(document.body.children);
        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = 'none';
            }
        });

        printContainer.style.display = 'block';
        window.print();

        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = '';
            }
        });

        printContainer.remove();
    }
}

  async getAppointmentForServiceAndProvider() {
    await this.listServiceType();
    
    return new Promise((resolve, reject) => {
    this.serviceTypeService.getSlotByDateAndServiceId(this.selectedServiceType, this.selectedDate, this.selectedServiceProvider).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.AvailableSlotsForProvider = json.data.SlotsTime.map((slot:any) => ({ name: slot, value: slot }));
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        const formattedSlot = `${this.formatTime(this.appointmentStartDateTime)} - ${this.formatTime(this.appointmentEndDateTime)}`;
        this.selectedSlot = this.AvailableSlotsForProvider.find((slot: { value: string; }) => slot.value === formattedSlot)?.value || null;
        resolve(this.selectedSlot);
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  });
  }

  onSlotTimeClick(event: any) {
    this.selectedSlot = event.value;
    this.createScheduledTimes(this.selectedSlot);
  }

  createScheduledTimes(selectedSlot: any) {
    const [startTime, endTime] = selectedSlot.split(' - ');
    console.log('Start Time:', startTime, ',End Time:', endTime);
    const startDateTime = this.combineDateTime(this.selectedDate, startTime);
    const endDateTime = this.combineDateTime(this.selectedDate, endTime);
    this.appointmentStartDateTime = startDateTime;
    this.appointmentEndDateTime = endDateTime;
    console.log('Start Date:', this.appointmentStartDateTime, ',End Date:', this.appointmentEndDateTime);
  }
  
  combineDateTime(dateString: string, timeString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    const [time, period] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    
    return new Date(year, month - 1, day, hours, minutes);
  }

  isFileValid(fileInput: any) {
    const file = fileInput.target.files[0];
    var size = file.size;
    var name = file.name;
    var extension = name.substring(name.lastIndexOf('.') + 1);
    if (extension == 'csv' || extension == 'xls' || extension == 'xlsx') {
      if (size > 5000000) {
        let finalName = name;
        if (name.length > 20) {
          finalName = name.substring(0, 20) + '...' + extension;
        }
        this.showErrorMessage(finalName + ' File should not be more than 5 MB!')
        return false
      } else {
        return true
      }
    } else {
      this.showErrorMessage('Invalid file, please select only csv, xls, xlsx, xlsm')
      return false
    }
  }

  addFiles(fileInput: any) {
    this.messageService.clear('errMsg');
    if (this.isFileValid(fileInput)) {
      this.uploadedFiles = [];
      const file: File = fileInput.target.files[0];
      this.uploadedFiles.push(file);
    } else {
      this.importDisabled = true;
      this.errorDataList = []
      this.fileInput!.nativeElement.value = "";
    }
  }

  importAppointmentExcelPreview(fileInput: any) {
    this.showProgressBar()
    if (fileInput == null && fileInput == '') {
      this.showErrorMessage('Please select a file.')
      this.hideProgressBar();
      return;
    }
    this.errorDataList = [];
    this.numErrorRecords = 0;
    const file: File = fileInput.files[0];
    const reader = new FileReader();    
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      
      this.appointmentService.importAppointmentExcelPreview(this.selectedFile.file).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Import Client Post Response sd --->> ' + JSON.stringify(json));
          if (json.response.status == 'SUCCESS') {
            console.log(json);
            this.noOfData = 0;
            json.data.allRecordResult.forEach((element: any) => {
              
              if (this.noOfData < 25) {
                this.errorDataList.push({
                  row: element.value0.Row,
                  firstName: element.value1.clientData.firstName,
                  middleName: element.value1.clientData.middleName,
                  lastName: element.value1.clientData.lastName,
                  mobileNo: element.value1.clientData.mobileNo,
                  email: element.value1.clientData.email,
                  serviceProvider: element.value1.serviceProvider.name,
                  country: element.value1.clientData.country,
                  serviceType: element.value1.serviceType.name,
                  startTime: element.value1.scheduledOnFrom,
                  endTime: element.value1.scheduledOnTo,
                  result: element.value2,
                  resultMessage: element.value3
                })
              }
              this.noOfData++;
            });
            let flag = 0;
            if (json.data.numErrorRecords > 0) {
              this.showErrorMessage(json.data.message)
              this.numErrorRecords = json.data.numErrorRecords;
              this.importDisabled = true;
              flag = 1;
            }
            if (json.data.numSuccessRecords > 0 && flag == 0) {
              this.showMessage('success', json.data.message)
              this.importDisabled = false;
            }
            if (json.data.numWarningRecords > 0) {
              this.showMessage('warn', json.data.message)
              this.importDisabled = false;
            }
            this.successFileName = json.data.inputFile;
            this.errorFileName = json.data.errorFile;
          } else {
            this.showErrorMessage(json.response.displayMessage)
          }
          this.hideProgressBar();
        },
        (err) => {
          console.log(err);
          this.hideProgressBar();
          this.showErrorMessage(AppSettings.ERROR_MSG)
        }
      );
    });
    reader.readAsDataURL(file);
  }


  importAppointments() {
    this.showProgressBar()
    this.appointmentService.importAppointments(this.successFileName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Import Client Post Response add --->> ' + JSON.stringify(json));
        
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('import_client');
          this.showSuccessToastMsg(json.response.displayMessage)
          this.listAppointment();
          // this.listClients(this.currentPage, this.searchInputText);
        } else {
          this.showErrorMessage(json.response.displayMessage)
          // this.showErrorMessage("Please correct errors to procced")
        }
        this.hideProgressBar();
      },
      (err) => {
        console.log(err);
        this.hideProgressBar();
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  downloadTemplate() {
    this.appointmentService.getDownloadTemplate();
  }

  showMessage(severity: string, msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: severity,
      detail: msg,
    });
  }

  showSuccessToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: 'Success',
      detail: msg,
    });
  }

  listClients(pageNumber: any, searchValue: any) {
    console.log('page number:' + pageNumber)
    this.clients = [];
    this.isLoading = true;
    let sortData;
    if (this.selectedSortBy != undefined && this.selectedSortBy != null) {
      sortData = {
        sortBy: this.selectedSortBy.split('_')[0],
        sortDirection: this.selectedSortBy.split('_')[1]
      }
    }
    this.clientDataService.getClientList(pageNumber, searchValue, this.selectedClientType, sortData).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Clients --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clients = json.data;
          this.pageSize = json.additionalData?.pageSize || 0;
          this.totalCount = json.additionalData?.totalCount || 0;
        } else {

        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  disableRightClick(event: MouseEvent) {
    event.preventDefault();
  }

  clientConfirmAppointment() {
    this.showProgressBar()
    this.appointmentService.confirmAppointmentPayment(this.appointmentId,true).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status,json.response.message)
          this.listAppointment();
          this.clearFormData();
        } else {
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

}