import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { MessageService } from 'primeng/api';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { LocationStrategy } from '@angular/common';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { ServiceTypeService } from '../services/service-type/service-type.service';
import { AppSettings } from '../Constant/AppSettings';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-service-gallery',
  templateUrl: './service-gallery.component.html',
  styleUrls: ['./service-gallery.component.css']
})
export class ServiceGalleryComponent implements OnInit {

  showBusinessName: boolean = false;
  businessUrl = '';
  baseUrl = '';
  disabled: boolean = true;
  isLoading: boolean = false;
  serviceTypeImages: any[] = [];
  BaseURL:string = environment.IMAGE_BASE_URL;
  @ViewChild('largeModal') largeModal:any;
  selectImage:string = '';
  serviceName:string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private locationStrategy: LocationStrategy,
    private router: Router,
    private serviceTypeService: ServiceTypeService,
  ) { }

  ngOnInit(): void {
    // console.log("Nice"+this.router.url.split('?')[0].split('/').pop());
    this.baseUrl = CommonFunctions.replaceAll(this.locationStrategy.getBaseHref(), '/', '')
    CommonFunctions.setBaseUrl('');
    if (this.baseUrl == '' || this.baseUrl == 'login') {
      this.showBusinessName = true;
    } else {
      this.showBusinessName = false;
      this.getBusinessInfoByShortname()
    }
    this.getSingleServiceType(this.router.url.split('?')[0].split('/').pop());
  }

  ShowImagePopUp(file:any){
    this.largeModal.nativeElement.style.display='block';
    this.selectImage = this.BaseURL+file.fileName;
  }

  async getSingleServiceType(serviceId:any){
    await this.serviceTypeService.getSingleServiceType(serviceId).subscribe(
      async (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get User Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.serviceTypeImages = json.data.serviceImage;
          this.serviceName = json.data.name;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }


  getBusinessInfoByShortname() {
    this.showProgressBar()
    let shortName = '';
    if (this.showBusinessName) {
      shortName = this.businessUrl
    } else {
      shortName = this.baseUrl
    }

    this.authenticationService.getBusinessInfoByShortname(shortName).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Business Info Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

 showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  CloseImagePopUp(){
    this.largeModal.nativeElement.style.display='none';
  }

}
