import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { SupportTicketsService } from '../services/support-tickets/support-tickets.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { ImageSnippet } from '../Constant/ImageSnippet';

@Component({
  selector: 'app-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.css']
})
export class SupportTicketsComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Support Tickets' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  @ViewChild('p', { static: false }) paginator?: Paginator;
  @ViewChild('fileInput') fileInput?: ElementRef;
  @ViewChild('fileInputComment') fileInputComment?: ElementRef;
  ticketId = '';
  cols: any[] = [];
  ticketsList: any[] = [];
  commentsList: any[] = [];
  attachments: any[] = [];
  disabled: boolean = false;
  isLoading: boolean = false;
  innerWidth: any;
  statusList: any[] = [];
  selectedStatus: any;
  profileUrl = 'assets/images/picture.svg'
  //Paginator variable
  timer: any;
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;
  //Dialog
  selectedFile: ImageSnippet | undefined;
  titleText: string = '';
  descText: any = '';
  statusText: number = 0;
  commentText: string = '';
  headerName: string = '';
  displayAddEditModal: boolean = false
  displayCommentModal: boolean = false
  displayViewModal: boolean = false
  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private supportTicketsService: SupportTicketsService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'subject', header: 'Title' },
      { field: 'status', header: 'Status' },
    ]
    this.ticketsList = [
      { title: 'New test Ticket' }
    ]
    this.statusList = [
      { name: 'Open', value: '2' },
      { name: 'Closed', value: '5' }
    ]
    this.listTickets(this.currentPage, '');
  }

  addComment(fileInput: any) {
    this.showProgressBar()
    if (this.commentText == '') {
      this.showErrorMessage('Title field is empty.')
      this.hideProgressBar();
      return;
    }
    let Data: any = {
      body: this.commentText,
      // user_id: CommonFunctions.getUserId()
    }
    var data = JSON.parse(JSON.stringify(Data));
    console.log("add comment:", JSON.stringify(Data));
    this.supportTicketsService.addComment(this.ticketId, Data, fileInput.files[0]).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Comment Post Response --->> ' + JSON.stringify(json));
        this.showSuccessToastMsg('SUCCESS', 'New comment added sucessfully.')
        this.showHideModalDialog('add_comment')
        this.listCommets(this.ticketId)
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }


  addTicket(fileInput: any) {
    this.showProgressBar()
    if (this.titleText == '') {
      this.showErrorMessage('Title field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.descText == '') {
      this.showErrorMessage('Description field is empty.')
      this.hideProgressBar();
      return;
    }

    const Data: any = {
      description: this.descText,
      subject: this.titleText,
      email: 'ram@chatstasy.com',
      priority: 3,
      status: 2,
      type: "Problem/Issue",
      custom_fields: {
        cf_business_id: CommonFunctions.getBusinessId()
      },
      responder_id: 9003465061,
      // cc_emails: [
      //   "surendar.augmatrixlab@gmail.com"
      // ],
    }

    if (fileInput != null && fileInput != '') {
      console.log('entered:', fileInput.files[0])
      const file: File = fileInput.files[0];
      const reader = new FileReader();
    }

    var data = JSON.parse(JSON.stringify(Data));
    console.log("add data:", JSON.stringify(Data));
    this.supportTicketsService.addTicket(Data, fileInput.files[0]).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Ticket Post Response --->> ' + JSON.stringify(json));
        this.showMessage('warn', 'Your ticket is getting submitted.')
        this.showHideModalDialog('add_ticket')
        this.callListInterval(json.id);
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }


  closeOrReopenTicket(value: number) {
    this.showProgressBar()
    const Data = {
      status: value,
    }
    var data = JSON.parse(JSON.stringify(Data));
    console.log("close/edit data:", JSON.stringify(Data));
    this.supportTicketsService.editTicket(Data, this.ticketId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Close Ticket Response --->> ' + JSON.stringify(json));
        if (value == 5) {
          this.showSuccessToastMsg('SUCCESS', 'Ticket is closed sucessfully.')
        } else {
          this.showSuccessToastMsg('SUCCESS', 'Ticket is reopened sucessfully.')
        }

        this.showHideModalDialog('view_ticket')
        this.listTickets(this.currentPage, '')
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  listTickets(page_number: number, from: string) {
    if (from != 'add_ticket') {
      this.showProgressBar()
      this.ticketsList = []
    }
    let statusFilter = '';
    if (this.selectedStatus != null) {
      statusFilter = '%20AND%20status:' + this.selectedStatus;
    }
    const filterData: string = `"cf_business_id:` + CommonFunctions.getBusinessId() + statusFilter + `"`;
    this.supportTicketsService.filterTicketsListByClientId(page_number, filterData).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Tickets Response --->> ' + JSON.stringify(json));
        this.ticketsList = []
        json.results.forEach((element: any) => {
          this.ticketsList.push({
            id: element.id,
            subject: element.subject,
            status: this.getStatusLabelById(element.status),
          })
        });
        var result = (json.total / 30)
        result = json.total % 30 == 0 ? result : (result + 1)
        this.pageSize = 30;
        this.totalCount = json.total
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  listCommets(ticket_id: string) {
    this.commentsList = [];
    this.showProgressBar()
    this.supportTicketsService.listComments(ticket_id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List Comments Response --->> ' + JSON.stringify(json));
        this.commentsList = json;
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  getTicket(type: any, item: any) {
    switch (type) {
      case 'edit_ticket':
        this.showHideModalDialog('edit_ticket')
        break;
      case 'view_ticket':
        this.showHideModalDialog('view_ticket')
        break;
    }

    this.ticketId = item.id;
    this.showProgressBar()
    this.supportTicketsService.getTicket(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Ticket Response --->> ' + JSON.stringify(json));
        this.titleText = json.subject
        this.descText = json.description_text
        this.statusText = json.status
        this.attachments = json.attachments
        this.listCommets(item.id)
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  downloadFile(url: any) {
    window.open(url);
  }

  save(urlData: string, name: string) {
    // const data = new Blob([urlData], { type: 'octet/stream' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(urlData);
    a.href = url;
    const filename = name;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  confirmDialog(type: string) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: type == 'close' ? 'Do you want to close this ticket ?' : 'Do you want to Reopen this ticket ?',
      header: type == 'close' ? 'Close Confirmation' : 'Reopen Confirmation',
      icon: type == 'close' ? 'pi pi-times' : 'pi pi-replay',
      accept: () => {
        if (type == 'close') {
          this.closeOrReopenTicket(5);
        } else {
          this.closeOrReopenTicket(2);
        }
      },
    });
  }

  onFilterValueChange(event: any) {
    this.listTickets(this.currentPage, '')
  }

  callListInterval(id: any) {
    const interval = setInterval(() => {
      const idInArray = this.ticketsList.some(obj => this.objectsAreEqual(obj, id))
      if (idInArray) {
        clearInterval(interval);
        this.messageService.clear('successMsg');
        this.showMessage('success', 'Your ticket is submitted successfully.')
      } else {
        this.listTickets(this.currentPage, 'add_ticket')
      }
      console.log(idInArray)
    }, 5000);

  }

  objectsAreEqual(obj1: any, id: any) {
    return obj1.id === id
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_ticket':
        this.headerName = 'Edit Ticket';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_ticket':
        this.clearFormData();
        this.headerName = 'Add Ticket';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'view_ticket':
        this.clearFormData();
        this.headerName = 'View Ticket';
        this.displayViewModal = !this.displayViewModal;
        break;
      case 'add_comment':
        this.commentText = '';
        this.headerName = 'Add Comment';
        this.displayCommentModal = !this.displayCommentModal;
        break;
    }
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.titleText = ''
    this.descText = ''
    this.commentText = ''
    this.fileInput!.nativeElement.value = null;
    this.fileInputComment!.nativeElement.value = null;
  }

  getStatusLabelById(value: any) {
    let label = ''
    switch (value) {
      case 2:
        label = 'Open';
        break;
      case 3:
        label = 'Pending';
        break;
      case 4:
        label = 'Resolved';
        break;
      case 5:
        label = 'Closed';
        break;
    }
    return label;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'successMsg',
      severity: status,
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessToastMsg(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  paginate(event: any) {
    console.log(event)
    this.listTickets(event.page + 1, '')
  }

  get state(): string {
    return this.utils.state;
  }
}
