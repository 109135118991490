import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MessageBotService {

  constructor(
    private http: HttpClient
  ) { }

  getMessages(MESSAGE_TYPE: string) {
    const url = environment.API_URL + '/api/customMessages/' + MESSAGE_TYPE;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editMessage(data: any, MESSAGE_TYPE: string) {
    // console.log('Edit Message Service Data ---> ' + JSON.stringify(data));

    const url = environment.API_URL + '/api/customMessages/' + MESSAGE_TYPE + '/edit';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  activateDeactivateMessage(status: any, MESSAGE_TYPE: string) {
    // console.log('Edit Message Service Data ---> ' + JSON.stringify(data));
    const url = environment.API_URL + '/api/customMessages/' + MESSAGE_TYPE + '/activate?active=' + status;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  resetMessages(MESSAGE_TYPE: string) {
    const url = environment.API_URL + '/api/customMessages/' + MESSAGE_TYPE + '/reset';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }
}
