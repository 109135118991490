<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Client Communication
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <!-- <button *ngIf="visibleBtn" pButton pRipple type="button" label="Add Chat"
                    class="p-button-sm p-button-raised custom_add_btn" style="font-size: 12px;" icon="pi pi-plus"
                    (click)="showHideModalDialog('add_chat')" [disabled]="bussinesInfo.state=='offline'"></button> -->
                    <button *ngIf="visibleBtn" pButton pRipple type="button" label="Add Chat"
                    class="p-button-sm p-button-raised custom_add_btn" style="font-size: 12px;" icon="pi pi-plus"
                    (click)="showHideModalDialog('add_chat')"></button>
                <p-tabView (onChange)="onChangeTab($event)">
                    <!-- *ngIf="getPrivilege('customer_notification_send')" -->
                    <p-tabPanel *ngIf="getPrivilege('manage_individual_messages')" header="Customer Messages">
                        <div class="p-d-flex">
                            <app-chat-sidebar style="width: 30%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;" [userId]="user_id">
                            </app-chat-sidebar>
                            <app-chat-area style="width: 70%;" from="client"></app-chat-area>
                        </div>

                    </p-tabPanel>
                    <!-- *ngIf="getPrivilege('customer_notification_broadcast')" -->
                    <p-tabPanel *ngIf="getPrivilege('manage_broadcast_messages')" header="Message Broadcast">
                        <app-broadcast-message></app-broadcast-message>

                    </p-tabPanel>
                </p-tabView>
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- ADD CHAT MODAL -->
<p-dialog header="Add Chat" [(visible)]="displayAddModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,addDialog)" #addDialog>
    <p-messages key="errMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <div style="height: 100%;min-width: 33vw;">
        <div class="header">
            <div class="sidebar_search">
                <span class="p-input-icon-right">
                    <i *ngIf="searchText !== ''" class="pi pi-times" style="cursor: pointer;"></i>
                    <input #searchInput pInputText type="text" [(ngModel)]="searchText"
                        placeholder="Search or start new chat" />
                </span>
                <button pButton pRipple type="button" icon="pi pi-search" [style]="{width:'2.1rem',height:'2.1rem'}"
                    class="p-button-rounded p-button-raised p-button-sm p-ml-2"></button>

            </div>
        </div>
        <p-scrollPanel [style]="{width: '100%', height: '47vh'}" #sc>
            <ul class="sidebar_ul">
                <li class="list_sidebar" *ngFor="let client of clients | filter: searchText"
                    (click)="addChatRoom(client)">
                    <a class="sidebar_container">
                        <img *ngIf="client.userType == 'chatstasy'" src="assets/images/chatstasy_circle.png"
                            class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                        <img *ngIf="client.userType != 'chatstasy'" src="assets/images/SMS_Logo.png"
                        class="sidebar_profile_img" style="width: 32px;height: 32px;" alt="">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title">{{client.firstName}} {{client.middleName}}
                                {{client.lastName}}</h4><span style="color: #919191"></span>
                        </div>
                    </a>
                </li>
                <p-messages *ngIf="clients?.length == 0 && !isLoading" key="errMsg" class="alignCenter">
                    <ng-template pTemplate>
                        <i class="pi pi-copy" style="font-size: 2rem"></i>
                        <div class="p-ml-2">NO CLIENTS FOUND</div>
                    </ng-template>
                </p-messages>
            </ul>
        </p-scrollPanel>
        <div *ngIf="isLoading" class="progressLayout">
            <div class="progressContainer">
                <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
            </div>
        </div>
    </div>
</p-dialog>
<!-- <app-footer></app-footer> -->