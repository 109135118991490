import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment.prod';
import { AppSettings } from '../Constant/AppSettings';
import { BusinessInfoService } from '../services/business-info/business-info.service';
import { RegistrationService } from '../services/registration/registration.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { BackupService } from '../services/backup/backup.service';
import { Dialog } from 'primeng/dialog';
import { BusinessHoursService } from '../services/business-hours/business-hours.service';
import { DatePipe } from '@angular/common';
import { ElapsedService } from '../services/Elapsed/elapsed.service';



@Component({
  selector: 'app-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['./business-information.component.css']
})
export class BusinessInformationComponent implements OnInit {
  items: MenuItem[] = [{ label: 'Business Information' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  businessName: string = '';
  address = '';
  country = 'null';
  countries: any[] = [];
  phone: string = '';
  fax: string = '';
  ein: string = '';
  websiteUrl: string = '';
  businessType: any[] = []
  timezone: string = 'null';
  timezoneList: string[] = [
    "(GMT -5:00) US/Eastern",
    "(GMT -6:00) US/Central",
    "(GMT -7:00) US/Mountain",
    "(GMT -8:00) US/Pacific",
    "(GMT -9:00) US/Alaska",
    "(GMT -10:00) US/Hawaii",
  ];
  selectedBusinessType: any = 'null';
  shortNameUrl: string = '';
  twillioNumber: string = '';
  timeoutError = '';
  isLoading: boolean = false;
  isLoadingUrl: boolean = false;
  disabled: boolean = false;
  disabledShortUrl: boolean = false;
  isFormValid = false;
  //
  businessStatus: boolean = false;
  //File upload
  avatarName = '';
  imageInputError: boolean = false;
  profileUrl = '';
  //image cropper
  croppedImageUrl: any = '';
  //Crop Dialog
  displayCropModal: boolean = false
  businessStatusDate: string = '';
  address2 = '';
  city = '';
  business_state: string = '';
  zipcode: string = '';
  twilioAddressId = CommonFunctions.getBusinessInfo().twilioAddressId;
  twilioMessageServiceId = CommonFunctions.getBusinessInfo().twilioMessageServiceId;
  displayBackupFile: boolean = false
  backupDialogMax: string = '278px';
  isMaximizeBackup: boolean = false;
  public innerWidth: any;
  backupList: any[] = [];
  selectedBackup: string = '';
  disabledBackupBtn: boolean = false;
  showExecuteLoading: boolean = false;
  operatingStatus: boolean = false;
  opreatingEndTime: string = '';
  opreatingEndWeek: string = '';
  businessTimezone: string = '';
  hoursList: any[] = [];

  constructor(
    public utils: UtilsService,
    private router: Router,
    private businessInfoService: BusinessInfoService,
    private registrationService: RegistrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private backupService : BackupService,
    private businessHoursService: BusinessHoursService,
    private datePipe: DatePipe,
    private elapsed: ElapsedService
  ) { }

  ngOnInit(): void {
    this.resetBusinessInfo();
    this.getCountries();
    this.getBusinessType();
    this.getShortName()
    this.getTimezone();
  }

  onMaximizeBackup(isMaximize: boolean) {
    console.log(isMaximize);
    if (isMaximize) {
      this.backupDialogMax = '278px';
      this.isMaximizeBackup = !this.isMaximizeBackup;
    } else {
      this.backupDialogMax = '350px';
      this.isMaximizeBackup = !this.isMaximizeBackup;
    }
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }


  editBusinessInfo(businessInfoForm: NgForm) {
    this.showProgressBar();
    if (businessInfoForm.value.businessName === '') {
      this.showErrorBannerMsg('Business Name Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.address === '') {
      this.showErrorBannerMsg('Address Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.city === '') {
      this.showErrorBannerMsg('City Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.business_state === '') {
      this.showErrorBannerMsg('State Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.country === '' || businessInfoForm.value.country === 'null') {
      this.showErrorBannerMsg('Country Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.phone === '') {
      this.showErrorBannerMsg('Phone Field is empty.')
      this.hideProgressBar();
      return;
    } if (this.phone.length <= 9) {
      this.showErrorBannerMsg('Phone Number should be 10 digits.')
      this.hideProgressBar();
      return;
    } if (!CommonFunctions.validateFax(this.fax)) {
      this.showErrorBannerMsg('Fax Number should be minimum 8 digits.')
      this.hideProgressBar();
      return;
    } if (this.ein === '') {
      this.showErrorBannerMsg('Ein Field is empty.')
      this.hideProgressBar();
      return;
    } if (!CommonFunctions.validateEin(this.ein)) {
      this.showErrorBannerMsg('EIN Number should be minimum 9 digits.')
      this.hideProgressBar();
      return;
    } if (this.websiteUrl != '' && !CommonFunctions.validURL(this.websiteUrl)) {
      this.showErrorBannerMsg('website is invalid.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.timezone === '' || businessInfoForm.value.timezone === 'null') {
      this.showErrorBannerMsg('Timezone Field is empty.')
      this.hideProgressBar();
      return;
    } if (businessInfoForm.value.businessType === '' || businessInfoForm.value.businessType === 'null') {
      this.showErrorBannerMsg('Business Type Field is empty.')
      this.hideProgressBar();
      return;
    }
    let mob = '+1' + this.phone;
    const profileData = {
      name: this.businessName,
      shortName: this.shortNameUrl,
      businessType: {
        id: this.selectedBusinessType,
        name: this.businessType.find(element => element.id == this.selectedBusinessType).name
      },
      address: this.address,
      country: this.country,
      countryName: '',
      phone: mob,
      fax: this.fax,
      logo: null,
      website: this.websiteUrl,
      timeZone: this.timezone,
      einNo: this.ein,
      businessState: this.business_state.toUpperCase(),      
      address2: this.address2,
      city: this.city,
      zipcode: this.zipcode,
    }

    var data = { data: JSON.parse(JSON.stringify(profileData)) };
    this.businessInfoService.editBusinessInfo(CommonFunctions.getBusinessId(), data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Business info Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          CommonFunctions.setBusinessInfo(json.data)
          this.setBusinessInfo(json.data)
          this.showSuccessMessage(json.response.status, json.response.displayMessage);
          setTimeout(() => {
            this.checkTwilioConfig();
          }, 1000);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editShortName() {
    this.disabledShortUrl = true;
    this.isLoadingUrl = true;
    if (this.shortNameUrl == '') {
      this.messageService.add({
        key: 'tr',
        severity: 'error',
        detail: 'Business Short Name Field is empty.',
      });
      this.isLoadingUrl = false;
      this.disabledShortUrl = false;
      return;
    }
    this.businessInfoService.editBusinessShortName(CommonFunctions.getBusinessId(), this.shortNameUrl.replace(/\s/g, '')).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Short name Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          window.location.href = json.data + '/business-information';
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.isLoadingUrl = false;
        this.disabledShortUrl = false;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.isLoadingUrl = false;
        this.disabled = false;
      }
    );
  }

  uploadBusinessLogo() {
    this.showProgressBar()
    this.businessInfoService.uploadBusinessLogo(CommonFunctions.getBusinessId(), this.croppedImageUrl).subscribe(
      (res) => {
        console.log(res);
        var json = JSON.parse(JSON.stringify(res));
        if (json.response.status == 'SUCCESS') {
          this.getBusinessInfo()
          this.showSuccessMessage(json.response.status, json.response.message)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (err) => {
        console.log(err);
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  checkBusinessShortname() {
    this.messageService.clear('tr');
    this.registrationService.checkBusinessShortname(this.shortNameUrl).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Check Short Name Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.disabledShortUrl = false
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.disabledShortUrl = true
        }
        if (this.shortNameUrl == '') {
          this.disabledShortUrl = true
        }
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.hideProgressBar();
      }
    );
  }


  private getCountries() {
    this.businessInfoService.getCountries().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.countries = json.data;
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  private getBusinessType() {
    this.businessInfoService.getBusinessType().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        // console.log(json.data);
        this.businessType = json.data;
        // json.data.forEach((element:any) =>{
        //   if(element.name == "DANCE STUDIO"){
        //     this.businessType.push({id: element.id, name: element.name});
        //   }
        //   if(element.name == "Non-Profit"){
        //     this.businessType.push({id: element.id, name: element.name.toUpperCase()});
        //   }
        // });
        this.businessType.sort((a,b) => a.name > b.name ? 1 : -1);
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getShortName() {
    this.businessInfoService.getBusinessShortName(CommonFunctions.getBusinessId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business short name --> ' + JSON.stringify(json.data));
        this.shortNameUrl = json.data
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getBusinessInfo() {
    this.businessInfoService.getBusinessInfo(CommonFunctions.getBusinessId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            this.setBusinessInfo(json.data)
            this.reloadComponent()
            // this.avatarName = json!.data!.name!.split(' ')[0]!.charAt(0) + json!.data!.name!.split(' ')[1]!.charAt(0);
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)

        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getTimezone() {
    this.businessInfoService.getTimezone().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business timezone response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          // Object.entries(json.data).forEach(([key, value]) => {
          //   if(key == '589' || key == '587' || key == '593' || key == '594' || key == '584' || key == '590'){
          //     this.timezoneList.push(json.data[key]);
          //   }
          // });
          //this.timezoneList = json.data;
          // this.timezoneList.push(json.data[590]);
          // this.timezoneList.push(json.data[588]);
          // this.timezoneList.push(json.data[594]);
          // this.timezoneList.push(json.data[595]);
          // this.timezoneList.push(json.data[585]);
          // this.timezoneList.push(json.data[591]);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  deleteBusinessLogo() {
    this.showProgressBar()
    this.businessInfoService.deleteBusinessLogo().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete Business Logo response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getBusinessInfo()
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  confirmEnableDisableDialog(isActive: boolean) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: isActive ? 'Are you sure you want to go online? Your customers will be able to connect to you' : 'Are you sure you want to go offline? Your customers will not be able to connect to you',
      header: (isActive ? 'Online' : 'Offline') + ' Confirmation',
      icon: isActive ? 'pi pi-check-circle' : 'pi pi-times-circle',
      accept: () => {
        this.updateState(isActive);
      },
      reject: () => {
        this.businessStatus = !isActive;
      }
    });
  }

  updateState(isActive: boolean) {
    let state = '';
    if (isActive) {
      state = 'online';
    } else {
      state = 'offline';
    }
    console.log('state:', state)
    const serviceData = {
      configurationWizard: null
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    this.businessInfoService.updateState(CommonFunctions.getBusinessId(), state, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Update State Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getBusinessInfo()
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.businessStatus = !isActive;
        }
      },
      (error) => {
        console.log(error);
        this.businessStatus = !isActive;
        this.hideProgressBar()
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  onToggle(event: any) {
    // this.editKeyword(event.checked, keyword);
    this.confirmEnableDisableDialog(event.checked)
  }


  confirmDeleteDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this image ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteBusinessLogo();
      }
    });
  }

  confirmChangeUrlDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are your sure you want to change your Business URL ?',
      header: 'Change Url Confirmation',
      icon: 'pi pi-link',
      accept: () => {
        this.editShortName();
      }
    });
  }

  resetBusinessInfo() {
    var businessData = CommonFunctions.getBusinessInfo();
    this.getBusinessHoursList();
    this.setBusinessInfo(businessData)
  }

  setBusinessInfo(businessData: any) {
    console.log(businessData);
    this.businessName = businessData.name;
    this.address = businessData.address;
    this.address2 = businessData.address2;
    this.city = businessData.city;
    this.country = businessData.country;
    this.phone = businessData.phone.replace('+1', '');
    this.fax = businessData.fax;
    this.ein = businessData.einNo;
    this.websiteUrl = businessData.website;
    this.selectedBusinessType = businessData.businessType.id;
    this.imageInputError = false
    this.profileUrl = environment.IMAGE_BASE_URL + businessData.logo;
    this.timezone = businessData.timeZone;
    this.twillioNumber = businessData.twilioNumber == null ? 'Not Allocated' : businessData.twilioNumber;
    if (businessData.state == 'online') {
      this.businessStatus = true;
    } else {
      this.businessStatus = false;
    }
    this.businessStatusDate = businessData.stateDate;
    this.business_state = businessData.businessState.toUpperCase();
    this.zipcode = businessData.zipcode;
    this.operatingStatus = businessData.operatingStatus;

    this.businessTimezone = businessData.timeZone; 
    
    setInterval(() => {
      var weekday: any[] = [];
      weekday[0] =  "SUNDAY";
      weekday[1] = "MONDAY";
      weekday[2] = "TUESDAY";
      weekday[3] = "WEDNESDAY";
      weekday[4] = "THURSDAY";
      weekday[5] = "FRIDAY";
      weekday[6] = "SATURDAY";
      
      if(this.hoursList.length > 0){
        this.hoursList.forEach((element:any)=>{      
          if(weekday[new Date(this.elapsed.getCurrentTimeInTimezone(this.businessTimezone)).getDay()] == element.nameOfDay){   
            
            var startTime = this.elapsed.getElapsedTime(this.datePipe.transform(this.elapsed.getCurrentTimeInTimezone(this.businessTimezone), 'yyyy-MM-dd')+"T"+element.startTime, this.businessTimezone);  
            
            var endTime = this.elapsed.getElapsedTime(this.datePipe.transform(this.elapsed.getCurrentTimeInTimezone(this.businessTimezone), 'yyyy-MM-dd')+"T"+element.endTime, this.businessTimezone);  

            if(Number(startTime.split(',')[0]) <= 0 && Number(startTime.split(',')[1]) <= 0 && Number(startTime.split(',')[2]) <= 0 && Number(startTime.split(',')[3]) <= 0){
              if(!this.operatingStatus){
                this.operatingStatus = true;
              }
            }

            if(Number(endTime.split(',')[0]) <= 0 && Number(endTime.split(',')[1]) <= 0 && Number(endTime.split(',')[2]) <= 0 && Number(endTime.split(',')[3]) <= 0){
              if(this.operatingStatus){
                this.operatingStatus = false;
              }
            }
          }
        });
      } 
    }, 60000);
  }


  reloadComponent() {
    this.utils.onReloadProfileClick(true);
  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/business_2.png'
  }

  hideCropModal(displayCropModal: any) {
    if (displayCropModal !== undefined) {
      this.displayCropModal = displayCropModal;
    }

  }


  croppedImage(imageFile: any) {
    if (imageFile !== undefined) {
      this.croppedImageUrl = imageFile?.croppedImageUrl
      this.profileUrl = imageFile?.base64Value;
      this.imageInputError = false
      this.uploadBusinessLogo()
    }
  }

  validateDigitOnly(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  // Only AlphaNumeric with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  showErrorBannerMsg(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  get state(): string {
    return this.utils.state;
  }

  Splitfunction(string:string, nb:any) {
    var array = string.split(' ');
    return array[nb];
  }

  checkTwilioConfig(){
    this.showProgressBar();
    this.businessInfoService.checkTwilioConfig().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            this.hideProgressBar();
            // this.showSuccessMessage(json.response.status, json.response.displayMessage)
          }
        }else{
          // this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  confirmBackupFileDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Are your sure you do want store the backup file?',
      header: 'Backup Configuration Data',
      icon: 'pi pi-link',
      accept: () => {
        this.exportBackupFile();
      }
    });
  }

  exportBackupFile(){
    this.isLoading = true;
    this.disabledBackupBtn = true;
    this.backupService.exportBackupFile().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.hideProgressBar();
          this.showSuccessMessage(json.response.status, json.response.displayMessage);
        }else{
          this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
        this.disabledBackupBtn = false;
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  confirmImportFileDialog() {
    if(this.selectedBackup == ""){
      this.showErrorBannerMsg("Please select backup file.");
      return;
    }    
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Please be aware any unsaved changes you had made to the configuration data will be lost when you restore this backup.',
      header: 'Import backup file',
      icon: 'pi pi-link',
      accept: () => {
        this.removeOldData();
      }
    });
  }

  listBackupFile(){
    this.backupService.getBackupFile().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if(json.data.length == 0){
            this.showErrorMessage("No backup found");
          }else{
            this.displayBackupFile = !this.displayBackupFile;
            this.backupList = json.data;
          }
          this.hideProgressBar();
        }else{
          this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
        this.selectedBackup = "";
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  removeOldData(){
    this.backupService.removeExistData(this.selectedBackup).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.hideProgressBar();
          // this.displayBackupFile = !this.displayBackupFile;
          this.showExecuteLoading = true;
          // this.backupList = [];
          // this.selectedBackup = "";
          this.importBackupFile();
          // this.showSuccessMessage(json.response.status, json.response.displayMessage);
        }else{
          alert();
          this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }


  importBackupFile(){
    this.backupService.importBackupFile(this.selectedBackup).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.hideProgressBar();
          this.displayBackupFile = !this.displayBackupFile;
          this.backupList = [];
          this.selectedBackup = "";
          this.showSuccessMessage(json.response.status, json.response.displayMessage);
        }else{
          this.showErrorMessage(json.response.displayMessage)
          this.hideProgressBar();
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar();
      }
    );
  }

  clearFormData() {
    this.messageService.clear('errMsg');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;

  }

  confirmationOperatingStatus(isActive: Boolean){
    this.messageService.clear();
    this.confirmationService.confirm({
      message: isActive ? 'Are you sure you want to open your business?' : 'Are you sure you want to close your business?',
      header: (isActive ? 'Open' : 'Close') + ' Confirmation',
      icon: isActive ? 'pi pi-check-circle' : 'pi pi-times-circle',
      accept: () => {
        this.updateBusinessOperatingStatus(isActive);
      },
      reject: () => {
        this.operatingStatus = !isActive;
      }
    });
  }

  onToggleOperatingStatus(event: any) {
    this.confirmationOperatingStatus(event.checked)
  }

  updateBusinessOperatingStatus(operatingStatus: any){    
    this.businessInfoService.updateOperatingStatus(CommonFunctions.getBusinessId(), operatingStatus).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Update State Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.getBusinessInfo()
        } else {
          this.showErrorMessage(json.response.displayMessage)
          this.operatingStatus = !operatingStatus;
        }
      },
      (error) => {
        console.log(error);
        this.operatingStatus = !operatingStatus;
        this.hideProgressBar()
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  // getCurrentTimeInTimezone(timezone: string): string {
    
  //   const parts = timezone.split(") ");
  //   const region = parts[1];
  //   const formatter = new Intl.DateTimeFormat('en-US', {
  //     timeZone: region.trim(),
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //     hour12: false
  //   });
  //   return formatter.format(new Date());
  // }

  getBusinessHoursList() {
    this.showProgressBar();
    this.businessHoursService.getListBusinessHours().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business Hours response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.hoursList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      }, (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      })
  }

  // getElapsedTime(dateTime: string): string {
  //   // Parse the dateTime string into a Date object
  //   const parsedDate = new Date(dateTime);

  //   // Get the current date and time
  //   const now = new Date(new Date(this.getCurrentTimeInTimezone(this.businessTimezone)));
  //   console.log(now);

  //   // Calculate the difference in milliseconds
  //   const diffInMs = parsedDate.getTime() - now.getTime();

  //   // Convert the difference to a readable format
  //   const diffInSeconds = Math.floor(diffInMs / 1000);
  //   const seconds = diffInSeconds % 60;

  //   const diffInMinutes = Math.floor(diffInSeconds / 60);
  //   const minutes = diffInMinutes % 60;

  //   const diffInHours = Math.floor(diffInMinutes / 60);
  //   const hours = diffInHours % 24;

  //   const diffInDays = Math.floor(diffInHours / 24);

  //   return `${diffInDays},${hours},${minutes},${seconds}`;
  // }
}
