import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  /**
     * Transform
     *
     * @param {any[]} items
     * @param {string} searchText
     * @returns {any[]}
     */
  transform(items: any[], searchTxt: string): any[] {
    let searchText = searchTxt
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      if (it.userType) {
        return it.firstName.toLocaleLowerCase().includes(searchText) || it.middleName.toLocaleLowerCase().includes(searchText) || it.lastName.toLocaleLowerCase().includes(searchText);
      } else {
        return it.recepient_name.toLocaleLowerCase().includes(searchText);
      }
    });
  }
}
