import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class BusinessKeywordsService {

  constructor(
    private http: HttpClient
  ) { }

  listKeywords() {
    const url = environment.API_URL + '/api/keywords';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editKeywordMsg(keyword_id: any, data: any) {
    const url = environment.API_URL + '/api/keywords/' + keyword_id + '/update';
    // console.log('Appointment add Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  enableDisable(keyword_id: any, isEnabled: any) {
    const url = environment.API_URL + '/api/keywords/' + keyword_id + '/updateStatus?isEnabled=' + isEnabled;
    // console.log('Appointment add Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  listVariables() {
    const url = environment.API_URL + '/api/variables';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addKeywordMsg(keyword_id: any, data: any) {
    const url = environment.API_URL + '/api/addkeyword';
    // console.log('Appointment add Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  removeBusinessKeywords(keywords: any) {
    const url = environment.API_URL + "/api/keywords/" + keywords + "/remove";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }
}
