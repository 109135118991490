import { Injectable } from '@angular/core';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Injectable({
  providedIn: 'root',
})
export class PrivilegeService {
  public privileges: any[] = [];

  constructor() { }

  setPrivileges(user_privileges: any[]) {
    for (let index = 0; index < this.privileges.length; index++) {
      let single_privileges = this.privileges[index];
      for (let indexJ = 0; indexJ < user_privileges.length; indexJ++) {
        let single_user_privileges = user_privileges[indexJ];
        if (single_privileges.name == single_user_privileges) {
          this.privileges[index].value = true;
        } else if (single_user_privileges == '_ALL') {
          this.privileges[index].value = true;

        }
      }
    }
    console.log(JSON.stringify(this.privileges));
    CommonFunctions.setPrivileges(this.privileges)
  }

  // [disabled]="getPrivileges('0')"

  getPrivileges(id: any): boolean {
    let val = false;
    let privileges: any[] = CommonFunctions.getPrivileges()
    for (let index = 0; index < privileges.length; index++) {
      const element = privileges[index].name;
      if (element == id) {
        val = privileges[index].value;
        break;
      } else {
        val = false;
      }
    }
    return val;
  }
}
