<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Business Calendar
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="p-mb-3 toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-6 p-lg-3 p-fluid">
                            <p-dropdown [options]="serviceTypeFilterList" [(ngModel)]="selectedServiceType"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterValueChange($event)" [style]="{'font-size':'14px'}"
                                placeholder="Service Name">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-6 p-lg-3 p-fluid">
                            <p-dropdown [options]="serviceProviderFilterList" [(ngModel)]="selectedServiceProvider"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterValueChange($event)" [panelStyle]="{'font-size':'14px'}"
                                placeholder="Service Provider">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-6 p-lg-6 p-jc-end d-none d-lg-flex d-xl-flex">
                            <button pButton pRipple label="Remove Test Appointment" icon="pi pi-trash" class="p-button-raised p-button-secondary p-button-sm" style="font-size: 14px;margin-right: 0.5rem;" (click)="showHideModalDialog('remove_test_appointment')"  [disabled]="bussinesInfo.state=='online'"></button>
                            <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple label="Block Slot"
                                icon="pi pi-ban" class="p-button-raised p-button-secondary p-button-sm"
                                style="font-size: 14px;margin-right: 0.5rem;"
                                (click)="showHideModalDialog('add_block_slots')"
                                [disabled]="bussinesInfo.state=='offline'"></button>
                            <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple
                                label="Add New Appointment" icon="pi pi-plus" class="p-button-raised p-button-sm"
                                style="font-size: 14px;" (click)="showHideModalDialog('add_appointment')"></button>
                        </div>

                    </div>
                </div>
                <div *ngIf="getPrivilege('manage_appointment')" class="p-grid d-lg-none">
                    <div class="p-col p-d-flex p-jc-center">
                        <button pButton pRipple label="Block Slot" icon="pi pi-ban"
                            class="p-button-raised p-button-secondary p-button-sm"
                            style="font-size: 12px;margin-right: 0.5rem;"
                            (click)="showHideModalDialog('add_block_slots')"></button>
                        <button pButton pRipple label="Add New Appointment" icon="pi pi-plus"
                            class="p-button-raised p-button-sm" style="font-size: 12px;"
                            (click)="showHideModalDialog('add_appointment')"></button>
                    </div>
                </div>
                <!-- <div class="p-grid">
                            <div class="p-col">
                                <button pButton pRipple label="Today" class="p-button-raised p-button-secondary"
                                    (click)="goToday()"></button>
                                <button pButton pRipple icon="pi pi-angle-left" [style]="{'margin-left': '.5em'}"
                                    class="p-button-raised" (click)="goPrev()"></button>
                                <button pButton pRipple icon="pi pi-angle-right" [style]="{'margin-left': '.2em'}"
                                    class="p-button-raised" (click)="goNext()"></button>
                            </div>
                        </div> -->
                <div *ngIf="isCalendarLoading" class="alignCenter">
                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                    </p-progressSpinner>
                </div>

                <full-calendar #calendar *ngIf='calendarVisible' [options]='calendarOptions'>

                </full-calendar>
                <div #contextMenu class="menu">
                    <ul class="menu-options">
                        <li class="menu-option" (click)="openDialogFromMenu('add_appointment')">Add Appointment
                        </li>
                        <li class="menu-option" (click)="openDialogFromMenu('add_block_slots')">Add Block Slot
                        </li>
                    </ul>
                </div>
                <!-- <p-contextMenu #contextMenu [target]="rightMenu" appendTo="body" [model]="menuItems">
                        </p-contextMenu> -->
                <!-- <p-menu #menu [popup]="true" [model]="menuItems"></p-menu> -->
            </p-card>
        </div>

    </p-scrollPanel>
</div>


<!-- ADD/EDIT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,addDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <form role="form" #addAppointmentForm="ngForm" style="min-width: 60vw;">
        <div class="row">
            <div class="form-group col-md-4">
                <label>Service Type<span class="symbol required"></span></label>
                <p-dropdown name="serviceType" [(ngModel)]="serviceType" [options]="serviceTypeList" optionLabel="name"
                    optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Service Type" [panelStyle]="{'font-size':'14px'}"
                    (onChange)="onServiceTypeChange($event,'modal')"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Service Provider<span class="symbol required"></span></label>
                <p-dropdown name="serviceProvider" [options]="serviceProviderList" [(ngModel)]="serviceProvider"
                    optionLabel="name" optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Service Provider" [panelStyle]="{'font-size':'14px'}" (onChange)="onServiceProviderChange($event)"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Client<span class="symbol required"></span></label>
                <p-dropdown name="client" [options]="clients" [(ngModel)]="client" optionLabel="name"
                    optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Client" [panelStyle]="{'font-size':'14px'}"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="startTime">Appointment Start Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentStartDateTime" name="appointmentStartDateTime" inputId="startTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="Start date/time" (onClose)="onDateChange($event,'add_appointment')" [disabledDates]="invalidDates"></p-calendar>

            </div>
            <div class="form-group col-md-4">
                <label for="endTime">Appointment End Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentEndDateTime" name="appointmentEndDateTime" inputId="endTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="End date/time" (onClose)="onDateChange($event,'add_appointment')"></p-calendar>

            </div>

        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Special Instruction</label>
                <div style="position: relative;">
                    <textarea pInputTextarea type="text" class="form-control" name="specialInstruction"
                        placeholder="Enter Special Instruction..." [(ngModel)]="specialInstruction" maxlength="500">
                    </textarea>
                    <span class="remaning">{{specialInstruction?.length}}/500</span>
                </div>

            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button *ngIf="headerName=='Add Appointment'" icon="pi pi-save" label="Save"
            (click)="confirmAddEditDialog('add_appointment')" [disabled]="checkDisabled">
        </p-button>
        <p-button *ngIf="headerName=='Edit Appointment'" icon="pi pi-save" label="Save"
            (click)="confirmAddEditDialog('edit_appointment')" [disabled]="checkDisabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- ADD BLOCK -->
<p-dialog #blockDialog [header]="headerName" [(visible)]="displayBlockModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,blockDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <form role="form" #blockSlotForm="ngForm" style="min-width: 50vw;">
        <div class="row">
            <div class="form-group col-md-6">
                <label for="startTime">Appointment Start Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentStartDateTime" name="appointmentStartDateTime" inputId="startTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="Start date/time" [disabled]="slotDisabled"
                    (onClose)="onDateChange($event,'add_block_slots')">
                </p-calendar>

            </div>
            <div class="form-group col-md-6">
                <label for="endTime">Appointment End Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentEndDateTime" name="appointmentEndDateTime" inputId="endTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="End date/time" (onClose)="onDateChange($event,'add_block_slots')"
                    [disabled]="slotDisabled">
                </p-calendar>

            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Comments<span class="symbol required"></span></label>
                <textarea pInputTextarea type="text" name="blockNotes" class="form-control"
                    placeholder="Enter comments...." [(ngModel)]="blockComment" [disabled]="slotDisabled"></textarea>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button *ngIf="headerName=='Add Block Slots'" icon="pi pi-ban" label="Block" (click)="addBlockSlot()"
            [disabled]="checkDisabled">
        </p-button>
        <p-button *ngIf="headerName=='View Block Slots'" icon="pi pi-check-circle" label="Unblock"
            (click)="unlockSlot()" [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- CONFIRMATION MODAL -->
<p-confirmDialog #cd [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{'min-width':'45vw'}">
    <p-footer>
        <div class="p-d-flex p-ai-center p-pl-2" *ngIf="!isReminder">
            <p-checkbox [(ngModel)]="sendNotification" [binary]="true" inputId="binary"
                [disabled]="disabledSendNotification"></p-checkbox>
            <label for="binary" class="p-ml-3 p-mb-0">Send notification to customer.</label>
        </div>
        <div class="p-mb-3 p-mt-3">
            <p-messages *ngIf="twillioNumber == null" severity="warn">
                <ng-template pTemplate>
                    <div class="p-d-flex p-ai-center">
                        <div class="p-ml-2" style="font-size: 12px;">Unable to send notification. SMS number
                            provisioning is in progress</div>
                    </div>
                </ng-template>
            </p-messages>
        </div>
        <div *ngIf="false" class="p-mb-3 p-mt-3">
            <p-messages *ngIf="isUserNonchatstasy && sendNotification" severity="warn">
                <ng-template pTemplate>
                    <div class="p-d-flex p-ai-center">
                        <div class="p-ml-2" style="font-size: 12px;">1 Message Credit will be deducted</div>
                    </div>
                </ng-template>
            </p-messages>
        </div>
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-button-danger" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    </p-footer>
</p-confirmDialog>

<!-- TEST APPOINTMENT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="visibleTestAppointment" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,addDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>

    
    <p-table [value]="appointmentList"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
    [responsive]='true' [rowHover]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Select</th>
                <th>Appointment Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Scheduled On From</th>
                <th>Scheduled On To</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-list>
            <tr>
                <td>
                    <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" [disabled]="true"></p-checkbox>
                </td>
                <td>{{list.appointmentId}}</td>
                <td>{{list.clientData?.firstName}}</td>
                <td>{{list.clientData?.lastName}}</td>
                <td>{{list.scheduledOnFrom  | date: 'MM-dd-yyyy h:mm a'}}</td>
                <td>{{list.scheduledOnTo  | date: 'MM-dd-yyyy h:mm a'}}</td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-trash" label="Remove" class="p-button-danger"
            (click)="confirmAddEditDialog('add_appointment')" [disabled]="checkDisabled">
        </p-button> -->
        <button pButton type="button" label="Remove" class="p-button-danger" icon="pi pi-trash" (click)="removeTestAppointment()"></button>

    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- VIEW APPOINTMENT -->
<p-sidebar [(visible)]="visibleViewAppointment" position="right" [baseZIndex]="5000" [style]="{overflow:'auto'}"
    styleClass="different">
    <div *ngIf="!isLoading">
        <div class="p-p-2">
            <h5 class="sidebar_title" *ngIf="appointmentNumber != 'null'"><span>{{appointmentNumber}}</span>
            </h5>
            <ul class="sidebar_ul">
                <li class="list_sidebar">
                    <div class="sidebar_container" style="border-bottom: 1px solid #eee;">
                        <div class="sidebar_info mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-id-badge p-mr-2">
                                </i>
                                <span
                                    *ngIf="singleClientData.firstName != 'null'">{{singleClientData.firstName}}&nbsp;</span>
                                <span *ngIf="singleClientData.middleName != 'null'">{{singleClientData.middleName}}
                                    &nbsp;</span>
                                <span *ngIf="singleClientData.lastName != 'null'">{{singleClientData.lastName}}</span>
                            </span>
                        </div>
                        <div *ngIf="singleClientData.mobileNo != 'null'" class="sidebar_info mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-mobile p-mr-2">
                                </i>{{singleClientData.mobileNo}}</span>
                        </div>
                        <div *ngIf="singleClientData.email != 'null'" class="sidebar_info"><span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-email p-mr-2"></i><a href="javascript:void(0)"
                                    style="text-decoration: underline !important;">{{singleClientData.email}}</a></span>
                        </div>
                    </div>

                    <div *ngIf="appointmentStartDateTime != 'null'" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Scheduled On</h4>
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="pi pi-calendar p-mr-2"></i>{{appointmentStartDateTime | date:
                                'MM-dd-yyyy h:mm a'}} - {{appointmentEndDateTime | date:
                                'MM-dd-yyyy h:mm a'}}</span>
                        </div>
                    </div>
                    <!-- <div class="sidebar_container">
                        <div class="sidebar_info p-grid p-fluid" style="display: flex;">
                            <div class="p-col-6">
                                <span style="color: #919191;align-items: center;
                                display: flex;"><i class="pi pi-clock p-mr-2"></i>12:40</span>
                            </div>
                            <div class="p-col-6">
                                <span style="color: #919191;align-items: center;
                            display: flex;"><i class="pi pi-clock p-mr-2"></i>01:40</span>
                            </div>
                        </div>
                    </div> -->

                    <div *ngIf="serviceTypeName != 'null'" class="sidebar_container"
                        style="border-top: 1px solid #eee;">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Service Type</h4>
                            <span style="color: #919191">{{serviceTypeName}}</span>
                        </div>
                    </div>
                    <div *ngIf="serviceProviderName != 'null'" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Provided by</h4>
                            <span style="color: #919191">{{serviceProviderName}}</span>
                        </div>
                    </div>
                    <div *ngIf="specialInstruction != 'null' && specialInstruction != ''" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Special Instruction</h4>
                            <span style="color: #919191">{{specialInstruction}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>


        <div class="p-p-2" style="border-top: 1px solid #eee;width:100%;">
            <button pButton pRipple icon="pi pi-bell" label="Send Reminder"
                class="p-button-raised p-button-success p-button-sm" (click)="showHideModalDialog('send_reminder')"
                style="font-size: 12px;margin-left:0.5rem" [disabled]="view_disabled || bussinesInfo.state=='offline'">
            </button>
            <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple icon="pi pi-pencil" label="Edit"
                class="p-button-raised p-button-sm" style="font-size: 12px;margin-left:0.5rem"
                (click)="showHideModalDialog('edit_appointment')" [disabled]="view_disabled">
            </button>
            <button pButton pRipple icon="pi pi-trash" (click)="showHideModalDialog('cancel_appointment')"
                label="Cancel Appointment" class="p-button-danger p-button-text"
                style="font-size: 12px;margin-left:0.5rem" [disabled]="view_disabled || bussinesInfo.state=='offline'">
            </button>


        </div>
    </div>
    <div *ngIf="isLoading" class="p-p-2 p-mr-2">
        <div style="margin: 15px 10px 10px 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'90px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'79px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'104px'"></ngx-shimmer-loading>
        </div>
        <div class="p-grid p-fluid" style="padding: 10px;">
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'49px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'81px'"></ngx-shimmer-loading>
        </div>
    </div>
</p-sidebar>
<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>