import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppSettings } from 'src/app/Constant/AppSettings';
import { Thread } from 'src/app/models/thread.model';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { ClientcommunicationService } from 'src/app/services/clientcommunication/clientcommunication.service';
import firebase from 'firebase';
import { map, take } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { Room } from 'src/app/models/room.model';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
declare var CKEDITOR: any;

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.css'],
  animations: [
    trigger('animation', [
      state('visible', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('void => *', [
        style({ transform: 'translateX(50%)', opacity: 0 }),
        animate('300ms ease-out')
      ]),
      transition('* => void', [
        animate(('250ms ease-in'), style({
          height: 0,
          opacity: 0,
          transform: 'translateX(50%)'
        }))
      ])
    ])
  ]
})
export class BroadcastMessageComponent implements OnInit {
  broadcastList: any[] = [];
  clients: any[] = [];
  isLoading: boolean = false;
  disabled: boolean = false
  searchText = '';
  msgText: string = '';
  count = 0
  nonChatstasyfilterList: any[] = []
  chatstasyfilterList: any[] = []
  selectAll: boolean = false;
  //
  bussinesInfo: any = {};
  interval: any;
  arrayVariables: any[] = [''];
  arrayActionButton: any[] = [''];
  displayToolbar: boolean = true;
  clientSegmentationList: any[] = [];
  filterToggle: boolean = false;
  broadcastLoading: boolean = false;
  selectedclientSegmentation: string[] = [];
  orgClients: any[] = [];
  segmentSelectAll: boolean = false;

  constructor(
    private clientcommunicationService: ClientcommunicationService,
    private clientDataService: ClientDataService,
    private messageService: MessageService,
    private clientsegmentationservice: ClientSegmentationService,
  ) { }

  ngOnInit(): void {
    this.listClient();
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
    this.getClientSegmentationList();
  }


  ngAfterViewInit() {
    this.interval = setInterval(() => {
      var checkElement = document.getElementById("broadcastEditor");
      if (this.arrayVariables.length > 0 && checkElement != null) {
        clearInterval(this.interval);

        CKEDITOR.config.strinsert_strings = this.arrayVariables;
        CKEDITOR.config.strinsert_strings_two = this.arrayActionButton;

        CKEDITOR.replace('broadcastEditor', {
          toolbar: [
            { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-',] },
            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
            { name: 'insert', items: ['Smiley'] },
            { name: 'links', items: [ 'Link', 'Unlink'] },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            { name: 'tools', items: ['Maximize', 'my-combo'] },
          ],
          height : "70px",
          removePlugins : 'elementspath',
	        resize_enabled  : false,
          on: {
            pluginsLoaded: function () {
              var editor: any = this,
                config = editor.config;
              config.resize_maxHeight = 300;
              editor.ui.addRichCombo('my-combo', {
                label: 'Action Buttons / Variables',
                title: 'Action Buttons / Variables',
                toolbar: 'basicstyles,0',

                panel: {
                  css: [CKEDITOR.skin.getPath('broadcastEditor')].concat(config.contentsCss),
                  multiSelect: false,
                  attributes: { 'aria-label': 'My Dropdown Title' }
                },

                init: function () {
                  let selectVariable = CKEDITOR.config.strinsert_strings_two;
                  let selectActionButton = CKEDITOR.config.strinsert_strings;

                  this.startGroup('Action Buttons');
                  selectVariable.forEach((elemet: any) => {
                    this.add(elemet.rawHtmlLink, elemet.name);
                  });

                  this.startGroup('Variables');
                  selectActionButton.forEach((elemet: any) => {
                    this.add(elemet, elemet);
                  });

                },

                onClick: function (value: any) {
                  editor.focus();
                  editor.fire('saveSnapshot');

                  editor.insertHtml(value);

                  editor.fire('saveSnapshot');
                }
              });
            }
          },
        });
        let checkId = setInterval(() => {
          var toolbar: any = document.getElementsByClassName("cke_reset_all");
          if (toolbar[1] != null) {
            clearInterval(checkId);
            var toolbar: any = document.getElementsByClassName("cke_reset_all");
            toolbar[1].style.display = "none";
          }
        }, 100);
      }
    }, 1000);
  }

  prepareMessageBeforeSending() {
    this.showProgressBar();
    this.msgText = CKEDITOR.instances.broadcastEditor.getData();

    if (this.broadcastList.length == 0 || this.broadcastList == undefined) {
      this.showErrorToastMsg('Please select client.')
      this.hideProgressBar();
      return;
    } if (this.msgText.toString().trim() == '' || this.msgText == null) {
      this.showErrorToastMsg('Please type message before sending.')
      this.hideProgressBar();
      return;
    }
    this.nonChatstasyfilterList = [];
    this.chatstasyfilterList = [];
    this.nonChatstasyfilterList = this.filterUserType('nonchatstasy', this.broadcastList)
    this.chatstasyfilterList = this.filterUserTypeForNon('chatstasy', this.broadcastList)
    console.log('Non:' + this.nonChatstasyfilterList, 'Chat:' + this.chatstasyfilterList)

    if (this.chatstasyfilterList.length != 0) {
      this.sendBulkMessage(this.chatstasyfilterList)
    }
    if (this.nonChatstasyfilterList.length != 0) {
      this.sendBulkSMS(this.nonChatstasyfilterList)
    }
  }

  sendBulkMessage(filterList: any) {
    filterList.forEach((element: any) => {
      this.showProgressBar();
      // console.log(element)
      this.checkRoom(element)
    });
  }


  checkRoom(client: any): void {
    this.clientcommunicationService.checkRoomExist(client.id.toString()).snapshotChanges().pipe(take(1))
      .pipe(
        map((changes: any) =>
          changes.map((c: any) =>
            ({ id: c.payload.doc.id, ...c.payload.doc.data() })
          )
        )
      ).subscribe((response: any) => {
        console.log(response);
        if (response.length > 0) {
          console.log("Sorry, room already present");
          this.addMsgAndUpdateRoom(response[0].id, client);
        } else {
          console.log("Does not exist.");
          this.createChatRoom(client);
        }
      });
  }


  createChatRoom(client: any) {
    let room: Room = {}
    room.business_id = CommonFunctions.getUserId().toString()  //company id
    room.business_logo = "";
    room.business_name = "Test ACME Industries";

    room.recepient_id = client.id.toString();
    room.recepient_name = client.firstName + ' ' + client.middleName + ' ' + client.lastName;
    room.recepient_image = "";
    room.last_message = "";
    room.preference_type = client.userType;
    room.timestamp = firebase.firestore.FieldValue.serverTimestamp()
    this.clientcommunicationService.createRoom(room).then((response: any) => {
      console.log(response.id)
      this.addMsgAndUpdateRoom(response.id, client);
    });
  }

  addMsgAndUpdateRoom(path: string, client: any) {

    let thread: Thread = {}
    thread.message_channel_type = "chatstasy";
    thread.recepient_id = client.id.toString();

    thread.sender_id = CommonFunctions.getBusinessInfoId().toString();
    thread.sender_image = "";
    thread.sender_name = CommonFunctions.getUser().firstName + ' ' + CommonFunctions.getUser().lastName;
    thread.sender_type = "Business";
    thread.text = this.msgText;
    thread.attachments = [];
    thread.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    console.log(thread)
    this.clientcommunicationService.addMeassage(path, thread).then(() => {
      console.log('Created new item successfully!');
      this.clientcommunicationService.updateRoom(path, thread.text, thread.timestamp, 'read').then(() => {
        if (this.chatstasyfilterList.length == this.count) {
          this.count = 0
          console.log('Updated room successfully!');
          this.showSuccessMessage('SUCCESS', 'Bulk messages sent successfully.')
          this.hideProgressBar()
        }
      }).catch((error) => {
        console.log('Error: Getting document:', error.message)
      });
      this.count = this.count + 1;
    });
  }

  sendBulkSMS(filterList: any) {
    this.showProgressBar();
    const data = { data: { message: this.msgText } }
    console.log('Data:' + JSON.stringify(data));
    this.clientcommunicationService.sendBulkSMS(data, filterList).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Send Bulk SMS Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  listClient() {
    this.showProgressBar();
    let type = null;
    if(this.bussinesInfo.state == "offline"){
      type = "test";
    }
    // this.clientDataService.getAllClientList(type).subscribe(
    this.clientDataService.getAllSubscribedClientList(type).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Boradcast Get Client List Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clients = json.data;
          this.orgClients = json.data;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  filterUserType(nameKey: string, myArray: any) {
    var filteredArray = myArray.filter(function (obj: any) {
      return obj.userType === nameKey;
    }).map(function (obj: any) {
      return obj.id;
    });
    return filteredArray;
  }

  filterUserTypeForNon(nameKey: string, myArray: any) {
    var filteredArray = myArray.filter(function (obj: any) {
      return obj.userType === nameKey;
    }).map(function (obj: any) {
      return obj;
    });
    return filteredArray;
  }

  addToBroadcastList(client: any) {
    const visitorAlreadyInArray = this.broadcastList.some(obj => this.objectsAreEqual(obj, client))
    if (!visitorAlreadyInArray) {
      this.broadcastList.push(client);
    }
    this.checkLists()
  }

  removeToBroadcastList(client: any) {
    this.broadcastList.splice(this.broadcastList.findIndex(a => a.id === client.id), 1)
    this.checkLists()
  }

  objectsAreEqual(obj1: any, obj2: any) {
    return obj1.id === obj2.id
  }

  checkLists() {
    if (this.broadcastList.length < this.clients.length) {
      this.selectAll = false
    } else {
      this.selectAll = true
    }
  }

  selectOrDeselectAll(event: any) {
    if (event.checked) {
      this.broadcastList = [];
      this.clients.forEach(element => {
        this.broadcastList.push(element);
      });
    } else {
      this.broadcastList = [];
    }
  }

  segmentSelectOrDeselectAll(event: any){
    if (event.checked) {
      let ary: any[] = [];
      this.clientSegmentationList.forEach(element => {
        ary.push(""+element.id+"");
      });
      this.selectedclientSegmentation = ary;
    }else{
      this.selectedclientSegmentation = [];
    }
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.clear('br');
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.clear('br');
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showToolBar() {
    if (this.displayToolbar) {
      var toolbar: any = document.getElementsByClassName("cke_reset_all");
      toolbar[1].style.display = "block";
    } else {
      var toolbar: any = document.getElementsByClassName("cke_reset_all");
      toolbar[1].style.display = "none";
    }

    var findClass:any = document.getElementsByClassName("cke_combo_inlinelabel");
    findClass[findClass.length-1].style.width = '140px';
    this.displayToolbar = !this.displayToolbar;
  }


  getClientSegmentationList() {
    this.showProgressBar();
    this.clientsegmentationservice.getClientSegmentationList().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Client Segmentation Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.clientSegmentationList = json.data;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  handleFilter(){
    this.broadcastLoading = true;
    setTimeout(() => {
      this.broadcastLoading = false;
      this.searchText = "";
      this.filterToggle =! this.filterToggle;
    }, 250);
  }

  cancelFilter(){
    this.segmentSelectAll = false;
    this.clients = this.orgClients;
    this.selectedclientSegmentation = [];
    this.filterToggle =! this.filterToggle;
  }

  filterClientBySegment(){
    if(this.selectedclientSegmentation.length > 0){
      this.clients = CommonFunctions.filterClientSegmentBySegmentId(this.orgClients, this.selectedclientSegmentation);
    }else{
      this.clients = this.orgClients;
    }
    
    this.filterToggle =! this.filterToggle;
  }
}
