export class Room {
    id?: string;
    business_id?: string;
    business_name?: string;
    business_logo?: string;
    recepient_id?: string;
    recepient_name?: string;
    last_message?: string;
    recepient_image?: string;
    timestamp?: any;
    preference_type?: string;
    read_status?: string;
    is_user_online?: boolean;
    is_typing?: boolean;
    is_subscribed?: boolean;
}
