<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Configure Business Holidays
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <p-tabView dynamic="true" (onChange)="customGraphicTypeChangeEvent($event)" [activeIndex]="activeTab" class="center-tabview">
                    <p-tabPanel header="Business Hours" [headerStyle]="{'width': '50%'}">
                        <div class="p-fluid p-formgrid p-grid p-pl-2">
                            <div class="p-col">
                                <div class="p-grid">
                                    <div class="p-col p-lg-2 p-p-2">
                                        <label class="p-ml-4">Day</label>
                                    </div>
                                    <label class="p-col p-lg-3 p-p-2">Start Time</label>
                                    <label class="p-col p-lg-3 p-p-2">End Time</label>
                                    <label class="p-col p-lg-4 p-p-2">Off Hours</label>
                                </div>
                                <div class="p-grid p-ai-center" *ngFor="let day of businessHoursList">
                                    <div class="p-col-3 p-lg-2 p-d-flex p-ai-center p-p-2">
                                        <p-checkbox name="groupname" [binary]="true" [(ngModel)]="day.isActive">
                                        </p-checkbox>
                                        <label class="p-ml-2 p-mb-0">{{day.name}}</label>
                                    </div>
        
                                    <p-dropdown class="p-col-12 p-lg-3 p-p-2" [options]="timeList" [(ngModel)]="day.startTime"
                                        optionLabel="name" (onChange)="onActionChange($event,day.name)"></p-dropdown>
                                    <p-dropdown class="p-col-12 p-lg-3 p-p-2" [options]="timeList" [(ngModel)]="day.endTime"
                                        optionLabel="name" (onChange)="onActionChange($event,day.name)"></p-dropdown>
                                    <p-multiSelect class="p-col-12 p-lg-4 p-p-2" [filter]="false"
                                        [(ngModel)]="day.selectedOffHour" [options]="day.possibleTimeslots" optionLabel="name"
                                        defaultLabel="Select off hours">
                                    </p-multiSelect>
                                </div>
                            </div>
        
                        </div>
                        <ng-template pTemplate="footer">
                            <p-progressSpinner *ngIf="isLoading" styleClass="p-mr-4" [style]="{ width: '35px', height: '35px' }"
                                strokeWidth="3">
                            </p-progressSpinner>
                            <button pButton pRipple label="Save Changes" class="p-button-raised"
                                [style]="{'margin-left': '.5em'}" (click)="editBusinessHoursList()"></button>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel header="Business Holidays" [headerStyle]="{'width': '50%'}">
                        <div class="row form-group">
                            <div class="col-sm-8">
                                <p-table [value]="businessHolidayFilter" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true' [responsive]='true' [rowHover]="true" [lazy]="true" [loading]="isLoading">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 10%;">Select</th>
                                            <th style="width: 45%;">Name</th>
                                            <th style="width: 45%;">Date</th>
                                            <th style="width: 45%;">Action</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-holiday>
                                        <tr>
                                            <td>
                                                <p-checkbox name="selection" value="{{holiday.id}}" [(ngModel)]="selectedHolidaysValues" (onChange)="onCheckboxChange($event, holiday.id)" [disabled] = holiday.isEnabled></p-checkbox>
                                                
                                            </td>
                                            <td>{{holiday.name}}</td>
                                            <td>{{holiday.date | date:'MM-dd-yyyy'}}</td>
                                            <td style="text-align: center;">
                                                <a href="javascript:void(0);" (click)="confirmDeleteDialog(holiday.id)" [ngClass]="{'disabledClass': !holiday.isEnabled, '': holiday.isEnabled}"> <i class="pi pi-trash" [ngStyle]="{'color': !holiday.isEnabled ? '#dddddd' : '#007AFF'}" style="font-size: 0.8rem;color:#007AFF"></i></a>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>                        
                            </div>
        
                            <div class="col-sm-4">

                                <div class="row" style=" margin-top: 7rem; ">
                                    <div class="col-sm-12 d-flex justify-content-center align-items-center">
                                        <button pButton pRipple type="button" label="Add New Holiday" class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;"
                                            (click)="showHideModalDialog('add_holiday')"></button>
                                    </div>
                                </div>

                                <div class="row" style=" margin-top: 7rem; ">
                                    <div class="col-sm-6">                                
                                         <p-dropdown [options]="uniqueYears" optionLabel="year" [(ngModel)]="selectedYear" (onChange)="filterDatesByYear(businessHolidayList, selectedYear.year)"></p-dropdown>
                                    </div>
                                    <div class="col-sm-6">
                                        <p-button label="SAVE" icon="pi pi-eye" iconPos="right" (click)="confimSaveUnCheckData()"></p-button>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </p-tabPanel>
                </p-tabView>
                
                <!-- <div class="p-fluid p-formgrid p-grid p-pl-2">
                    <div class="p-col">
                        <div class="p-grid">
                            <div class="p-col p-lg-2 p-p-2">
                                <label class="p-ml-4">Day</label>
                            </div>
                            <label class="p-col p-lg-3 p-p-2">Start Time</label>
                            <label class="p-col p-lg-3 p-p-2">End Time</label>
                            <label class="p-col p-lg-4 p-p-2">Off Hours</label>
                        </div>
                        <div class="p-grid p-ai-center" *ngFor="let day of businessHoursList">
                            <div class="p-col-3 p-lg-2 p-d-flex p-ai-center p-p-2">
                                <p-checkbox name="groupname" [binary]="true" [(ngModel)]="day.isActive">
                                </p-checkbox>
                                <label class="p-ml-2 p-mb-0">{{day.name}}</label>
                            </div>

                            <p-dropdown class="p-col-12 p-lg-3 p-p-2" [options]="timeList" [(ngModel)]="day.startTime"
                                optionLabel="name" (onChange)="onActionChange($event,day.name)"></p-dropdown>
                            <p-dropdown class="p-col-12 p-lg-3 p-p-2" [options]="timeList" [(ngModel)]="day.endTime"
                                optionLabel="name" (onChange)="onActionChange($event,day.name)"></p-dropdown>
                            <p-multiSelect class="p-col-12 p-lg-4 p-p-2" [filter]="false"
                                [(ngModel)]="day.selectedOffHour" [options]="day.possibleTimeslots" optionLabel="name"
                                defaultLabel="Select off hours">
                            </p-multiSelect>
                        </div>
                    </div>

                </div>
                <ng-template pTemplate="footer">
                    <p-progressSpinner *ngIf="isLoading" styleClass="p-mr-4" [style]="{ width: '35px', height: '35px' }"
                        strokeWidth="3">
                    </p-progressSpinner>
                    <button pButton pRipple label="Save Changes" class="p-button-raised"
                        [style]="{'margin-left': '.5em'}" (click)="editBusinessHoursList()"></button>
                </ng-template> -->
            </p-card>
        </div>
        <!-- <div class="p-mt-4">
                    <p-card header="Configure Business Timezone">
                        <div class="p-fluid">
                            <div class="p-field p-grid">
                                <label for="firstname" class="p-col-12 p-md-2 p-pl-4">Timezone</label>
                                <div class="p-col-12 p-md-10">
                                    <p-dropdown class="p-col-12 p-lg-3" [options]="startTimeList" optionLabel="name"
                                        placeholder="Select a timezone" (onChange)="onActionChange($event,'timezone')">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>

                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="Save Changes" class="p-button-raised"
                                [style]="{'margin-left': '.5em'}"></button>
                        </ng-template>
                    </p-card>
                </div> -->
    </p-scrollPanel>
</div>


<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>

<!-- REMOVE MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>

<!-- ADD HOLIDAY MODAL -->
<p-dialog #addDialog header="Add Holiday" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
[draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
(onShow)="showDialogMaximized($event,addDialog)">
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form role="form" style="min-width: 60vw;" #addHolidayForm="ngForm">
    <div class="row">
      <div class="form-group col-md-6">
        <label>Holiday Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="holidayName" placeholder="Enter Holiday Name"
          [(ngModel)]="holidayName" (keypress)="keyPressAlphaNumericWithCharacters($event)" required autocomplete="off" />
      </div>
      <div class="form-group col-md-6">
        <label>Date<span class="symbol required"></span></label>
        <p-calendar [(ngModel)]="holidayDate" name="holidayDate" inputId="startTime"
                    hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="Start date" (onClose)="onDateChange($event,'add_appointment')" [disabledDates]="invalidDates"></p-calendar>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayAddEditModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-plus" (click)="addHoliday()" label="Add" [disabled]="disabled"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>