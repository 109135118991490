import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessInfoService {

  constructor(
    private http: HttpClient
  ) { }

  getBusinessInfo(business_id: string) {
    const url = environment.API_URL + '/api/business/' + business_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  editBusinessInfo(business_id: string, profile: any) {
    const url = environment.API_URL + '/api/business/' + business_id + '/update';
    console.log('Business Info edit Service Data ---> ' + JSON.stringify(profile));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, profile, httpOptions).pipe(timeout(60000));
  }

  getBusinessShortName(business_id: string) {
    const url = environment.API_URL + '/api/business/' + business_id + '/shortName';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  editBusinessShortName(business_id: string, shortName: any) {
    const url = environment.API_URL + '/api/business/' + business_id + '/shortName/update?shortName=' + shortName;
    // console.log('Business Info edit Service Data ---> ' + JSON.stringify(profile));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  uploadBusinessLogo(business_id: string, file: any) {
    const url = environment.API_URL + '/api/business/' + business_id + '/upload';
    // console.log('Profile Service Data ---> ' + JSON.stringify(file));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
      }),
    };
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  deleteBusinessLogo() {
    const url = environment.API_URL + '/api/business/deleteImage';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  updateState(business_id: string, state: string, data:any) {
    const url = environment.API_URL + '/api/business/' + business_id + '/updateState';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'state': state.toString() + '',
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }


  getBusinessType() {
    const url = environment.API_URL + '/api/business_type/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getTimezone() {
    const url = environment.API_URL + '/api/businessHour/timezones';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  downloadProfileImage() {
    const url =
      environment.API_URL + '/api/anonymous/cdn/1612584877555_1612584877554_ajna.jpg';
    const TOKEN = CommonFunctions.getToken();

    return this.http.get(url).pipe(timeout(60000));
  }



  getCountries() {
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
      }),
    };
    const url = environment.API_URL + '/api/global/country';
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getConfigurationWizard() {
    const url = environment.API_URL + '/api/configurationwizard';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  updateBusinessConfigurationWizard(business_id:any, data:any){
    const url = environment.API_URL + '/api/business/' + business_id + '/updateconfigurationupdate';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json'
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }

  getAdminLoginBusinessList(){
    const url = environment.API_URL + '/api/admin-login/list';
    const TOKEN = CommonFunctions.getToken();

    const businessId = CommonFunctions.getBusinessId();
    // Check if businessId is not undefined before calling toString()
    const businessIdString = businessId !== undefined ? businessId.toString() : '';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': businessIdString
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  checkTwilioConfig(){
    const url = environment.API_URL + '/api/anonymous/business/TwilioAddressUpdate';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  assignTwilioConfig(){
    const url = environment.API_URL + '/api/anonymous/business/TwilioAddressCheck';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  updateOperatingStatus(business_id: string, state: string) {
    const url = environment.API_URL + '/api/business/' + business_id + '/updateOperatingStatus?status='+state;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'state': state.toString() + '',
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


}
