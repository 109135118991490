import { LocationStrategy } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { map, take} from 'rxjs/operators';
import { AuthenticationService } from './services/authentication/authentication.service';
import { FirbaseMessagingService } from './services/firbase-messaging/firbase-messaging.service';
import { SubscriptionAndPaymentsService } from './services/subscription-and-payments/subscription-and-payments.service';
import { UtilsService } from './services/utils/utils.service';
import { CommonFunctions } from './Utilities/CommonFunctions';
import { Room } from './models/room.model';
import firebase from 'firebase';
import { ClientcommunicationService } from './services/clientcommunication/clientcommunication.service';
import { UserService } from './services/user/user.service';
import { AppSettings } from './Constant/AppSettings';
import { AppointmentService } from './services/appointment/appointment.service';
import { PrivilegeService } from './services/privilege/privilege.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService],
})
export class AppComponent {
  title = 'Chatstasy';
  message: any;
  isVisible: boolean = false;
  isLoading: boolean = false;
  isAuthenticated = false;
  messages: any[] = []
  notifications: any[] = [];
  interval: any;
  notificationObjectList: any[] = [];
  businessUrl: string = '';
  menuSidebar: boolean = false;
  showMasterLayout: boolean = false;
  displayModalDisable: boolean = false;
  subscriptionNotify: any;
  checkRedirect: string = '';
  unReadNotification: boolean = false;
  showOptions: boolean = false;
  singleClientData: any = {};
  bussinesInfo: any = {};
  visibleViewAppointment: boolean = false;
  view_disabled: boolean = false;
  appointmentNumber: any = '';
  appointmentStartDateTime: any = '';
  appointmentEndDateTime: any = '';
  serviceTypeName: string = '';
  serviceProviderName: string = '';
  specialInstruction = '';
  disabled: boolean = false;
  beforeClosingToggleStatus: boolean = false; 

  constructor(
    public authenticationService: AuthenticationService,
    private locationStrategy: LocationStrategy,
    private utilsService: UtilsService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private messagingService: FirbaseMessagingService,
    private subscriptionAndPaymentsService: SubscriptionAndPaymentsService,
    private confirmationService: ConfirmationService,
    private appointmentService: AppointmentService,
    private privilegeService: PrivilegeService,
  ) { }

  ngOnInit() {
    this.businessUrl = CommonFunctions.replaceAll(this.locationStrategy.getBaseHref(), '/', '')
    console.log('base url:', this.businessUrl);
    if (this.businessUrl != '' && this.businessUrl != 'login' && this.businessUrl != 'registration' && this.businessUrl != "apt") {
      this.getBusinessInfoByShortname()
    }
    this.messagingService.notificationCount.subscribe(
      (payload) => {
        var newPayload = JSON.parse(JSON.stringify(payload))
        // console.log('testing payload:' + JSON.stringify(payload))
        // this.showSuccessToastMsg(newPayload?.notification?.title, newPayload?.notification?.body)
      }
    )
    this.utilsService.isVisibleSidebar.subscribe(
      (isVisible) => {
        this.beforeClosingToggleStatus = this.unReadNotification;
        this.unReadNotification = false;
        this.retrieveNotificationMessages();
        this.isVisible = isVisible;
      }
    )
    this.utilsService.menuState.subscribe(
      (isVisible) => {
        this.menuSidebar = isVisible;
      }
    )
    this.utilsService.isLogin.subscribe(
      (isLogin) => {
        if (isLogin) {
          this.getMessageCreditCount()
          this.interval = setInterval(() => {
            this.getMessageCreditCount()
          }, 5000);
          this.messagingService.setDbRef()
          this.retrieveUnreadNotification()
          this.retrieveNotificationMessages()
          this.retrieveMessages()
        } else {
          if (this.interval) {
            clearInterval(this.interval);
          }
        }
      }
    )
    if (this.authenticationService.isLoggedIn()) {
      this.getMessageCreditCount()
      this.interval = setInterval(() => {
        this.getMessageCreditCount()
      }, 5000);
      this.retrieveUnreadNotification()
      this.retrieveNotificationMessages()
      this.retrieveMessages()
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
  }

  ngAfterContentChecked() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // console.log('event:', event)
        var exist = this.router.config.some(obj => (obj.path) == event['url'].split('/')[1])
        // if (event['url'].split('/')[1] != "/support-chat") {
        // }
        if (!exist) {
          this.router.navigate(['/home']);
        }

        if (event['url'] == '/' || event['url'] == '/login' || event['url'] == '/logout' || event['url'] == '/registration' || event['url'] == '/forgot-password' || event['url'] == '/page-not-found' || event['url'].toLocaleLowerCase() == '/registration/free' || event['url'].toLocaleLowerCase() == '/registration/basic' || event['url'].toLocaleLowerCase() == '/registration/standard' || event['url'].toLocaleLowerCase() == '/registration/free-monthly' || event['url'].toLocaleLowerCase() == '/registration/free-annual' || event['url'].toLocaleLowerCase() == '/registration/basic-monthly' || event['url'].toLocaleLowerCase() == '/registration/basic-yearly' || event['url'].toLocaleLowerCase() == '/registration/standard-monthly' || event['url'].toLocaleLowerCase() == '/registration/standard-yearly' || event['url'].toLocaleLowerCase() == '/registration/enterprise-monthly' || event['url'].toLocaleLowerCase() == '/registration/enterprise-annual' || event['url'].toLocaleLowerCase().indexOf('/service-gallery') >= 0 || event['url'].toLocaleLowerCase().indexOf('/dts') >= 0) {
          this.showMasterLayout = false;
          if(event['url'].toLocaleLowerCase().indexOf('/service-gallery') >= 0){
            this.checkRedirect = event['url'].toLocaleLowerCase();
          }

          if(event['url'].toLocaleLowerCase().indexOf('/business-calendar-action') >= 0) {
            this.checkRedirect = event['url'].toLocaleLowerCase();
          }
          if(event['url'].toLocaleLowerCase().indexOf('/dts') >= 0){
            this.checkRedirect = event['url'].toLocaleLowerCase();
          }
        } else {
          this.showMasterLayout = true;
        }
      }
    });
    this.cdref.detectChanges();
  }

  getBusinessInfoByShortname() {
    this.authenticationService.getBusinessInfoByShortname(this.businessUrl).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Business Info Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data)
            if (json.data.id != CommonFunctions.getUser().businessId) {
              this.router.navigate(['/login']);
              if(this.checkRedirect != ''){
                this.router.navigate([this.checkRedirect]);
              }
            } else {
              if (this.router.url == '/page-not-found') {
                this.router.navigate(['/login']);
              }
            }
            // this.disabled = false;
          } else {
            this.router.navigate(['/page-not-found']);
            // this.disabled = true;
          }
        }
      },
      (error) => {
        console.log('There was an error');
        // this.router.navigate(['/page-not-found']);
        console.log(error);
      }
    );
  }

  getMessageCreditCount() {
    // this.showProgressBar()
    this.subscriptionAndPaymentsService.messageCreditCount().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log("Message Credit Count Response --->> " + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.utilsService.setCreditCount(json.data.currentCreditAmount)
          // this.messageCreditCount = json.data.currentCreditAmount;
        } else {
          // this.showErrorMessage(json.response.displayMessage)
        }
        // this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        // this.hideProgressBar();
      }
    );
  }

  retrieveNotificationMessages(): void {
    this.subscriptionNotify = this.messagingService.getAllUnreadNotifications().snapshotChanges().pipe(
      map((changes: any) =>
        changes.map((c: any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      ),
      map((notifications: any[]) => 
        notifications.sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp descending
      )
    ).subscribe((data: any) => {
      this.notifications = data;
      console.log(this.notifications)
      data.forEach((element: any) => {
        if (element.notificationType == 'SMS_NO_ALLOCATED') {
          this.utilsService.setTwillioBanner(element)
        }
        if (element.readStatus == 'unread' && element.notificationType == 'DEACTIVATION') {
          console.log('urlllll', this.router.url)
          if (this.router.url != '/login' && this.router.url != '/logout') {
            this.displayModalDisable = true;
          }
        }
      });

    }, (error) => {
      console.log("Error:" + error)
    });
  }

  retrieveUnreadNotification(): void {
    this.messagingService.getAllUnreadNotifications().snapshotChanges().pipe(
      map((changes: any) =>
        changes.map((c: any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      ),
      map((notifications: any[]) => 
        notifications.sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp descending
      )
    ).subscribe((data: any) => {
      this.messagingService.notificationCount.next(data.length.toString())
    }, (error) => {
      console.log("Error:" + error)
    });
  }

  retrieveMessages(): void {
    this.messagingService.getAllUnreadRooms().snapshotChanges().pipe(
      map((changes: any) =>
        changes.map((c: any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe((data: any) => {
      this.messages = data;
      this.messagingService.messageCount.next(this.messages.length.toString())
      // console.log(data)
    },
      (error) => {
        console.log("Error:" + error)
      });
  }

  onNotificationClick(notification: any) {
    let notificationData = { id: notification.referenceId };
    console.log(notification.notificationType.toUpperCase());
    switch (notification.notificationType.toUpperCase()) {
      case 'APPOINTMENT':
        this.getAppointment(notificationData);

        // if (this.router.url == '/business-calendar') {
        //   this.utilsService.onClickNotification(notificationData)
        // }
        // this.router.navigateByUrl('/business-calendar', {
        //   state: {
        //     notification: { id: notification.referenceId }
        //   }
        // })
        break;
      case 'SMS_NO_ALLOCATED':
        if (this.router.url == '/business-information') {
          this.utilsService.onClickNotification(notificationData)
        }
        this.router.navigateByUrl('/business-information')
        break;
      case 'CHAT_MESSAGE_SUPPORT':
        if (this.router.url.split('/')[1] != "support-chat") {
          this.router.navigateByUrl('/support-chat')
        }
        console.log(this.router.url.split('/')[1])
        break;
        case 'FIREBASE_MESSAGE':
        // if (this.router.url == '/client-communication/room/'+notificationData) {
        //   console.log("Sds");
        //   this.utilsService.onClickNotification(notificationData)
        // }
        this.router.navigateByUrl('/client-communication/room/'+notificationData);
        console.log(notificationData);
        break;
    }
    if (notification.readStatus == 'unread') {
      this.setNotificationRead(notification.id)
    }
    this.setIsVisibleSidebar(false)
  }

  setNotificationRead(path: any) {
    var typeObject: any = {
      readStatus: 'read'
    }
    this.messagingService.updateNotificationRead(path, typeObject).then(() => {
    });
  }

  showSuccessToastMsg(summary: any, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: summary,
      detail: msg,
    });
  }

  logout() {
    this.displayModalDisable = false;
    this.notifications.forEach((element: any) => {
      if (element.readStatus == 'unread' && element.notificationType == 'DEACTIVATION') {
        this.setNotificationRead(element.id)
      }
    });
    this.router.navigate(['/logout']);
    if (this.subscriptionNotify) {
      this.subscriptionNotify.unsubscribe();
    }
  }

  closeMenu() {
    this.utilsService.changeMobileMenuState(false);
  }

  setIsVisibleSidebar(isVisible: boolean) {
    this.utilsService.setIsVisibleSidebar(isVisible);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.subscriptionNotify) {
      this.subscriptionNotify.unsubscribe();
    }
  }

  notificationHandleChange(event:any){
    console.log(this.unReadNotification);
    if(event.checked){
      this.retrieveReadNotificationMessages();
    }else{
      this.retrieveNotificationMessages();
    }
  }

  retrieveReadNotificationMessages(): void {
    this.subscriptionNotify = this.messagingService.getAllReadNotifications().snapshotChanges().pipe(
      map((changes: any) =>
        changes.map((c: any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      ),
      map((notifications: any[]) => 
        notifications.sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp descending
      )
    ).subscribe((data: any) => {
      this.notifications = data;
      console.log(this.notifications)
      data.forEach((element: any) => {
        if (element.notificationType == 'SMS_NO_ALLOCATED') {
          this.utilsService.setTwillioBanner(element)
        }
        if (element.readStatus == 'unread' && element.notificationType == 'DEACTIVATION') {
          console.log('urlllll', this.router.url)
          if (this.router.url != '/login' && this.router.url != '/logout') {
            this.displayModalDisable = true;
          }
        }
      });

    }, (error) => {
      console.log("Error:" + error)
    });
  }

  showNotificationOptions(){
    this.showOptions = !this.showOptions;
  }

  onDeleteClick(Id: any) {
    console.log('Delete clicked for:', Id);
    this.messagingService.removeNotification(Id).then(
      (payload) => {
        console.log('testing payload:' + JSON.stringify(payload));
        this.showSuccessToastMsg(null, "Notification successfully removed.");
      }, (error) => {
        console.log("Error:" + error)
      });
  }

  removeNotificationConfirmDialog(msg: any, event: MouseEvent): void {
    event.stopPropagation(); // Prevents onNotificationClick from being triggered
    // Add delete logic here
    this.messageService.clear();
    this.confirmationService.confirm({
      key: "deleteConfirmDialog",
      message: 'Do you want to delete this notification?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.onDeleteClick(msg.id);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event): void {
    // Close the dropdown if clicked outside
    const target = event.target as HTMLElement;
    if (!target.closest('#myDropdown') && !target.closest('.pi-ellipsis-v')) {
        this.showOptions = false;
    }
  }

  removeAllNotificationConfirmDialog(event: MouseEvent, type:string): void {
    event.stopPropagation(); // Prevents onNotificationClick from being triggered
    // Add delete logic here
    this.messageService.clear();
    this.confirmationService.confirm({
      key: "deleteConfirmDialog",
      message: 'Do you want to delete all '+type+' notification?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.DeleteAllNotificationClick(type);
      }
    });
  }

  DeleteAllNotificationClick(type: any) {
    console.log('Delete clicked for:', type);
    this.messagingService.deleteAllNotificationsByType(type);
    this.showSuccessToastMsg(null, "Notification successfully removed.");
  }

  getAppointment(appointment: any) {
    this.showProgressBar();
    
    this.appointmentService.getAppointment(appointment.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Appointment Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          // this.getServiceProviderByServiceTypeList(json.data.serviceType?.id)
          // this.appointmentId = json.data.id
          this.appointmentNumber = json.data.appointmentId;
          this.appointmentStartDateTime = new Date(json.data.scheduledOnFrom);
          this.appointmentEndDateTime = new Date(json.data.scheduledOnTo);
          // this.serviceType = json.data.serviceType?.id || '';
          this.serviceTypeName = json.data.serviceType?.name || '';
          // this.serviceProvider = json.data.serviceProvider?.id || '';
          this.serviceProviderName = json.data.serviceProvider?.name || '';
          this.specialInstruction = json.data.specialInstructions || '';
          // this.client = json.data.clientData?.id || '';
          this.singleClientData = json.data.clientData || '';
          // this.singleClientConfirm = json.data.clientConfirm == "true" ? true : false;
          // this.checkUserType(json.data.clientData?.userType || '')
          
          // this.getServiceType(this.serviceType).subscribe(() => {
          //   this.updateConfirmButtonState();
          // });
          this.visibleViewAppointment = true;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
        var now = new Date();
        if (this.appointmentStartDateTime < now) {
          this.view_disabled = true;
        } else {
          this.view_disabled = false
        }
      }
    );
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  getFormattedTimestamp(timestamp:any): Date {
    return new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  }

  onSidebarHide(){
    this.isVisible = !this.isVisible;
    if(this.beforeClosingToggleStatus){
      this.retrieveReadNotificationMessages();
    }else{
      this.retrieveNotificationMessages();
    }
    this.unReadNotification = this.beforeClosingToggleStatus;
  }
}
