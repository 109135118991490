import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  TreeNode,
  MessageService,
  ConfirmationService,
  ConfirmEventType,
  MenuItem,
} from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { AppSettings } from '../Constant/AppSettings';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { RoleService } from '../services/role/role.service';
import { UtilsService } from '../services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
})
export class RoleComponent implements OnInit {
  id: any = '';
  roleName: string = '';
  roleDescription: string = '';
  isLoading: boolean = false;
  roles: any[] = [];
  timeoutError = '';
  // PRIMENG Varaible
  privileges: TreeNode[] = [];
  selectedPrivileges: TreeNode[] = [];
  cols: any[] = [];
  items: MenuItem[] = [{ label: 'Manage Roles' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  // Dialog display varaible
  displayResetPasswordModal: boolean = false;
  displayEditModal: boolean = false;
  displayAddModal: boolean = false;
  disabled: boolean = false;
  roleDialogMax: string = '278px';
  isMaximizeAddRole: boolean = false;
  public innerWidth: any;
  bussinesInfo: any = {};
  tempSelectedPrivilege: any[] = [];

  constructor(
    public utils: UtilsService,
    public roleService: RoleService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private privilegeService: PrivilegeService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.listRoles();
    this.listPrivilegeGroups();
    this.cols = [
      { field: 'name', header: 'Role Name' },
      { field: 'description', header: 'Role Description' },
    ]
    // this.dataArray = ["Expenses.doc"];
    // this.checkNode(this.files, this.dataArray);
    this.bussinesInfo = CommonFunctions.getBusinessInfo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;

  }

  // updateSelectedPrivilege(name:any, children:any){
  //   for (let index = 0; index < this.selectedPrivileges.length; index++) {
  //     if(this.selectedPrivileges[index].label == name){
  //       this.selectedPrivileges[index].children = children;
  //     }
  //   }
  // }


  addRole(roleForm: NgForm) {
    // console.log(this.selectedPrivileges)
    this.showProgressBar();
    if (roleForm.value.roleName === '') {
      this.showErrorMessage('Role Name Field is empty.')
      this.hideProgressBar();
      return;
    } else if (roleForm.value.roleDescription === '') {
      this.showErrorMessage('Description Field is empty.')
      this.hideProgressBar();
      return;
    } else if (this.selectedPrivileges.length === 0) {
      this.showErrorMessage('Please select atleast one privilege.')
      this.hideProgressBar();
      return;
    }

    var filteredKeywords = this.selectedPrivileges.filter((word) => {
      // console.log('child--' + !this.privileges.includes(word))
      return !this.privileges.includes(word);
    });
    // console.log(filteredKeywords)
    let addPrivilegesList: any[] = [];
    if (filteredKeywords !== null) {
      for (let index = 0; index < filteredKeywords.length; index++) {
        const element = filteredKeywords[index];
        addPrivilegesList.push({ id: element.key });
      }
    }

    // for (let index = 0; index < this.selectedPrivileges.length; index++) {
    //   if(this.selectedPrivileges[index].label == "Super Privilege"){
    //     for (let indexJ = 0; indexJ < this.tempSelectedPrivilege.length; indexJ++) {
    //       if(this.tempSelectedPrivilege[indexJ].label == this.selectedPrivileges[index].label){
    //         addPrivilegesList.push({ id: this.tempSelectedPrivilege[indexJ].children[0].key });
    //       }
    //     }
    //   }     
    // }
    
    const roleData = {
      name: roleForm.value.roleName,
      description: roleForm.value.roleDescription,
      privileges: addPrivilegesList,
    };
    var data = { data: JSON.parse(JSON.stringify(roleData)) };
    console.log('Add Role Form Data --->> ' + JSON.stringify(data));
    this.roleService.addRole(data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Role Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('add_role');
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: json.response.status,
            detail: json.response.message,
          });
          roleForm.reset();
          this.listRoles();
          // this.router.navigate(['/user']);
          this.selectedPrivileges = [];
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.timeoutError = error;
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  editRole(roleForm: NgForm) {
    this.showProgressBar();
    if (roleForm.value.roleName === '') {
      this.showErrorMessage('Role Name Field is empty.')
      this.hideProgressBar();
      return;
    } else if (roleForm.value.roleDescription === '') {
      this.showErrorMessage('Description Field is empty.')
      this.hideProgressBar();
      return;
    } else if (this.selectedPrivileges.length === 0) {
      this.showErrorMessage('Please select atleast one privilege.')
      this.hideProgressBar();
      return;
    }
    var filteredKeywords = this.selectedPrivileges.filter((word) => {
      // console.log('child--' + !this.privileges.includes(word))
      return !this.privileges.includes(word);
    });
    console.log(filteredKeywords);
    let addPrivilegesList: any[] = [];
    if (filteredKeywords !== null) {
      for (let index = 0; index < filteredKeywords.length; index++) {
        const element = filteredKeywords[index];
        addPrivilegesList.push({ id: element.key });
      }
    }
    const roleData = {
      name: roleForm.value.roleName,
      description: roleForm.value.roleDescription,
      privileges: addPrivilegesList,
    };
    var data = { data: JSON.parse(JSON.stringify(roleData)) };
    console.log('ID --->> ' + this.id);
    this.roleService.editRole(data, this.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit Role Post Response --->> ' + JSON.stringify(json));
        // console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.showHideModalDialog('edit_role');
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: json.response.status,
            detail: json.response.displayMessage,
          });
          this.listRoles();
          // this.router.navigate(['/user']);
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.timeoutError = error;
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  getRole(role: any, type: string) {
    console.log('ID --->> ' + role.id);
    this.showHideModalDialog(type);
    this.id = role.id;
    if (type === 'edit_role') {
      this.showProgressBar();
      this.roleService.getRole(role.id).subscribe(
        (response) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log('Get Role Response --->> ' + JSON.stringify(json));
          this.roleName = json.data.name;
          this.roleDescription = json.data.description;
          // let tempList: any[] = [];
          // json.data.privileges.forEach((inputGet: any) => {
          //   this.privileges.forEach((parent: any) => {
          //     for (let index = 0; index < parent.children.length; index++) {
          //       const element = parent.children[index];
          //       if (inputGet.id == element.key) {
          //         console.log('--get parent-->' + parent.key)
          //         tempList.push(parent)
          //       }
          //     }

          //   });
          //   this.selectedPrivileges.push({ key: inputGet.id });
          // });

          // let uniqueChars: any[] = [];
          // tempList.forEach((c) => {
          //   if (!uniqueChars.includes(c)) {
          //     uniqueChars.push(c);
          //     this.selectedPrivileges.push({ key: c.key});
          //   }
          // });
          // tempList.forEach((c) => {
          //   if (!uniqueChars.includes(c)) {
          //     uniqueChars.push(c);
          //     this.selectedPrivileges.push({ key: c.key});
          //   }
          // });
          const keysToBeSelected: string[] = [];
          json.data.privileges.forEach((inputGet: any) => {
            keysToBeSelected.push(inputGet.id);
          });
          this.checkNode(this.privileges, keysToBeSelected);
          var names = this.selectedPrivileges.map(function (item) {
            return item;
          });

          console.log(names);

          console.log('--removed duplicate parent-->' + JSON.stringify(this.selectedPrivileges.toString()))
          this.hideProgressBar();
        },
        (error) => {
          console.log('There was an error');
          console.log(error);
          this.showErrorMessage(AppSettings.ERROR_MSG)
          this.timeoutError = error;
          this.hideProgressBar();
        }
      );
    }
  }

  removeRole(role: any) {
    this.roleService.removeRole(role.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.messageService.add({
            key: 'br',
            severity: 'success',
            summary: json.response.status,
            detail: json.response.message,
          });
          this.listRoles();
        } else {
          this.messageService.add({
            key: 'br',
            severity: 'error',
            summary: json.response.status,
            detail: json.response.displayMessage,
          });
        }
        console.log('Remove User Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.messageService.add({
          key: 'br',
          severity: 'error',
          detail: AppSettings.ERROR_MSG,
        });
        this.timeoutError = error;
      }
    );
  }




  listRoles() {
    this.isLoading = true;
    this.roleService.listRoles().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        // console.log('Roles --->> ' + JSON.stringify(json));
        this.roles = json.data;

        this.hideProgressBar();
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.timeoutError = error;
        this.hideProgressBar();
        this.messageService.add({
          key: 'br',
          severity: 'error',
          detail: AppSettings.ERROR_MSG,
        });
      }
    );
  }

  listPrivilegeGroups() {
    this.isLoading = true;
    this.roleService.listPrivilegeGroups().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));

        console.log('Privileges --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          let temp: TreeNode[] = [];
          for (let index = 0; index < json.data.length; index++) {
            const element = json.data[index];
            let tempChildren: TreeNode[] = [];
            for (let indexJ = 0; indexJ < element.privileges.length; indexJ++) {
              const elementj = element.privileges[indexJ];
              tempChildren.push({
                key: elementj.id,
                label: elementj.description,
                data: elementj.name,
              });
            }

            // this.tempSelectedPrivilege.push({
            //   key: element.id,
            //   label: element.name,
            //   expanded: false,
            //   children: tempChildren,
            // });
            // if (
            //   element.name !== "Client Administration" &&
            //   element.name !== "User Management" &&
            //   element.name !== "Marketing Management"
            // ) {
            //   tempChildren = [];
            // }

            temp.push({
              key: element.id,
              label: element.name,
              expanded: false,
              children: tempChildren,
            });
          }
          this.privileges = temp;
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log('There was an error');
        console.log(error);
        this.timeoutError = error;
        this.hideProgressBar();
        this.messageService.add({
          key: 'br',
          severity: 'error',
          detail: AppSettings.ERROR_MSG,
        });
      }
    );
  }



  confirmDeleteDialog(role: any) {
    console.log('ID --->> ' + role.id);
    // this.id = role.id;
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this role?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.removeRole(role);
      },
      reject: (type: any) => {
        switch (type) {
          // case ConfirmEventType.REJECT:
          //   this.messageService.add({ key: 'br', severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
          //   break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              key: 'br',
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }



  checkNode(nodes: TreeNode[], str: string[]) {
    nodes.forEach((node) => {
      //check parent
      if (str.includes(node.key!)) {
        this.selectedPrivileges.push(node);
      }

      if (node.children != undefined) {
        node.children.forEach((child) => {
          //check child if the parent is not selected
          if (str.includes(child.key!) && !str.includes(node.key!)) {
            node.partialSelected = true;
            node.expanded = true;
            child.parent = node;
          } else {
            node.partialSelected = false;
            node.expanded = false;
            child.parent = node;
          }

          //check the child if the parent is selected
          //push the parent in str to new iteration and mark all the childs
          if (str.includes(node.key!)) {
            child.parent = node;
            str.push(child.key!);
          }
        });
      } else {
        return;
      }

      this.checkNode(node.children, str);

      node.children.forEach((child) => {
        if (child.partialSelected) {
          node.partialSelected = true;
        }
      });
    });
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.id = '';
    this.roleName = '';
    this.roleDescription = '';
    this.selectedPrivileges = [];
    this.timeoutError = '';
    this.isMaximizeAddRole = false;
    this.roleDialogMax = '278px';
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }

  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_role':
        this.clearFormData();
        this.displayEditModal = !this.displayEditModal;
        break;
      case 'add_role':
        this.clearFormData();
        this.checkNode(this.privileges, []);
        this.displayAddModal = !this.displayAddModal;
        break;
    }
  }

  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets
    if (/[a-zA-Z0-9_]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onMaximizeAddRole(isMaximize: boolean) {
    console.log(isMaximize);
    if (isMaximize) {
      this.roleDialogMax = '278px';
      this.isMaximizeAddRole = !this.isMaximizeAddRole;
    } else {
      this.roleDialogMax = '350px';
      this.isMaximizeAddRole = !this.isMaximizeAddRole;
    }
  }
  get state(): string {
    return this.utils.state;
  }
}
