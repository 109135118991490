import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionAndPaymentsService {

  constructor(
    private http: HttpClient
  ) { }

  renewUser(id: any, subscriptionType: any, subscriptionId: any, paymentRefNo: any, price: any, duration: any) {
    let params = new HttpParams().set('subscriptionType', subscriptionType);
    params = params.set('subscriptionId', subscriptionId);
    params = params.set('price', price);
    params = params.set('paymentRefNo', paymentRefNo);
    params = params.set('duration', duration);
    // console.log("Add User Service Data ---> " + JSON.stringify(user));
    const url = environment.API_URL + "/api/user/" + id + "/renew";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params,
    };
    console.log(url + params);
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  paymentSuccess(id: any, from: string, json: any) {
    let params = new HttpParams()
    params = params.set('paymentRefNo', json.paymentRefNo);
    if (from != "message_credit") {
      params = params.set('subscriptionId', json.subscriptionId);
      params = params.set('duration', json.duration);
      params = params.set('proratedCharge', json.proratedCharge);
      params = params.set('setupFee', json.setupFee);
      params = params.set('uSubType', json.uSubType);
    }
    if (json.messageCreditPackage != '' && json.messageCreditPackage != null) {
      params = params.set('messageCreditPackage', json.messageCreditPackage);
    }
    params = params.set('tax', json.tax);
    params = params.set('subscriptionType', json.subscriptionType);
    params = params.set('price', json.price);
    if(from =='home'){
      params = params.set('discountId',json.discountId)
    }

    params = params.set('PaymentGateway', json.PaymentGateway);
    
    // console.log("Add User Service Data ---> " + JSON.stringify(user));
    const url = environment.API_URL + "/api/payment/" + id + "/success";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params,
    };
    console.log(url + '?' + params);
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }


  subscriptionList(page: any) {
    let params = new HttpParams().set('page', page);
    const url = environment.API_URL + "/api/usersubscription/list";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getMessageCreditPaymentsList(page: any) {
    let params = new HttpParams().set('page', page);
    const url = environment.API_URL + "/api/payment/getMessageCreditPayments";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  messageCreditPackageList(subsType: string) {
    const url = environment.API_URL + "/api/messageCreditPackage?subscription=" + subsType;
    // const TOKEN = CommonFunctions.getToken();
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: "Bearer " + TOKEN,
    //     "Content-Type": "application/json",
    //     'BusinessId': CommonFunctions.getBusinessId().toString()
    //   }),
    // };
    // return this.http.get(url, httpOptions).pipe(timeout(60000));
    return this.http.get(url).pipe(timeout(60000));
  }

  messageCreditCount() {
    const url = environment.API_URL + "/api/messageCredit/get";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  checkSubscription(subs_id: string) {
    const url = environment.API_URL + "/api/usersubscription/" + subs_id + '/check';
    console.log('Url:-' + url)

    const TOKEN = CommonFunctions.getToken();
    let GetBusinessId = "0";
    if(CommonFunctions.getBusinessId() != undefined){
      GetBusinessId = CommonFunctions.getBusinessId().toString();
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': GetBusinessId.toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  calculateSubscription(subId: any, duration: any, messageCredit: any, subscription: string, coupon: string) {
    let params = new HttpParams()
    if (subId != '' && subId != 0) {
      params = params.set('subId', subId);
    }
    if (duration != '') {
      params = params.set('duration', duration);
    }
    if (messageCredit != '') {
      params = params.set('messageCredit', messageCredit);
    }
    if (subscription != '') {
      params = params.set('subscription', subscription);
    }
    if (coupon != '') {
      params = params.set('coupon', coupon);
    }
    const url = environment.API_URL + "/api/usersubscription/calculate";
    const TOKEN = CommonFunctions.getToken();
    let GetBusinessId = "0";
    if(CommonFunctions.getBusinessId() != undefined){
      GetBusinessId = CommonFunctions.getBusinessId().toString();
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': GetBusinessId
      }),
      params: params,
    };
    console.log(url + '?' + params);
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }


  getInvoice(subs_id: string) {
    const url = environment.API_URL + "/api/payment/" + subs_id + '/invoice';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getInvoiceMsgCredit(subs_id: string) {
    const url = environment.API_URL + "/api/payment/" + subs_id + '/getMessageCreditPayments';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  checkUpcoming(id: string) {
    const url = environment.API_URL + "/api/usersubscription/" + id + '/checkUpcoming';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  SquareProcessPayment(token: string, data:any) {
    const url = environment.API_URL + "/api/payment/process-payment";
    const TOKEN = CommonFunctions.getToken();

    let GetBusinessId = "0";
    if(CommonFunctions.getBusinessId() != undefined){
      GetBusinessId = CommonFunctions.getBusinessId().toString();
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': GetBusinessId,
        'token':token
      }),
    };
    return this.http.post(url, data, httpOptions).pipe(timeout(60000));
  }
}
