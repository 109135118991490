import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  //Desktop view menu
  public state = "open";
  //Mobile view menu
  public menuState = new BehaviorSubject(false)
  public creditMsgState = "hide";
  creditCount = new BehaviorSubject('null');
  creditCountNumber = 0;
  modal = new BehaviorSubject(false);
  isVisibleSidebar = new BehaviorSubject(false);
  notificationData: any = new BehaviorSubject(undefined);
  isLogin = new BehaviorSubject(false)
  twillioBannerData = new BehaviorSubject(undefined)
  // 1. Storing the route param value, :businessUrl -> id value
  private pathParamState = new BehaviorSubject<string>('');
  pathParam: Observable<string>;
  //profile reload
  reloadProfile = new BehaviorSubject(false);
  //Buy message credit subs List reload
  buyMsgCreditReload = new BehaviorSubject(false);
  loadFirebaseMessage = new BehaviorSubject("");
  loadSubscriptionCal = new BehaviorSubject("");
  constructor() {
    this.pathParam = this.pathParamState.asObservable();
  }

  public changeState(): void {
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
    console.log('menustate:', this.state == "open", 'state:', this.state)
  }

  public changeMobileMenuState(isVisible: boolean): void {
    this.menuState.next(isVisible)
  }

  public setIsVisibleSidebar(isVisible: boolean) {
    this.isVisibleSidebar.next(isVisible);
  }


  public changeCreditMsgState(state: string): void {
    this.creditMsgState = state;
  }

  public setCreditCount(count: any) {
    this.creditCount.next(count);
    this.creditCountNumber = count;
  }

  public setCreditModalShowHide(modal: boolean) {
    this.modal.next(modal);
  }

  public onClickNotification(notificationData: any) {
    this.notificationData.next(notificationData);
  }

  public setIsLogin(isLogin: boolean) {
    this.isLogin.next(isLogin);
  }

  public setTwillioBanner(twillioBanner: any) {
    this.twillioBannerData.next(twillioBanner);
  }

  updatePathParamState(newPathParam: string): void {
    this.pathParamState.next(newPathParam);
  }

  public onReloadProfileClick(data: boolean) {
    this.reloadProfile.next(data);
  }
  public onReloadMsgCreditClick(data: boolean) {
    this.buyMsgCreditReload.next(data);
  }
  
  public setloadFirebaseMessage(id: any) {
    this.loadFirebaseMessage.next(id);
  }

  public triggerSubscriptionCal(id: any) {
    this.loadSubscriptionCal.next(id);
  }
  
}
