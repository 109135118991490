<div [ngClass]="state == 'open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{ width: '100%', height: '95vh', 'margin-top': '7px', 'padding-bottom': '70px' }" #sc>
        <div class="report-container">
            <div class="dropdown-container" style="position: relative; top: 60px; left: 20px;">
                <label for="report-select">Select Report:</label>
                <!-- <select id="report-select" [(ngModel)]="selectedReport" style="margin-left: 10px;">
                    <option value="serviceUtilization">Service Utilization Report</option>
                    <option value="clientEngagement">Client Engagement Report</option>
                </select> -->
                <p-dropdown
                    [options]="reportOptions"
                    [(ngModel)]="selectedReport"
                    style="margin-left: 10px;"
                    optionLabel="label"
                    optionValue="value"
                    (onChange)="onReportChange($event)"
                ></p-dropdown>

            </div>

            <div class="report-content">
                <app-service-utilization-report *ngIf="selectedReport === 'serviceUtilization'"></app-service-utilization-report>
                <app-report-client-engagement-report *ngIf="selectedReport === 'clientEngagement'"></app-report-client-engagement-report>
            </div>
        </div>
    </p-scrollPanel>
</div>
