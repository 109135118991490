<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Configuration Wizard
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div class="container-fluid bg-white">
                    <div class="row">
                        <div class="col-md-12">
                        </div>
                        <div class="col-sm-6">
                            <div class="col-sm-12">
                                <h2 style=" padding-bottom: 15px;font-weight: bolder; ">Mandatory Tasks</h2>
                            </div>
                            <div class="col-sm-12" *ngFor="let list of listconfigurationwizard.slice(0,14); let i = index">
                                <div class="p-field-checkbox" [ngStyle]="{'margin-left': i == 2 ? '-3px': '0px'}">
                                    <p-checkbox name="{{list.id}}" value="{{list.id}}" inputId="{{list.id}}" [disabled]="list.show" [(ngModel)]="selectedValues" (onChange)="onChangeSelect(i, list.configurationWizardExtra, list.description, list.id)"></p-checkbox>
                                    <label for="{{list.id}}" [ngStyle]="list.show ? {'color': '#b3b3b3','font-weight': (list.required == 'true') ? '600' : '100'} : {'color': '#858585','font-weight': (list.required == 'true') ? '600' : '100'}">{{ list.genId + 1 }}. {{list.description}} <span [ngClass]=" list.required == 'true' ? 'symbol required' : '' "></span>
                                    </label>
                                    <a *ngIf="list.routerLink" routerLink="{{list.routerLink}}" class="pi pi-link" style=" font-size: 17px; margin-left: 3px; margin-right: 3px;"></a>
                                    <a (click)="ShowBubbleHelpText(i, list.description, list.bubbleHelpText)" [ngStyle]="list.show ? {'font-weight': '600', 'color': '#b3b3b3'} : {'font-weight': '600'}">
                                        <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                    </a>
                                    <span style=" padding-left: 10px; ">
                                        <i *ngIf="list.genId == 8" [ngClass]="showExpand ? 'pi pi-minus' : 'pi pi-plus'" (click)="expandDiv(i, list.configurationWizardExtra, list.id)" style=" color: #858585; "></i>
                                    </span>
                                </div>
                                <div class="col-md-12" *ngIf="showExpand && list.genId == 8">
                                    <div class="col-sm-12" *ngFor="let array of list.configurationWizardExtra; let j = index">
                                        <div class="p-field-checkbox" [ngStyle]="{'margin-left': j == 3 ? '-3px': '0px'} ">
                                            <p-checkbox name="extra_{{array.id}}" value="{{array.id}}" inputId="extra_{{array.id}}" [(ngModel)]="selectedExtraValues" (onChange)="onChangeExtraSelect()" [disabled]="list.show"></p-checkbox>
                                            <label for="extra_{{array.id}}" style=" font-weight: 600; line-height: 16px;">{{IndexValue+1}}.{{j+1}}. {{array.extraDetails}} <span class="symbol required"></span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="col-sm-12">
                                <h2 style=" padding-bottom: 15px;font-weight: bolder; ">Optional Tasks</h2>
                            </div>
                            <div class="col-sm-12" *ngFor="let list of listconfigurationwizard.slice(14,22); let i = index">
                                <div class="p-field-checkbox" [ngStyle]="{'margin-left': i == 2 ? '-3px': '0px'}">
                                    <p-checkbox name="{{list.id}}" value="{{list.id}}" inputId="{{list.id}}" [disabled]="list.show" [(ngModel)]="selectedValues" (onChange)="onChangeSelect(i, list.configurationWizardExtra, list.description, list.id)"></p-checkbox>
                                    <!-- <label for="{{list.id}}" [ngStyle]="list.show ? {'color': '#b3b3b3','font-weight': (list.required == 'true') ? '600' : '100'} : {'color': '#858585','font-weight': (list.required == 'true') ? '600' : '100'}">{{ list.genId + 1 }}. {{list.description}} <span [ngClass]=" list.required == 'true' ? 'symbol required' : '' "></span>
                                    </label> -->
                                    <label for="{{list.id}}">{{ list.genId + 1 }}. {{list.description}} <span [ngClass]=" list.required == 'true' ? 'symbol required' : '' "></span>
                                    </label>
                                    <a *ngIf="list.routerLink" routerLink="{{list.routerLink}}" class="pi pi-link" style=" font-size: 17px; margin-left: 3px; margin-right: 3px;"></a>
                                    <a (click)="ShowBubbleHelpText(i, list.description, list.bubbleHelpText)" [ngStyle]="list.show ? {'font-weight': '600', 'color': '#b3b3b3'} : {'font-weight': '600'}">
                                        <svg style="margin-left: 5px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                    </a>
                                    <!-- <span style=" padding-left: 10px; ">
                                        <i *ngIf="list.genId == 15" [ngClass]="showExpand ? 'pi pi-minus' : 'pi pi-plus'" (click)="expandDiv(i, list.configurationWizardExtra, list.id)" style=" color: #858585; "></i>
                                    </span> -->
                                </div>

                                <!-- <div class="col-md-12" *ngIf="showExpand && list.genId == 15">
                                    <div class="col-sm-12" *ngFor="let array of list.configurationWizardExtra; let j = index">
                                        <div class="p-field-checkbox" [ngStyle]="{'margin-left': j == 3 ? '-3px': '0px'} ">
                                            <p-checkbox name="extra_{{array.id}}" value="{{array.id}}" inputId="extra_{{array.id}}" [(ngModel)]="selectedExtraValues" (onChange)="onChangeExtraSelect()" [disabled]="list.show"></p-checkbox>
                                            <label for="extra_{{array.id}}" style=" font-weight: 600; line-height: 16px;">{{IndexValue+1}}.{{j+1}}. {{array.extraDetails}}</label>
                                            <span class="symbol required"></span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-10 offset-1">
                                <div class="text-center" *ngIf="stateDate == null ">
                                    <button pButton pRipple type="button" class="p-button-raised" label="GO LIVE" [disabled]="showButton" (click)="confirmEnableDisableDialog(businessStatus)"></button>
                                </div>
                            </div>

                            <div class="text-center" *ngIf="stateDate != null ">

                                <div style=" font-size: 14px; font-weight: 600; ">
                                    <!-- <span class="dot"></span> BUSINESS WENT LIVE ON {{stateDate | date: 'yyyy-MM-dd HH:mm:ss'}} -->
                                    <span class="dot"></span> BUSINESS WENT LIVE ON {{stateDate | date: 'MM-dd-yyyy'}}
                                    <div class="holder">
                                        <div class="livenow">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </p-scrollPanel>
</div>

<!-- CONFIRMATION MODAL -->
<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{width:'45vw'}">
</p-confirmDialog>
<p-toast position="bottom-right" key="br"></p-toast>

<!-- EXTRA DETAILS BOX -->
<p-dialog #addDialog [header]="headerName" [(visible)]="displayExtraDetails" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" [style]="{width: '70vw'}">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>

    <div class="col-md-12">
        <div class="col-sm-12" *ngFor="let array of arrayList; let j = index">
            <div class="p-field-checkbox" [ngStyle]="{'margin-left': j == 3 ? '-3px': '0px'} ">
                <p-checkbox name="extra_{{array.id}}" value="{{array.id}}" inputId="extra_{{array.id}}"  [(ngModel)]="selectedExtraValues" (onChange)="onChangeExtraSelect()"></p-checkbox>
                <label for="extra_{{array.id}}" style=" font-weight: 600;">{{IndexValue+1}}.{{j+1}}. {{array.extraDetails}}</label>
            </div>
        </div>
        <div class="text-center">
            <div class="col-10 offset-1">
                <button pButton pRipple type="button" class="p-button-raised" label="Confirm" (click)="confirmExtraSelection()" [disabled]="showExtraButton" ></button>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        

    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- BUBBLE HELP TEXT DETAILS BOX -->
<p-dialog #addDialog [header]="bubbleHeaderName" [(visible)]="displayBubbleHelpText" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" [style]="{width: '70vw'}">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>

    <p style=" font-size: initial; ">{{bubbleHelpText}} </p>

    <ng-template pTemplate="footer">      

    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>