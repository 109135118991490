<p-messages *ngIf="showHideMsg" key="Msg" [severity]="severity">
    <ng-template pTemplate>
        <div class="align">
            <div class="p-ml-2">{{msgText}}</div>
        </div>
        <div>
            <button *ngIf="showHideCloseBtn" pButton icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-warning p-message-close"
                (click)="hideTwillioMsg()"></button>
        </div>

    </ng-template>
</p-messages>

<!-- <p-messages *ngIf="deactivationShowHideMsg" severity="warn" [closable]="false">
    <ng-template pTemplate>
        <div class="align">
            <div class="p-ml-2">{{deactivationMsgText}}</div>
        </div>
    </ng-template>
</p-messages> -->